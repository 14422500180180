import React, { Component } from 'react';
import Connector from '../../data/Connector';
import Firebase from 'firebase';
import 'firebase/functions';
import HelperEmptyImg from '../../helper/EmptyLarge';
import Navigation from '../../components/Navigation';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import WidgetCard from '../../components/Widgets/card';
import { AuthUserContext, withAuthorizationMember } from '../../components/Session';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class MemberCardsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      done: false,
      num: global.num,
      card: global.userCard,
      showOverlayCard: false,
      hiddenCard: 'hidden',
      loading: false,
      valuesCard: ['', '', '', '', ''],
      errorCard: [false, false, false, false, false],
      margin: 0,
      showOverlayDelete: false,
      hiddenDelete: 'hidden',
    };
  }


  componentDidMount() {
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-3]
    if(id !== 'admin' && id !== 'member') {
      global.uid = id
    }
    Connector.setUser()
    document.title = lang.t('header:title.admin')+' - '+global.userBusiness
    window.scrollTo(0, 0)

    this.setState({
      done: true
    })

    if(global.message !== '') {
      setTimeout(() => {
        EventEmitter.dispatch('showMessage', global.message);
        global.message = ''
      }, 300);
      
    }
    EventEmitter.subscribe('userLoaded', (event) => this.checkSpace())
    EventEmitter.subscribe('clientLoaded', (event) => this.configureData())
    EventEmitter.subscribe('logLoaded', (event) => this.configureLog())
    this.configureData()

  }
  

  checkSpace() {
    if(global.uid === '') {
      this.props.history.push(ROUTES.LOADING)
    } else {
      var found = false
      for(var item of global.userStaff) {
        if(item.id === global.uid) {
          found = true
        }
      }
      if(found) {
        document.title = lang.t('header:title.admin')+' - '+global.userBusiness
        this.configureData()
      } else {
        this.props.history.push(ROUTES.LOADING)
      }
    }
  }


  configureData() {
    if(this.state.loading) {
      this.setState({
        loading: false,
        card: global.userCard,
      })
    } else {
      this.setState({
        card: global.userCard,
      })
    }
  }


  configureLog() {
    for(var item of global.userLog) {
      if(item.data.title === 'cardnewerror' || item.data.title === 'carddeleteerror') {
        EventEmitter.dispatch('showMessageError', item.data.message);
        global.message = ''
        Firebase.database().ref('/log/'+global.uid+'/'+item.id).remove()
        this.setState({
          loading: false,
        })
      }
      if(item.data.title === 'cardcreated' || item.data.title === 'carddeleted') {
        EventEmitter.dispatch('showMessage', lang.t('message:card'));
        Firebase.database().ref('/log/'+global.uid+'/'+item.id).remove()
        global.message = ''
        this.setState({
          loading: false,
        })
      }
    }
  }


  getBg() {
    var label = 'image'
    if(!this.state.done && this.state.num === 99) {
      var num = parseInt(Math.random()*6+1)
      global.num = num
      this.setState({
        num: num
      })
    }
    label = 'image bg'+this.state.num
    return label
  }


  onChangeCard = event => {
    var tmp = this.state.valuesCard
    tmp[parseInt(event.target.name)] = event.target.value
    this.setState({valuesCard: tmp});
  };


  createCard() {
    this.setState({errorCard: [false, false, false, false, false]})
    var tmp = [false, false, false, false, false]
    var passed = true
    if(this.state.valuesCard[0] === '') {
      tmp[0] = true
      passed = false
    }
    if(this.state.valuesCard[1] === '') {
      tmp[1] = true
      passed = false;
    }
    if(this.state.valuesCard[2] === '') {
      tmp[2] = true
      passed = false;
    }
    if(this.state.valuesCard[3] === '') {
      tmp[3] = true
      passed = false;
    }
    if(this.state.valuesCard[4] === '') {
      tmp[4] = true
      passed = false;
    }
    this.setState({errorCard: tmp})
    if(passed) {
      var email = global.userEmail
      if(email === '' || email === undefined) {
        email = 'noemail@ptmate.net'
      }

      if(this.state.card !== undefined && this.state.card !== '') {
        var callFunction = Firebase.functions().httpsCallable('connectedManageClientCardV2');
          callFunction({type: 'delete', card: this.state.card, trainer: global.uid, account: global.userStripeConnect, client: global.spaceClient, uid: global.uidUser, customer: global.userStripe}).then(function(result) {
           //var sanitizedMessage = result.data.text;
        });
      }
      if(global.userStripe !== '' && global.userStripe !== undefined) {
        var callFunction2 = Firebase.functions().httpsCallable('connectedManageClientCardV2');
        callFunction2({type: 'create', customer: global.userStripe, name: this.state.valuesCard[0], client: global.spaceClient, card: this.state.valuesCard[1], month: this.state.valuesCard[2], year: this.state.valuesCard[3], cvc: this.state.valuesCard[4], account: global.userStripeConnect, uid: global.uid, user: global.uidUser}).then(function(result) {
          //var sanitizedMessage = result.data.text;
        });
      } else {
        var callFunction3 = Firebase.functions().httpsCallable('connectedManageClientCardV2');
        callFunction3({type: 'account', clientname: global.userName, email: email, name: this.state.valuesCard[0], client: global.spaceClient, card: this.state.valuesCard[1], month: this.state.valuesCard[2], year: this.state.valuesCard[3], cvc: this.state.valuesCard[4], account: global.userStripeConnect, uid: global.uid, user: global.uidUser}).then(function(result) {
          //var sanitizedMessage = result.data.text;
        });
      }
      
      this.setState({
        loading: true,
        message: lang.t('message:cardadded')
      })
    }
  }



  // Delete billing overlay ------------------------------------------------------------



  showOverlayDelete() {
    this.setState({
      showOverlayDelete: true,

    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenDelete: '',
        margin: -height/2
      });
    }, 100);
  }


  hideOverlayDelete() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenDelete: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({
        showOverlayDelete: false, 
      });
    }, 500);
  }


  renderOverlayDelete() {
    if(this.state.showOverlayDelete) {
      return (
        <div className={'overlay '+this.state.hiddenDelete}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{lang.t('modal:deletecard.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideOverlayDelete()}>{lang.t('modal:common.close')}</button>
            <p className="clear mb-30">{lang.t('modal:deletecard.text1')} {global.userBusiness}. {lang.t('modal:deletecard.text2')}</p>
            <button className="btn primary" onClick={() => this.deleteCard()}>{lang.t('modal:deletecard.button')}</button>
          </div>
        </div>
      )
    }
  }


  deleteCard() {
    this.hideOverlayDelete()
    this.setState({
      loading: true
    })
    var callFunction = Firebase.functions().httpsCallable('connectedManageClientCardV2');
    callFunction({type: 'clear', card: this.state.card, trainer: global.uid, account: global.userStripeConnect, client: global.spaceClient, uid: global.uid, customer: global.userStripe}).then(function(result) {
       //var sanitizedMessage = result.data.text;
    });
    global.message = lang.t('message:carddeleted')
  }



  // Display stuff ------------------------------------------------------------



  renderErrorCards(num) {
    var texts = [lang.t('admin:card.error1'), lang.t('admin:card.error2'), lang.t('admin:card.error3'), lang.t('admin:card.error4'), lang.t('admin:card.error5')]
    if(this.state.errorCard[num]) {
      return (
        <p className="error">{texts[num]}</p>
      )
    }
  }


  renderCardImage() {
    if(global.cards[0].brand === 'MasterCard') {
      return <img src="/img/card-mastercard.svg" alt="MasterCard"/>
    } else if(global.cards[0].brand === 'Visa') {
      return <img src="/img/card-visa.svg" alt="Visa"/>
    } else {
      return <img src="/img/card.svg" alt="Card"/>
    }
  }


  renderCard() {
    if(this.state.card === '') {
      return (
        <div className="empty large col-6">
          <div className="illustration mb-30">
            <div className="img1"></div>
            <div className="img2"></div>
            <div className="img3"></div>
            <div className="img4"></div>
            {HelperEmptyImg.renderImage('payments')}
          </div>
          <div className="data">
          <h3 className="mb-20">{lang.t('empty:card.title')}</h3>
          <p>{lang.t('empty:card.text2')}</p>
          </div>
        </div>
      )
    } else {
      return (
        <div className="col-6">
          <div className="col-4">&nbsp;</div>
          <div className="col-6">
            <WidgetCard id={global.spaceClient}/>
            <div className="clear sv-20"></div>
            <button className="btn tertiary close small width-12" onClick={() => this.showOverlayDelete()}>{lang.t('admin:button.remove')}</button>
          </div>
          <div className="clear"></div>
        </div>
      )
    }
  }


  renderLoading() {
    if(this.state.loading) {
      return (
        <div className="loading">
          <div className="loader-box">
            <span className="loader"><span className="loader-inner"></span></span>
          </div>
          <p style={{whiteSpace: 'pre-wrap'}}>{lang.t('message:loading.stripe')}</p>
        </div>
      )
    }
  }


  renderContent() {
    if(global.userStripeConnect === '') {
      return (
        <div className="empty large pt-60 clear">
          <div className="illustration mb-30">
            <div className="img1"></div>
            <div className="img2"></div>
            <div className="img3"></div>
            <div className="img4"></div>
            {HelperEmptyImg.renderImage('programs')}
          </div>
          <div className="data">
            <h3 className="mb-20">{lang.t('empty:card.title')}</h3>
            <p>{lang.t('empty:card.text1')}</p>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          {this.renderCard()}
          <div className="col-3 form">
            <h2 className="mb-20">{lang.t('header:admin.addcard')}</h2>
            <div className="col-12">
              <label>{lang.t('admin:card.name')}*</label>
              <input type="text" placeholder={lang.t('admin:card.name.placeholder')} value={this.state.valuesCard[0]} name="0" onChange={this.onChangeCard}/>
              {this.renderErrorCards(0)}
              <div className="mb-20"></div>
              <label>{lang.t('admin:card.number')}*</label>
              <input type="number" placeholder={lang.t('admin:card.number.placeholder')} value={this.state.valuesCard[1]} name="1" onChange={this.onChangeCard}/>
              {this.renderErrorCards(1)}
              <div className="mb-20"></div>
            </div>
            <div className="col-4">
              <label>{lang.t('admin:card.expiry')}*</label>
              <input type="number" placeholder="MM" value={this.state.valuesCard[2]} name="2" onChange={this.onChangeCard}/>
            </div>
            <div className="col-4">
              <label>&nbsp;</label>
              <input type="number" placeholder="YY" value={this.state.valuesCard[3]} name="3" onChange={this.onChangeCard}/>
            </div>
            <div className="col-1">&nbsp;</div>
            <div className="col-3">
              <label>{lang.t('admin:card.cvc')}*</label>
              <input type="number" placeholder="CVC" value={this.state.valuesCard[4]} name="4" onChange={this.onChangeCard}/>
            </div>
            <div className="clear"></div>
            <div className="col-12">
              {this.renderErrorCards(2)}
              {this.renderErrorCards(3)}
              {this.renderErrorCards(4)}
            </div>
            <div className="clear sv-40"></div>
            <button className="btn primary" onClick={() => this.createCard()}>{lang.t('admin:button.addcard')}</button>
          </div>
        </div>
      )
    }
  }


  renderPayment() {
    if(global.userStripeConnect !== '' && global.products.length > 0) {
      return (
        <Link to={'/'+global.uid+'/admin/payment'} className="tab">
          <p>{lang.t('nav:admin.payment')}</p>
          <div className="bg"></div>
        </Link>
      )
    }
  }


  renderNavMembership() {
    var show = false
    for(var item of global.products) {
      if(item.data.type === 'subscription') {
        show = true
      }
    }
    if(show) {
      return (
        <Link to={'/'+global.uid+'/admin/newmembership'} className="tab">
          <p>{lang.t('nav:admin.membership')}</p>
          <div className="bg"></div>
        </Link>
      )
    }
  }


  renderMainImage() {
    if(global.spaceBackground === '') {
      return (
        <div className={this.getBg()}>
          <div className={'gradient theme-'+global.spaceTheme}></div>
        </div>
      )
    } else {
      return (
        <div className='image bg' style={{backgroundImage: 'url('+global.spaceBackground+')', backgroundSize: 'cover'}}></div>
      )
    }
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content member">
          <div className="member-header mb-10">
            {this.renderMainImage()}
            <div className="member-main">
              <div className="business">
                <div className="avatar" style={{backgroundImage: 'url('+global.spaceImage+')'}}> </div>
                <h3>{global.userBusiness}<br/><span>{lang.t('header:title.memberarea')}</span></h3>
              </div>
              <h2 className="mb-30">{lang.t('header:title.admin')}</h2>
              <Link to={'/'+global.uid+'/admin/forms'} className="tab">
                <p>{lang.t('nav:admin.forms')}</p>
                <div className="bg"></div>
              </Link>
              {this.renderPayment()}
              {this.renderNavMembership()}
              <Link to={'/'+global.uid+'/admin/payments'} className="tab">
                <p>{lang.t('nav:admin.history')}</p>
                <div className="bg"></div>
              </Link>
              <Link to={'/'+global.uid+'/admin/card'} className="tab active">
                <p>{lang.t('nav:admin.card')}</p>
                <div className="bg"></div>
              </Link>
            </div>
            <div className="clear"></div>
          </div>
          <div className="clear sv-40"></div>
          {this.renderContent()}
        </div>
        <Navigation active='admin' />
        {this.renderOverlayDelete()}
        {this.renderLoading()}
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['nav','header','admin','common','modal','message'])(withRouter(withAuthorizationMember(condition)(MemberCardsPage)));