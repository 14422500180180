import React, { Component } from 'react';
import Connector from '../../data/Connector';
import Firebase from 'firebase';
import 'firebase/functions';
import Moment from 'moment';
import Navigation from '../../components/Navigation';
import InputToggle from '../../components/Form/toggle';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import HelperCal from '../../helper/Calendar';
import ModalLeaderboard from '../../components/Modals/leaderboard';
import { AuthUserContext, withAuthorizationMember } from '../../components/Session';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class MemberResultsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      done: false,
      num: global.num,
      name: global.userName,
      exercises: global.exercises,
      session: '',
      item: null,
      id: '',
      program: null,
      blocks: [],
      mins: [],
      secs: [],
      changed: false,
      isTraining: false,
      current: 0,
      best: global.clientBest,
      showOverlayVideo: false,
      hiddenVideo: 'hidden',
      margin: 0,
      showModalLeader: false,
      leaderBlock: null,
    };
  }


  componentDidMount() {
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-3]
    var sess = arr[arr.length-1]
    document.title = lang.t('header:title.sessionprogram')+' - '+global.userBusiness

    if(id !== 'results' && id !== 'member') {
      global.uid = id
    }
    Connector.setUser()
    
    window.scrollTo(0, 0)

    this.setState({
      done: true,
      session: sess,
    }, () => {
      this.configureData()
    })

    if(global.message !== '') {
      setTimeout(() => {
        EventEmitter.dispatch('showMessage', global.message);
        global.message = ''
      }, 300);
      
    }
    EventEmitter.subscribe('userLoaded', (event) => this.checkSpace())
    EventEmitter.subscribe('clientLoaded', (event) => this.configureData())
    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('trainingLoaded', (event) => this.configureData())
    EventEmitter.subscribe('programsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('exercisesLoaded', (event) => this.configureData())
  }
  

  checkSpace() {
    if(global.uid === '') {
      this.props.history.push(ROUTES.LOADING)
    } else {
      var found = false
      for(var item of global.userStaff) {
        if(item.id === global.uid) {
          found = true
        }
      }
      if(found) {
        document.title = lang.t('header:title.sessionprogram')+' - '+global.userBusiness
        this.configureData()
      } else {
        this.props.history.push(ROUTES.LOADING)
      }
    }
  }


  configureData() {
    var program = null
    var tmp1a = []
    var tmp2a = []
    var tblk = []
    for(var j=0; j<60; j++) {
      tmp1a.push(j+' min')
      tmp2a.push(j+' '+lang.t('training:label.sec'))
    }

    var user = ''
    var id = ''
    var session = this.state.session
    var sitem = null
    var date = ''
    var list = global.sessions
    var isTraining = false
    var attendees = []
    var current = 0

    for(var s2 of global.sessionsTraining) {
      if(s2.id === session) {
        list = global.sessionsTraining
        isTraining = true
      }
    }

    for(var s3 of global.archiveTraining) {
      if(s3.id === session) {
        list = global.archiveTraining
        isTraining = true
      }
    }

    for(var item of list) {
      if(item.id === session) {
        sitem = item
        program = item.program[0]
        id = item.program[0].id
        program = item.program[0]
        date = Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('lll')
        if(item.data.group) {
          attendees = item.data.clients
          for(var ai=0; ai<item.data.clients.length; ai++) {
            if(item.data.clients[ai] === global.spaceClient) {
              current = ai
            }
          }
        }

        for(var block of item.program[0].blocks) {
          var min = parseInt(block.data.rounds/60)
          var rmin = parseInt(block.data.rounds/60)
          var rsec = parseInt(block.data.rounds)-(rmin*60)
          if(block.data.timeRes !== undefined && block.data.timeRes !== null) {
            rmin = parseInt(block.data.timeRes/60)
            rsec = block.data.timeRes-(rmin*60)
          }
          var rming = []
          var rsecg = []
          var arrt = []
          var cycles = 1
          var simple = false

          var ssimple = false
          var usimple = "reps"

          if(block.data.cycles !== undefined) {
            cycles = block.data.cycles
          }
          if(item.data.group) {
            if(block.data.timeResGroup !== undefined && block.data.timeResGroup !== null) {
              arrt = block.data.timeResGroup
            }
            if(arrt.length === 0) {
              var att1 = 0
              while(att1 < attendees.length) {
                rming.push(0)
                rsecg.push(0)
                att1++
              }
            } else {
              for(var tme of arrt) {
                rming.push(parseInt(tme/60))
                rsecg.push(tme-(parseInt(tme/60)*60))
              }
            }
          }

          if(block.data.simple !== undefined) {
            simple = block.data.simple
          }

          var ex = []
          var tmpb = 0
          var tmp0 = 0
          var tmp1 = 0
          var tmp2 = 0

          var exWeightType = []
          var ei = 0
          while(ei < block.data.exName.length) {
            exWeightType.push('per')
            ei++
          }
          if(block.data.exWeightType !== undefined) {
            exWeightType = block.data.exWeightType
          }

          // Simple results
          if(block.data.simple) {
            if(block.data.unitSimple !== undefined && block.data.unitSimple !== '') {
              usimple = block.data.unitSimple
            }
            if(block.data.scaledSimple !== undefined) {
              if(block.data.scaledSimple.length > this.state.current) {
                ssimple = block.data.scaledSimple[this.state.current]
              }
            }

            if(block.data.type === 0) {
              if(block.data.amrapSimple !== undefined) {
                if(block.data.amrapSimple.length > this.state.current) {
                  var tmpa = block.data.amrapSimple[this.state.current].split('+')
                  tmp1 = parseInt(tmpa[0])
                  tmp2 = 0
                  if(tmpa.length > 1) {
                    tmp2 = parseInt(tmpa[1])
                  }
                }
              }
            } else if(block.data.type !== 0 && block.data.type !== 5) {
              if(block.data.valueSimple !== undefined) {
                if(block.data.valueSimple.length > this.state.current) {
                  tmp1 = block.data.valueSimple[this.state.current]
                }
              }
            }
          }
          simple = block.data.simple

          for(var i=0; i<block.data.exName.length; i++) {
            var min1 = parseInt(block.data.exWork[i]/60)
            var min2 = parseInt(block.data.exRest[i]/60)
            var min3 = parseInt(block.data.exResWeight[i]/60)
            var m = []
            var s = []

            var unit = ''
            if(block.data.exUnits !== undefined) {
              if(block.data.exUnits.length > i) {
                unit = block.data.exUnits[i]
              }
            }

            var notes = ''
            if(block.data.exNotes !== undefined) {
              if(block.data.exNotes.length > i) {
                notes = block.data.exNotes[i]
              }
            }

            tmpb += parseInt(block.data.exReps[i])
            if(!item.data.group) {
              tmp0 += parseInt(block.data.exResReps[i])
            }

            // Reps
            var reps = block.data.exReps[i]
            var repsround = block.data.exRepsRounds[i]
            var grouprounds = block.data.exRepsRounds[i]
            if(attendees.length === 0) {
              // 1:1
              if(block.data.exResReps !== undefined) {
                if(block.data.exResReps.length > i) {
                  tmp0 += parseInt(block.data.exResReps[i])
                  if(block.data.exResReps[i] !== 0 && block.data.exResReps[i] !== undefined) {
                    reps = block.data.exResReps[i]
                  }
                }
              }
              if(block.data.exResRepsRounds !== undefined) {
                if(block.data.exResRepsRounds.length > i) {
                  if(block.data.exResRepsRounds[i] !== '' && block.data.exResRepsRounds[i] !== undefined) {
                    repsround = block.data.exResRepsRounds[i]
                  }
                }
              }
            } else {
              // Group
              if(block.data.exResRepsGroup !== undefined) {
                if(block.data.exResRepsGroup.length > i) {
                  var areps = block.data.exResRepsGroup[i].split('-')
                  if(areps.length > this.state.current) {
                    if(areps[this.state.current] !== '') {
                      reps = areps[this.state.current]
                      tmp0 += parseInt(areps[this.state.current])
                    }
                  }
                }
                for(var im of block.data.exResRepsGroup) {
                  var mins = parseInt(im/60)
                  m.push(parseInt(im/60))
                  s.push(parseInt(im)-(mins*60))
                }
                if(m.length < attendees.length) {
                  for(var ia=m.length; ia<attendees.length; ia++) {
                    m.push(0)
                    s.push(0)
                  }
                }
              }
              if(block.data.exResRepsRounds !== undefined) {
                if(block.data.exResRepsRounds.length > i) {
                  var arepsr = block.data.exResRepsRounds[i].split('|')
                  if(arepsr.length > this.state.current) {
                    repsround = arepsr[this.state.current]
                    grouprounds = block.data.exResRepsRounds[i]
                  }
                }
              }
            }

            // Weight
            var weight = block.data.exWeight[i]
            var weightround = block.data.exWeightRounds[i]
            var groupweight = block.data.exWeightRounds[i]
            if(attendees.length === 0) {
              // 1:1
              if(block.data.exResWeight !== undefined) {
                if(block.data.exResWeight.length > i) {
                  if(block.data.exResWeight[i] !== 0 && block.data.exResWeight[i] !== undefined) {
                    weight = block.data.exResWeight[i]
                  }
                }
              }
              if(block.data.exResWeightRounds !== undefined) {
                if(block.data.exResWeightRounds.length > i) {
                  if(block.data.exResWeightRounds[i] !== '' && block.data.exResWeightRounds[i] !== undefined) {
                    weightround = block.data.exResWeightRounds[i]
                  }
                }
              }
            } else {
              // Group
              if(block.data.exResWeightGroup !== undefined) {
                if(block.data.exResWeightGroup.length > i) {
                  var aweight = block.data.exResWeightGroup[i].split('-')
                  if(aweight.length > this.state.current) {
                    if(aweight[this.state.current] !== '') {
                      weight = aweight[this.state.current]
                    }
                  }
                }
              }
              if(block.data.exResWeightRounds !== undefined) {
                if(block.data.exResWeightRounds.length > i) {
                  var aweightr = block.data.exResWeightRounds[i].split('|')
                  groupweight = block.data.exResWeightRounds[i]
                  if(aweightr.length > this.state.current) {
                    weightround = aweightr[this.state.current]
                  }
                }
              }
            }

            ex.push({
              id: block.data.exId[i],
              name: block.data.exName[i],
              tool: block.data.exTool[i],
              image: block.data.exImage[i],
              cat: block.data.exCat[i],
              type: block.data.exType[i],
              reps: block.data.exReps[i],
              repsRound: grouprounds,
              repsRoundStatic: block.data.exRepsRounds[i],
              resRepsGroup: block.data.exResRepsGroup[i],
              // Updating reps data
              resReps: reps,
              resRepsRound: repsround,
              
              weight: block.data.exWeight[i],
              weightRoundStatic: block.data.exWeightRounds[i],
              weightRound: groupweight,
              resWeightGroup: block.data.exResWeightGroup[i],
              // Updating weight data
              resWeight: weight,
              resWeightRound: weightround,

              weightType: exWeightType[i],
              work: block.data.exWork[i],
              rest: block.data.exRest[i],
              wmin: min1,
              wsec: block.data.exWork[i]-(min1*60),
              rmin: min2,
              rsec: block.data.exRest[i]-(min2*60),
              resMin: min3,
              resSec: block.data.exResWeight[i]-(min3*60),
              resMinGroup: m[i],
              resSecGroup: s[i],
              unit: unit,
              notes: notes,
            })
          }
          var name = ''
          if(block.data.name !== undefined) {
            name = block.data.name
          }

          if(!block.data.simple) {
            tmp1 = 0
            tmp2 = 0
            if(tmp0 > 0) {
              tmp1 = Math.floor(tmp0/tmpb)
              tmp2 = tmp0 % tmpb
            }
          }

          // Simple
          var snote = ''
          var snotes = ''
          if(block.data.notesResSimple !== undefined) {
            snote = block.data.notesResSimple
            snotes = block.data.notesResSimple
            if(attendees.length > 0) {
              var ars = block.data.notesResSimple.split('|')
              if(ars.length > this.state.current) {
                snote = ars[this.state.current]
              }
            }
          }

          tblk.push({
            id: block.id,
            type: block.data.type,
            cat: block.data.cat,
            rounds: block.data.rounds,
            cycles: cycles,
            name: name,
            emom: block.data.emom,
            notes: block.data.notes,
            resNotes: block.data.notesRes,
            exercises: ex,
            min: min,
            sec: block.data.rounds-(min*60),
            resMin: rmin,
            resSec: rsec,
            resMinGroup: rming,
            resSecGroup: rsecg,
            show: false,
            simple: simple,
            tmp1: tmp1,
            tmp2: tmp2,
            results: block.data.logResults,
            simpleNote: snote,
            simpleNotes: snotes,
            unitSimple: usimple,
            scaledSimple: ssimple,
            scaledSimple2: block.data.scaledSimple,
            valueSimple2: block.data.valueSimple
          })
        }
      }
    }

    this.setState({
      id: id,
      session: session,
      item: sitem,
      program: program,
      date: date,
      mins: tmp1a,
      secs: tmp2a,
      exercises: global.exercises,
      blocks: tblk,
      user: user,
      isTraining: isTraining,
      attendees: attendees,
      best: global.clientBest,
      current: current
    })
  }


  getBg() {
    var label = 'image'
    if(!this.state.done && this.state.num === 99) {
      var num = parseInt(Math.random()*6+1)
      global.num = num
      this.setState({
        num: num
      })
    }
    label = 'image bg'+this.state.num
    return label
  }



  // Interactive stuff ------------------------------------------------------------



  setTimeMin(event, index) {
    var tmp = this.state.blocks
    if(this.state.attendees.length === 0) {
      tmp[index].resMin = event.target.value
    } else {
      tmp[index].resMinGroup[this.state.current] = event.target.value
    }
    this.setState({
      blocks: tmp,
      changed: true,
    })
  }


  setTimeSec(event, index) {
    var tmp = this.state.blocks
    if(this.state.attendees.length === 0) {
      tmp[index].resSec = event.target.value
    } else {
      tmp[index].resSecGroup[this.state.current] = event.target.value
    }
    this.setState({
      blocks: tmp,
      changed: true,
    })
  }


  onChangeReps(event, num, index) {
    var tmp = this.state.blocks
    var val =  parseInt(event.target.value)
    if(event.target.value === '') {
      val = 0
    }
    tmp[num].exercises[index].resReps = val

    this.setState({
      blocks: tmp,
      changed: true,
    })
  }


  onChangeRepsRound(event, num, index) {
    var tmp = this.state.blocks
    var val = event.target.value
    if(val === '') { val = '0' }
    val = val.replace(/[^0-9-]/g, "")
    val = val.replace('--', '-')
    var reps = 0
    var ar = val.split('-')
    for(var a of ar) {
      if(a !== '') {
        reps += parseInt(a)
      }
      
    }
    tmp[num].exercises[index].resReps = reps
    tmp[num].exercises[index].resRepsRound = val
    
    this.setState({
      blocks: tmp,
      changed: true,
    })
  }


  onChangeWeight(event, num, index) {
    var tmp = this.state.blocks
    var val = parseFloat(event.target.value)
    if(event.target.value === '') {
      val = 0
    }
    tmp[num].exercises[index].resWeight = val

    this.setState({
      blocks: tmp,
      changed: true,
    })
  }


  onChangeWeightRound(event, num, index) {
    var tmp = this.state.blocks
    var val = event.target.value
    var vals = ''
    if(val === '') { val = '0' }
    val = val.replace(/[^0-9-.]/g, "")
    val = val.replace('--', '-')
    val = val.replace('.-', '-')
    val = val.replace('. ', '')
    val = val.replace('..', '.')
    var weight = 0
    var ar = val.split('-')
    for(var a of ar) {
      if(a !== '') {
        if(parseFloat(a) > weight) {
          weight = parseFloat(a)
        }
      }
      
    }
    tmp[num].exercises[index].resWeight = weight
    tmp[num].exercises[index].resWeightRound = val
    
    this.setState({
      blocks: tmp,
      changed: true,
    })
  }


  setTimeMinEx(event, num, index) {
    var tmp = this.state.blocks
    if(this.state.attendees.length === 0) {
      tmp[num].exercises[index].resMin = event.target.value
    } else {
      tmp[num].exercises[index].resMinGroup[this.state.current] = event.target.value
    }
    this.setState({
      blocks: tmp,
      changed: true,
    })
  }


  setTimeSecEx(event, num, index) {
    var tmp = this.state.blocks 
    if(this.state.attendees.length === 0) {
      tmp[num].exercises[index].resSec = event.target.value
    } else {
      tmp[num].exercises[index].resSecGroup[this.state.current] = event.target.value
    }
    this.setState({
      blocks: tmp,
      changed: true,
    })
  }


  toggleNotes(index) {
    var tmp = this.state.blocks
    tmp[index].show = !tmp[index].show
    this.setState({
      blocks: tmp,
    })
  }


  setNotes(event, index) {
    var tmp = this.state.blocks
    tmp[index].resNotes = event.target.value
    this.setState({
      blocks: tmp,
      changed: true,
    })
  }


  setSimple(event, index) {
    var tmp = this.state.blocks
    tmp[index].simpleNote = event.target.value
    this.setState({
      blocks: tmp,
      changed: true,
    })
  }


  setAR1(event, index) {
    var tmp = this.state.blocks
    if(!tmp[index].simple) {
      var reps = 0
      if(tmp[index].tmp2 !== undefined && tmp[index].tmp1 !== '') {
        reps = parseInt(tmp[index].tmp2)
      }
      for(var ex of tmp[index].exercises) {
        ex.resReps = ex.reps*parseInt(event.target.value)
        if(reps > 0) {
          if(reps > ex.reps) {
            ex.resReps += ex.reps
            reps -= ex.reps
          } else {
            ex.resReps += reps
            reps = 0
          }
        }
      }
    }
    tmp[index].tmp1 = event.target.value
    this.setState({
      blocks: tmp,
      changed: true
    })
  }


  setAR2(event, index) {
    var tmp = this.state.blocks
    var rounds = 0
    var reps = 0
    if(event.target.value !== '') {
      reps = parseInt(event.target.value)
    }
    if(tmp[index].tmp1 !== undefined && tmp[index].tmp1 !== '') {
      rounds = parseInt(tmp[index].tmp1)
    }
    for(var ex of tmp[index].exercises) {
      ex.resReps = ex.reps*rounds
      if(reps > 0) {
        if(reps > ex.reps) {
          ex.resReps += ex.reps
          reps -= ex.reps
        } else {
          ex.resReps += reps
          reps = 0
        }
      }
    }
    tmp[index].tmp2 = event.target.value
    this.setState({
      blocks: tmp,
      changed: true
    })
  }


  updateScaled(index, value) {
    var tmp = this.state.blocks
    tmp[index].scaledSimple = value
    this.setState({
      blocks: tmp,
      changed: true
    })
  }



  // Update results ------------------------------------------------------------



  updateResults() {
    var tmp  = this.state.program.blocks
    var parent = global.uid
    if(this.state.isTraining) {
      parent = global.uidUser
    }
    for(var i=0; i<this.state.blocks.length; i++) {
      var block = this.state.blocks[i]
      var weight = []
      var reps = []
      var repsr = []
      var repsgroup = [] //Group reps
      var weightgroup = [] //Group weight
      var tgarr = []
      var weightr = []
      var excount = 0

      // Harmonize AMRAP
      if(block.type === 0 && !block.simple) {
        if(block.tmp2 !== 0 && block.tmp2 !== '') {
          var tr = 0
          for(var ex1 of block.exercises) {
            tr += ex1.reps
          }
          if(block.tmp2 > tr) {
            block.tmp1 = parseInt(block.tmp1)+Math.floor(block.tmp2/tr)
            block.tmp2 = block.tmp2 % tr
          }
        }
      }
      
      for(var ex of block.exercises) {
        var rn = 0
        if(block.tmp1 !== '') {
          rn = parseInt(ex.reps*block.tmp1)
        }
        if(block.type !== 0) {
          reps.push(parseInt(ex.resReps))
        } else {
          var add = 0
          if(block.tmp2 !== 0 && block.tmp2 !== '') {
            add = block.tmp2
            for(var rni=0; rni<excount; rni++) {
              add -= this.state.program.blocks[i].data.exReps[rni]
            }
            if(add > ex.reps) {
              add = ex.reps
            }
            if(add < 0) {
              add = 0
            }
            
          }
          rn += parseInt(add)
          reps.push(parseInt(rn))
        }
        
        var rrl = ex.resRepsRound
        var exrepsgroup = ''
        if(rrl[0] === '-') {
          rrl = rrl.substring(1, rrl.length)
        }
        if(rrl[rrl.length-1] === '-') {
          rrl = rrl.substring(0, rrl.length-1)
        }

        var wrl = ex.resWeightRound
        var exweightgroup = ''
        if(wrl[0] === '-') {
          wrl = wrl.substring(1, wrl.length)
        }
        if(wrl[wrl.length-1] === '-') {
          wrl = wrl.substring(0, wrl.length-1)
        }

        // Group sessions
        if(this.state.attendees.length > 0) {
          // Reps
          rrl = ''
          exrepsgroup = ''
          wrl = ''
          exweightgroup = ''
          var argroup1 = ex.resRepsGroup.split('-')
          var argroup11 = ex.repsRound.split('|')
          var argroup2 = ex.resWeightGroup.split('-')
          var argroup21 = ex.weightRound.split('|')
          for(var ia=0; ia<this.state.attendees.length; ia++) {
            if(ia === this.state.current) {
              exrepsgroup += '-'+ex.resReps
              rrl += ex.resRepsRound+'|'
              exweightgroup += '-'+ex.resWeight
              wrl += ex.resWeightRound+'|'
            } else {
              // Reps
              if(argroup1.length > ia) {
                exrepsgroup += '-'+argroup1[ia]
              } else {
                //exrepsgroup += '-'+ex.reps
                exrepsgroup += '-0'
              }
              if(argroup11.length > ia) {
                rrl += argroup11[ia]+'|'
              } else {
                //rrl += ex.repsRound+'|'
                rrl += '0|'
              }
              // Weight
              if(argroup2.length > ia) {
                exweightgroup += '-'+argroup2[ia]
              } else {
                //exweightgroup += '-'+ex.weight
                exweightgroup += '-0'
              }
              if(argroup21.length > ia) {
                wrl += argroup21[ia]+'|'
              } else {
                //wrl += ex.weightRound+'|'
                wrl += '0|'
              }
            }
          }
          exrepsgroup = exrepsgroup.substring(1, exrepsgroup.length)
          exweightgroup = exweightgroup.substring(1, exweightgroup.length)
        }

        repsr.push(rrl)
        repsgroup.push(exrepsgroup)
        weightr.push(wrl)
        weightgroup.push(exweightgroup)

        if(ex.tool !== 6 && ex.tool !== 7) {
          weight.push(parseFloat(ex.resWeight))
        } else {
          weight.push(parseInt(ex.resMin)*60+parseInt(ex.resSec))
        }
        if(this.state.attendees.length === 0) {
          this.updateBest(ex)
        } else {
          this.updateBestGroup(ex)
        }
        excount++
      }

      // Completion time
      if(block.type === 0 || block.type === 5) {
        if(this.state.attendees.length > 0) {
          for(var bi1=0; bi1<this.state.attendees.length; bi1++) {
            var num = parseInt(block.resMinGroup[bi1])*60+parseInt(block.resSecGroup[bi1])
            if(isNaN(num)) {
              tgarr.push(0)
            } else {
              tgarr.push((parseInt(block.resMinGroup[bi1])*60+parseInt(block.resSecGroup[bi1])))
            }
          }
          tmp[i].data.timeResGroup = tgarr
        }
      }

      tmp[i].data.notesRes = block.resNotes
      tmp[i].data.timeRes = parseInt(block.resMin)*60+parseInt(block.resSec)
      tmp[i].data.exResWeight = weight
      tmp[i].data.exResWeightGroup = weightgroup
      tmp[i].data.exResReps = reps
      tmp[i].data.exResRepsGroup = repsgroup

      tmp[i].data.exResRepsRounds = repsr
      tmp[i].data.exResWeightRounds = weightr

      //if(tmp[i].data.simple) {
        //var notes = block.simpleNotes
        var notes = ''
        if(this.state.attendees.length > 0) {
          var ars = block.simpleNotes.split('|')
          for(var iss=0; iss<this.state.attendees.length; iss++) {
            if(iss === this.state.current) {
              if(block.simpleNote === '') {
                notes += '-|'
              } else {
                notes += block.simpleNote+'|'
              }
            } else {
              if(ars.length > iss) {
                if(ars[iss] !== '') {
                  notes += ars[iss]+'|'
                } else {
                  notes += '-|'
                }
              } else {
                notes += '-|'
              }
            }
          }
          notes = notes.substring(0, notes.length-1)
        } else {
          notes = block.simpleNote
        }
        tmp[i].data.notesResSimple = notes
      //}

      if(block.simple) {
        var a1 = "0"
        var a2 = "0"
        if(block.tmp1 != "") { a1 = block.tmp1}
        if(block.tmp2 != "") { a2 = block.tmp2}
        if(block.type === 0) {
          if(this.state.item.data.group) {
            if(tmp[i].data.amrapSimple !== undefined) {
              if(tmp[i].data.amrapSimple.length > this.state.current) {
                // do nothing
              } else {
                var tmpa1 = []
                for(var ai1=tmp[i].data.amrapSimple.length; ai1 < this.state.item.data.clients.length; ai1++) {
                  tmpa1.push("0+0")
                }
                tmp[i].data.amrapSimple = tmpa1
              }
            } else {
              var tmpa2 = []
              for(var ai2=0; ai2 < this.state.item.data.clients.length; ai2++) {
                tmpa2.push("0+0")
              }
              tmp[i].data.amrapSimple = tmpa2
            }
            tmp[i].data.amrapSimple[this.state.current] = a1+"+"+a2
          } else {
            tmp[i].data.amrapSimple = [a1+"+"+a2]
          }
        } else if(block.type !== 0 && block.type !== 5) {
          if(this.state.item.data.group) {
            if(tmp[i].data.valueSimple !== undefined) {
              if(tmp[i].data.valueSimple.length > this.state.current) {
                // do nothing
              } else {
                var tmpv1 = []
                for(var vi1=tmp[i].data.amrapSimple.length; vi1 < this.state.item.data.clients.length; vi1++) {
                  tmpv1.push(0)
                }
                tmp[i].data.valueSimple = tmpv1
              }
            } else {
              var tmpv2 = []
              for(var vi2=0; vi2 < this.state.item.data.clients.length; vi2++) {
                tmpv2.push(0)
              }
              tmp[i].data.valueSimple = tmpv2
            }
            tmp[i].data.valueSimple[this.state.current] = parseFloat(a1)
          } else {
            tmp[i].data.valueSimple = [parseFloat(a1)]
          }
        }

        if(this.state.item.data.group) {
          if(tmp[i].data.scaledSimple !== undefined) {
            if(tmp[i].data.scaledSimple.length > this.state.current) {
              // do nothing
            } else {
              var tmps1 = []
              for(var si1=tmp[i].data.scaledSimple.length; si1 < this.state.item.data.clients.length; si1++) {
                tmps1.push(false)
              }
              tmp[i].data.scaledSimple = tmps1
            }
          } else {
            var tmps2 = []
            for(var si2=0; si2 < this.state.item.data.clients.length; si2++) {
              tmps2.push(false)
            }
            tmp[i].data.scaledSimple = tmps2
          }
          tmp[i].data.scaledSimple[this.state.current] = block.scaledSimple
        } else {
          tmp[i].data.scaledSimple = [block.scaledSimple]
        }
      }
      console.log(tmp[i].data)

      Firebase.database().ref('/sessions/'+parent+'/'+this.state.session+'/workout/'+this.state.program.id+'/blocks/'+tmp[i].id).update(
        tmp[i].data
      ).then((data)=>{
        EventEmitter.dispatch('showMessage', lang.t('messaging:message.resultsupdated'))
        setTimeout(() => {
          this.setState({
            changed: false
          })
        }, 100);
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    }
  }


  updateBest(ex) {
    var update = true
    var final = ex.resWeight
    var actual = ex.resWeight
    var per = 0
    var tmp = this.state.best

    for(var item of tmp) {
      if(item.id === ex.id) {
        if(ex.tool !== 6 && ex.tool !== 7) {
          if(ex.weight > 0 && ex.weightType === 'per') {
            final = ex.resWeight*(100/ex.weight)
            per = ex.weight
          }
          
          var rval = item.data.value
          var rtype = ""
          if(item.data.type !== undefined) {
            rtype = item.data.type
          }
          if((rtype === "kg" && ex.weightType === "lb") || (rtype === "kg" && ex.weightType === "per" && global.spaceLbs)) {
            rval = item.data.value*global.lbsUp
          }
          if((rtype === "lb" && ex.weightType === "kg") || (rtype === "lb" && ex.weightType === "per" && !global.spaceLbs)) {
            rval = item.data.value*global.lbsDown
          }
          if(rval > final) {
            update = false
          }
        }
        if(item.data.value < ex.resWeight && (ex.tool === 6 || ex.tool === 7)) {
          update = false
        }
        if(update) {
          item.data.value = final
          this.setState({
            best: tmp
          })
        }
        
      }
      
    }
    var unit = ''
    if(ex.unit !== undefined) {
      unit = ex.unit
    }
    if(update && ex.tool !== 0 && ex.tool !== 9 && !isNaN(final)) {
      Firebase.database().ref('/records/'+global.uidUser+'/'+ex.id).update({
        date: Moment(this.state.date, 'lll').format('DD/MM/YYYY HH:mm'),
        id: ex.id,
        name: ex.name,
        tool: ex.tool,
        value: final,
        actual: actual,
        percent: per,
        unit: unit
      })
    }
  }


  updateBestGroup(ex) {
    
      var update = true
      var final = 0
      var actual = 0
      var per = 0
      var tmp = this.state.best

      if(ex.resWeightGroup !== undefined && ex.resWeightGroup.length > this.state.current) {
        final = parseFloat(ex.resWeightGroup[this.state.current])
        actual = parseFloat(ex.resWeightGroup[this.state.current])
        if(ex.weight > 0 && ex.weightType === 'per') {
          final = parseFloat(ex.resWeightGroup[this.state.current])*(100/ex.weight)
          per = ex.weight
        }
      }

      if(ex.tool === 0 && ex.tool === 9) {
        update = false
      }
      for(var cb of tmp) {
        if(cb.id === ex.id) {
          var rval = cb.data.value
          var rtype = ""
          if(cb.data.type !== undefined) {
            rtype = cb.data.type
          }
          if((rtype === "kg" && ex.weightType === "lb") || (rtype === "kg" && ex.weightType === "per" && global.spaceLbs)) {
            rval = cb.data.value*global.lbsUp
          }
          if((rtype === "lb" && ex.weightType === "kg") || (rtype === "lb" && ex.weightType === "per" && !global.spaceLbs)) {
            rval = cb.data.value*global.lbsDown
          }
          if(rval > final || rval === final) {
            update = false
          }

          if(update) {
            cb.data.value = final
            this.setState({
              best: tmp
            })
          }
        }
      }

      if(final === 0) {
        update = false
      }
      var unit = ''
      if(ex.unit !== undefined) {
        unit = ex.unit
      }
      if(update && ex.tool !== 0 && ex.tool !== 9 && !isNaN(final)) {
        Firebase.database().ref('/records/'+global.uidUser+'/'+ex.id).update({
          //date: Moment().format('DD/MM/YYYY HH:mm'),
          date: Moment(this.state.date, 'lll').format('DD/MM/YYYY HH:mm'),
          id: ex.id,
          name: ex.name,
          tool: ex.tool,
          value: final,
          actual: actual,
          percent: per,
          unit: unit
        })
      }
    //}
  }



  // Video overlay ------------------------------------------------------------



  showOverlayVideo() {
    this.setState({showOverlayVideo: true})
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenVideo: '',
        margin: -height/2,
      });
    }, 100);
  }


  hideOverlayVideo() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenVideo: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showOverlayVideo: false});
    }, 500);
  }


  renderPlayer() {
    var arr = this.state.program.data.video.split('/')
    if(this.state.program.data.video.indexOf('youtu') !== -1) {
      var link = arr[arr.length-1]
      if(arr[arr.length-1].indexOf('?')) {
        var ar1 = arr[arr.length-1].split('=')
        var ar11 = ar1[ar1.length-2].split('&')
        link = ar11[0]
      }
      return (
        <iframe width="100%" height="470" src={'https://www.youtube.com/embed/'+link} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      )
    } else {
      var ar2 = arr[arr.length-1].split('?')
      return (
        <iframe src={'https://player.vimeo.com/video/'+ar2[0]+'?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'} frameborder="0" width="100%" height="470" title="Video player"></iframe>
      )
    }
  }


  renderOverlayVideo() {
    if(this.state.showOverlayVideo) {
      return (
        <div className={'overlay '+this.state.hiddenVideo}>
          <div className="box mainvideo auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h3>{lang.t('common:button.video')}</h3>
            <button className="btn tertiary small close" onClick={() => this.hideOverlayVideo()}>{lang.t('modal:common.close')}</button>
            <div className="clear"></div>
            {this.renderPlayer()}
          </div>
        </div>
      )
    }
  }



  // Movements stuff ------------------------------------------------------------



  renderImage(img) {
    if(img === '') {
      return (
        <div className="image"></div>
      )
    } else if(img.indexOf('adm-') !== -1) {
      return (
        <div className="image" style={{backgroundImage: 'url(/img/exercises/'+img+'.jpg)'}}></div>
      )
    } else {
      return (
        <div className="image" style={{backgroundImage: 'url('+img+')'}}></div>
      )
    }
  }


  getSetTitle(block) {
    var label = lang.t('training:prog.set')
    if(block.exercises.length > 1) {
      label = lang.t('training:prog.dropset')
      var id = ''
      for(var ex of block.exercises) {
        if(ex.id !== id && id !== '') {
          label = lang.t('training:prog.superset')
        }
      }
    }
    return label
  }


  renderBlockTypeStatic(item) {
    var label = ''
    if(item.type === 0) {
      label = ' - '+HelperCal.getDurationMin(item.rounds)
    }
    if(item.type === 1 || item.type === 2 || item.type === 3) {
      label = ' - '+item.rounds+' '+lang.t('training:prog.rounds')
      if(item.rounds === 1) {
        label = ' - 1 '+lang.t('training:prog.round')
      }
      if(item.type === 1 && item.emom) {
        label += ' '+lang.t('training:prog.allmovements')
      }
      if(item.type === 2 && item.emom) {
        label += ' ('+HelperCal.getDurationMin(item.exercises[0].work)+' - '+HelperCal.getDurationMin(item.exercises[0].rest)+')'
      }
    }
    if(item.type === 4) {
      label = ' - '+item.rounds+' '+this.getSetTitle(item)
      if(item.rounds > 1) {
        label += 's'
      }
    }
    if(item.type === 5) {
      label = ' - '+HelperCal.getDurationMin(item.rounds)
      if(item.cycles > 1) {
        label = ' - '+item.cycles+' '+lang.t('training:prog.rounds')+' - '+lang.t('training:prog.cap')+': '+HelperCal.getDurationMin(item.rounds)
      }
    }
    return label
  }


  renderRepsTitleStatic(item, type, unit) {
    var label = lang.t('training:target.reps')
    if(type === 'result') {
      label = lang.t('training:target.repsdone')
    }
    if(unit !== '' && unit !== undefined && unit !== 'reps') {
      if(unit === 'dist') {
        label = lang.t('training:target.distance')
        if(type === 'result') {
          label = lang.t('training:target.distancedone')
        }
      }
      if(unit === 'cals') {
        label = lang.t('training:target.cal')
        if(type === 'result') {
          label = lang.t('training:target.caldone')
        }
      }
      if(unit === 'time') {
        label = lang.t('training:target.time')
        if(type === 'result') {
          label = lang.t('training:target.timedone')
        }
      }
    } else {
      if(item === 6 || item === 7) {
        label = lang.t('training:target.distance')
        if(type === 'result') {
          label = lang.t('training:target.distancedone')
        }
      }
    }
    return label
  }


  renderRepsStatic(item, num) {
    var label = item.reps
    var wlabel = ''
    if(item.reps === 0) {
      label = ''
    }

    if(item.weight !== 0 && item.weight !== 100 && item.weightType === 'per') {
      wlabel = ' '+lang.t('training:prog.at')+' '+item.weight+'% '+lang.t('training:prog.of1rm')
    }
    if(item.weight !== 0 && item.weight !== 100 && item.weightType !== 'per') {
      wlabel += ' '+lang.t('training:prog.with')+' '+item.weight+' '+item.weightType
    }
    if(this.state.blocks[num].type === 1 || this.state.blocks[num].type === 5) {
      label = ''
      if(item.repsRoundStatic !== "" && item.repsRoundStatic !== "0") {
        label = item.repsRoundStatic.replaceAll('-', ' - ')
      }
      if(item.weightRoundStatic !== "" && item.weightRoundStatic !== '0') {
        if(item.weightType === 'kg') {
          wlabel = ' '+lang.t('training:prog.with')+' '+item.weightRoundStatic.replaceAll('-', ' - ')+' kg'
        } else if(item.weightType === 'lb') {
          wlabel = ' '+lang.t('training:prog.with')+' '+item.weightRoundStatic.replaceAll('-', ' - ')+' lb'
        } else {
          wlabel = ' '+lang.t('training:prog.at')+' '+item.weightRoundStatic.replaceAll('-', ' - ')+' %'
        }
      }
    } 

    if(item.unit !== undefined && item.unit !== '') {
      if(item.unit === 'dist') {
        label += 'm'
      }
      if(item.unit === 'cals') {
        label += 'cal'
      }
      if(item.unit === 'time') {
        label += 's'
      }
    } else {
      if(item.tool === 6 || item.tool === 7) {
        label += 'm'
      }
      if(item.tool === 27) {
        label += 'cal'
      }
      if(item.tool === 28) {
        label += 's'
      }
    }
    
    return [label, wlabel]
  }


  renderRepField(item, num, index) {
    if(this.state.blocks[num].rounds > 1 || this.state.blocks[num].cycles > 1) {
      return (
        <input type="text" className="light" value={item.resRepsRound} onChange={(val) => this.onChangeRepsRound(val, num, index)}/>
      )
    } else {
      return (
        <input type="number" className="light" value={item.resReps} onChange={(val) => this.onChangeReps(val, num, index)}/>
      )
    }
  }


  renderReps(item, num, index) {
    return (
      <div className="col-3">
        <label>{this.renderRepsTitleStatic(item.tool, 'result', item.unit, item.name)}</label>
        {this.renderRepField(item, num, index)}
      </div>
    )
  }


  renderWeightField(item, num, index) {
    if(this.state.blocks[num].rounds > 1 || this.state.blocks[num].cycles > 1) {
      return (
        <input type="text" className="light" value={item.resWeightRound} onChange={(val) => this.onChangeWeightRound(val, num, index)}/>
      )
    } else {
      return (
        <input type="number" className="light" value={item.resWeight} onChange={(val) => this.onChangeWeight(val, num, index)}/>
      )
    }
  }


  renderStaticWeight(item) {
    var label = item.resWeight+' '+(global.spaceLbs ? 'lb' : 'kg')
    if(item.weightType !== 'per') {
      label = item.resWeight+' '+item.weightType
    }
    if(item.resWeightRound !== '') {
      var tmp = item.resWeightRound.substring(0, item.resWeightRound.length-1)
      var tmp = item.resWeightRound.substring(0, item.resWeightRound.length-1)
      label = tmp+' '+(global.spaceLbs ? 'lb' : 'kg')
      if(item.weightType !== 'per') {
        label = tmp+' '+item.weightType
      }
    }
    return label
  }


  renderWeight(item, num, index) {
    if(item.tool !== 0 && item.tool !== 6 && item.tool !== 7 && item.tool !== 9) {
      return (
        <div className="col-3">
          <label>{lang.t('training:label.weightused')} ({item.weightType !== 'per' ? item.weightType : (global.spaceLbs ? 'lb' : 'kg')})</label>
          {this.renderWeightField(item, num, index)}
        </div>
      )
    }
  }



  // Modals ------------------------------------------------------------



  hideModals() {
    this.setState({
      showModalLeader: false,
    })
  }



  // Display stuff ------------------------------------------------------------



  renderNotesStatic(item) {
    if(item.notes !== '') {
      return (
        <div className="clear" style={{paddingTop: 5}}>
          <span className="program-notes movement">{item.notes}</span>
        </div>
      )
    }
  }


  renderExerciseRest(block, index) {
    if(block.type === 2 && !block.emom && block.exercises[index].rest !== 0 && block.exercises[index].rest !== undefined && block.exercises[index].rest !== '') {
      return (
        <div className="box list simple mb-10">
          <div className="col-10">
            <div className="icon secondary lft mr-10">
              <div className="inner prog-rest"></div>
            </div>
            <p className="lft"><strong>{lang.t('training:label.rest')}</strong></p>
          </div>
          <div className="col-2 program-time">
            <p>{HelperCal.getDurationMin(parseInt(block.exercises[index].rest))}</p>
          </div>
          <div className="clear"></div>
        </div>
      )
    }
  }


  renderTimeStatic(block, index) {
    if((block.type === 1 || block.type === 2) && !block.emom) {
      return (
        <div className="col-2 program-time">
          <p>{HelperCal.getDurationMin(parseInt(block.exercises[index].work))}</p>
        </div>
      )
    }
  }


  renderSimple(block, index) {
    if(block.simple && block.results) {
      return (
        <div className="col-12 mb-50">
          <label>Results</label>
          <textarea value={block.simpleNote} onChange={(event) => this.setSimple(event, index)}></textarea>
        </div>
      )
    }
  }


  renderExercisesStatic(num) {
    var block = this.state.blocks[num]
    if(!block.simple) {
      return (
        <div>
          {block.exercises.map((item, index) => (
            <div>
              <div className="box list simple mb-10" key={index}>
                <div className="col-10">
                  <div className="icon primary mr-10">
                    <div className={'inner '+global.exCatsIcons[block.cat]}></div>
                  </div>
                  <p className="lft"><strong>{this.renderRepsStatic(item, num)[0]} {item.name}</strong> {this.renderRepsStatic(item, num)[1]}</p>
                </div>
                {this.renderTimeStatic(block, index)}
                <div className="clear"></div>
                <div className="program-results">
                  {this.renderReps(item, num, index)}
                  {this.renderWeight(item, num, index)}
                  {this.renderNotesStatic(item)}
                  <div className="clear"></div>
                </div>
                <div className="clear"></div>
              </div>
              {this.renderExerciseRest(block, index)}
            </div>
          ))}
        </div>
      )
    }
  }


  renderStaticNotes(item) {
    if(item.notes !== '') {
      return <p className={item.simple ? 'mb-20 pl-10' : 'small mb-20 pl-10'} style={{whiteSpace: 'pre-wrap'}}>{item.notes}</p>
    }
  }


  renderTime(item, index) {
    if(item.type === 5) {
      if(this.state.isTraining) {
        return (
          <div className="info">
            <label>{lang.t('training:label.completiontime')}</label>
            <p>{HelperCal.getDurationMin(item.resMin*60+item.resSec)}</p>
          </div>
        )
      } else {
        if(this.state.attendees.length === 0) {
          return (
            <div className="mb-20">
              <div className="form-item col-3">
                <label>{lang.t('training:label.completiontime')}</label>
                <input type="text" value={item.resMin} onChange={event => this.setTimeMin(event, index)}/>
              </div>
              <div className="col-3">
                <label>&nbsp;</label>
                <input type="text" value={item.resSec} onChange={event => this.setTimeSec(event, index)}/>
              </div>
              <div className="clear"></div>
            </div>
          )
        } else {
          return (
            <div className="mb-20">
              <div className="col-3">
                <label>{lang.t('training:label.completiontime')}</label>
                <input type="text" value={item.resMinGroup[this.state.current]} onChange={event => this.setTimeMin(event, index)}/>
              </div>
              <div className="col-3">
                <label>&nbsp;</label>
                <input type="text" value={item.resSecGroup[this.state.current]} onChange={event => this.setTimeSec(event, index)}/>
              </div>
              <div className="clear"></div>
            </div>
          )
        }
      }
    } else if(item.type === 0) {
      return (
        <div className="mb-20">
          <div className="col-3">
            <label>{lang.t('training:label.finishedrounds')}</label> 
            <input type="number" className="small" value={item.tmp1} onChange={(val) => this.setAR1(val, index)}/>
          </div>
          <div className="col-3">
            <label>{lang.t('training:label.reps')}</label>
            <input type="number" className="small" value={item.tmp2} onChange={(val) => this.setAR2(val, index)}/>
          </div>
          <div className="clear"></div>
        </div>
      )
    } else if(item.simple) {
      return (
        <div className="mb-20">
          <div className="col-3">
            <label>{lang.t('training:form.result'+item.unitSimple)}</label> 
            <input type="number" className="small" value={item.tmp1} onChange={(val) => this.setAR1(val, index)}/>
          </div>
          <div className="clear"></div>
        </div>
      )
    }
  }


  renderScaled(block, index) {
    if(block.simple) {
      return (
        <div className="col-12 clear">
          <InputToggle label='' value={block.scaledSimple} onChange={(event) => this.updateScaled(index, event)} text={'Scaled'}/>
        </div>
      )
    }
  }


  renderStaticRest(block) {
    if(block.type === 4 && block.exercises[0].work !== 0 && block.exercises[0].work !== undefined) {
      return (
        <div className="box list simple mb-10">
          <div className="col-10">
          <div className="icon secondary lft mr-10">
              <div className="inner prog-rest"></div>
            </div>
            <p className="lft"><strong>{lang.t('training:label.rest')}</strong></p>
          </div>
          <div className="col-2 program-time">
            <p>{HelperCal.getDurationMin(parseInt(block.exercises[0].work))}</p>
          </div>
          <div className="clear"></div>
        </div>
      )
    }
  }


  renderBlockTitleStatic(item) {
    if(item.name !== '' && item.name !== undefined) {
      return item.name
    } else {
      return lang.t(global.exCats[item.cat])
    }
  }


  renderBlockLeaderboard(item) {
    if(item.results && global.spaceAllowHabits && !this.state.isTraining) {
      return <button className="btn tertiary small rgt" onClick={() => this.setState({showModalLeader: true, leaderBlock: item})}>Leaderboard</button>
    }
  }

  
  renderBlocksStatic() {
    return (
      <div>
        {this.state.blocks.map((item, index) => (
          <div key={index}>
            <div className="clear mb-50">
              <label>{this.renderBlockTitleStatic(item)}</label>
              <h3 className="clear lft mb-20">{lang.t(global.proTypes[item.type])} {this.renderBlockTypeStatic(item)}</h3>
              {this.renderBlockLeaderboard(item)}
              <div className="clear"></div>
              {this.renderTime(item, index)}
              {this.renderScaled(item, index)}
              {this.renderExercisesStatic(index)}
              {this.renderStaticRest(item)}
              {this.renderStaticNotes(item)}
              {this.renderSimple(item, index)}
            </div>
          </div>
        ))}
      </div>
    )
  }


  renderBenchmarkPill() {
    if(this.state.program.data.benchmark) {
      return <p className="mb-20"><span className="pill primary">{lang.t('training:label.benchmark')}</span></p>
    }
  }


  renderButtonTop() {
    if(this.state.changed) {
      return (
        <div className="program-float">
          <p className="mb-10">{lang.t('training:label.unsaved')}</p>
          <button className="btn tertiary small width-12" onClick={() => this.updateResults()}>{lang.t('training:button.updateresults')}</button>
        </div>
      )
    }
  }


  renderButton() {
    if(this.state.changed) {
      return (
        <div>
          <div className="width-6">
            <button className="btn primary" onClick={() => this.updateResults()}>{lang.t('training:button.updateresults')}</button>
          </div>
          <div className="space-40"></div>
        </div>
      )
    }
  }


  renderButtonVideo() {
    if(this.state.program.data.video !== undefined && this.state.program.data.video !== "") {
      return (
        <button className="btn tertiary width-12" onClick={() => this.showOverlayVideo()}>{lang.t('common:button.video')}</button>
      )
    }
  }


  renderContent() {
    if(this.state.program !== null) {
      return (
        <div>
          <div className="program-header mb-60">
            <div className={'icon large lft mr-20 '+HelperCal.getColor(this.state.program.data.time)}>
              <p>{this.state.program.data.time}'</p>
            </div>
            <div className="main">
              <h2>{this.state.program.data.name}</h2>
              {this.renderBenchmarkPill()}
              <p className="mb-10">{this.state.program.data.desc}</p>
              {this.renderButtonVideo()}
            </div>
            <div className="clear"></div>
          </div>
          {this.renderBlocksStatic()}
          <div className="sv-30"></div>
          {this.renderButton()}
        </div>
      )
    }
  }


  renderSession() {
    var label = lang.t('calendar:label.results')
    if(this.state.session !== '') {
      for(var item of global.sessions) {
        if(item.id === this.state.session) {
          if(item.data.group) {
            if(item.data.client !== '') {
              label = item.data.client+' - '+Moment(item.data.timestamp, 'X').locale(lang.language).format('ddd, D MMM - LT')
            } else {
              label = lang.t('calendar:label.group')+' - '+Moment(item.data.timestamp, 'X').locale(lang.language).format('ddd, D MMM - LT')
            }
          } else {
            label = lang.t('calendar:label.pt')+' - '+Moment(item.data.timestamp, 'X').locale(lang.language).format('ddd, D MMM - LT')
          }
        }
      }
      for(var item2 of global.sessionsTraining) {
        if(item2.id === this.state.session) {
          label = lang.t('calendar:label.training')+' - '+Moment(item2.data.timestamp, 'X').locale(lang.language).format('ddd, D MMM - LT')
        }
      }
    }
    return label
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content form-sidebar mt-20">
          {this.renderContent()}
        </div>
        <div className="header">
          <div className="content">
            <Link to={'/'+global.uid+'/session/'+this.state.session} className="back lft mr-10">
              <div className="circle"></div>
              <div className="arrow"></div>
            </Link>
            <h2>{this.renderSession()}</h2>
          </div>
        </div>
        <Navigation active='calendar' />
        {this.renderButtonTop()}
        {this.renderOverlayVideo()}
        <ModalLeaderboard show={this.state.showModalLeader} type={'notes'} session={this.state.item} block={this.state.leaderBlock} onHide={() => this.hideModals()}/>
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['header','common','calendar','modal','training','message','messaging'])(withRouter(withAuthorizationMember(condition)(MemberResultsPage)));