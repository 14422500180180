import React, { Component } from 'react';
import Firebase from 'firebase';
import 'firebase/functions';
import Moment from 'moment';
import 'moment/locale/es';
import Connector from '../../data/Connector';
import Resizer from "react-image-file-resizer";

import Navigation from '../../components/Navigation';
import HelperEmptyImg from '../../helper/EmptyLarge';
import HelperPage from '../../helper/Page';
import * as ROUTES from '../../constants/routes';
import { withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import InputRadio from '../../components/Form/radio';
import ModalAvatar from '../../components/Modals/avatar';
import { AuthUserContext, withAuthorizationMember } from '../../components/Session';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class MemberAccountPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: global.userName,
      email: global.userEmail,
      phone: global.userPhone,
      birth: global.userBday,
      image: global.userImage,
      height: global.coupon,
      emergency: global.emergency,
      values: [global.userName, global.userEmail, global.userPhone, global.userBday],
      valuesEc: global.emergency,
      file: null,
      fileDisplay: null,
      error: [false, false, false, false],
      editing: false,
      showOverlayPass: false,
      hiddenPass: 'hidden',
      valuesPass: ['', '', ''],
      errorPass: [false, false, false],
      margin: 0,
      message: '',
      valueHeight: global.coupon,
      lbs: global.userLbs,
      reminder: global.clientEmailReminder,
      imageType: '',
      avatar: global.userAvatar,
      newimage: false,
      showModalAvatar: false,
      //
      done: false
    };
  }


  componentDidMount() {
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-2]
    if(id !== 'account' && id !== 'member') {
      global.uid = id
    }
    Connector.setUser()
    document.title = lang.t('header:title.account')+' - '+global.userBusiness
    window.scrollTo(0, 0)

    this.setState({
      done: true
    })

    if(global.message !== '') {
      setTimeout(() => {
        EventEmitter.dispatch('showMessage', global.message);
        global.message = ''
      }, 300);
      
    }
    EventEmitter.subscribe('userLoaded', (event) => this.checkSpace())
    this.configureData()
  }


  checkSpace() {
    if(global.uid === '') {
      this.props.history.push(ROUTES.LOADING)
    } else {
      var found = false
      for(var item of global.userStaff) {
        if(item.id === global.uid) {
          found = true
        }
      }
      if(found) {
        document.title = lang.t('header:title.account')+' - '+global.userBusiness
        this.configureData()
      } else {
        this.props.history.push(ROUTES.LOADING)
      }
    }
  }


  configureData() {
    var bnm = []
    if(global.userStripeAddress !== '' && global.userStripeAddress !== undefined) {
      bnm = global.userStripeAddress.split('||')
    }
    var height = global.coupon
    if(global.userLbs && global.coupon !== 0) {
      var h = global.coupon/2.54
      var h1 = parseInt(h/12)
      var h2 = (h-(h1*12)).toFixed(0)
      height = h1+"."+h2
    }

    var itype = ''
    if(global.userAvatar !== '') {
      itype = 'avatar'
    }
    if(global.userImage !== '') {
      itype = 'image'
    }

    this.setState({
      name: global.userName,
      email: global.userEmail,
      phone: global.userPhone,
      birth: global.userBday,
      image: global.userImage,
      emergency: global.emergency,
      height: global.coupon,
      values: [global.userName, global.userEmail, global.userPhone, global.userBday],
      valuesEc: global.emergency,
      bname: bnm,
      valueHeight: height,
      lbs: global.userLbs,
      reminder: global.clientEmailReminder,
      avatar: global.userAvatar,
      imageType: itype,
    });
    this.getImage()
  }


  getBg() {
    var label = 'image'
    if(!this.state.done && this.state.num === 99) {
      var num = parseInt(Math.random()*6+1)
      global.num = num
      this.setState({
        num: num
      })
    }
    label = 'image bg'+this.state.num
    return label
  }


  getImage() {
    if(global.userImage === '') {
      var inits = ''
      let arr = global.userName.split(' ')
      if(arr.length > 1) {
        inits = arr[0].charAt(0)+arr[1].charAt(0)
      } else {
        inits = arr[0].charAt(0)
      }
      if(global.userAvatar !== '') {
        return <div className={'avatar '+global.userAvatar}></div>
      } else {
        return (
          <div className="avatar">
            <p>{inits}</p>
          </div>
        )
      }
    } else {
      return (
        <div className="avatar" style={{backgroundImage: 'url('+global.userImage+')'}}> </div>
      )
    }
  }



  // Change password ------------------------------------------------------------



  showOverlayPass() {
    this.setState({
      showOverlayPass: true,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenPass: '',
        margin: -height/2,
        valuesPass: ['', '', ''],
        errorPass: [false, false, false]
      });
    }, 100);
  }


  hideOverlayPass() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenPass: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showOverlayPass: false});
    }, 500);
  }


  renderErrorPass(num) {
    var texts = [lang.t('modal:password.error1'), lang.t('modal:password.error2'), lang.t('modal:password.error3')]
    if(this.state.errorPass[num]) {
      return (
        <p className="error">{texts[num]}</p>
      )
    }
  }


  renderOverlayPass() {
    if(this.state.showOverlayPass) {
      return (
        <div className={'overlay '+this.state.hiddenPass}   >
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{lang.t('modal:password.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideOverlayPass()}>{lang.t('modal:common.close')}</button>
            <p className="clear mb-30">{lang.t('modal:password.text')}</p>
            <div className="form">
              <label>{lang.t('modal:password.label1')}*</label>
              <input type="password" value={this.state.valuesPass[2]} name="2" onChange={this.onChangePass}/>
              {this.renderErrorPass(2)}
              <div className="sv-20"></div>
              <label>{lang.t('modal:password.label2')}*</label>
              <input type="password" placeholder={lang.t('modal:password.placeholder2')} value={this.state.valuesPass[0]} name="0" onChange={this.onChangePass}/>
              {this.renderErrorPass(0)}
              <div className="sv-20"></div>
              <label>{lang.t('modal:password.label3')}*</label>
              <input type="password" placeholder={lang.t('modal:password.placeholder3')} value={this.state.valuesPass[1]} name="1" onChange={this.onChangePass}/>
              {this.renderErrorPass(1)}
              <div className="sv-30"></div>
              <button className="btn primary" onClick={() => this.checkPassword()}>{lang.t('modal:password.button')}</button>
            </div>
          </div>
        </div>
      )
    }
  }


  onChangePass = event => {
    var tmp = this.state.valuesPass
    tmp[parseInt(event.target.name)] = event.target.value
    this.setState({valuesPass: tmp});
  };


  checkPassword() {
    this.setState({errorPass: [false, false, false]})
    var tmp = [false, false, false]
    if(this.state.valuesPass[0] === '') { tmp[0] = true }
    if(this.state.valuesPass[1] === '') { tmp[1] = true }
    if(this.state.valuesPass[2] === '') { tmp[2] = true }
    this.setState({errorPass: tmp})
    if(!tmp[0] && !tmp[1] && !tmp[2]) {
      this.changePassword()
    }
  }


  changePassword() {
    this.hideOverlayPass()
    const credential = Firebase.auth.EmailAuthProvider.credential(Firebase.auth().currentUser.email, this.state.valuesPass[2])
    Firebase.auth().currentUser.reauthenticateWithCredential(credential).then(() => {
      Firebase.auth().currentUser.updatePassword(this.state.valuesPass[0]).then(() => {
        EventEmitter.dispatch('showMessage', lang.t('message:password'));
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    }).catch((error) => {
      EventEmitter.dispatch('showMessageError', error.message);
    });
  }



  // Update user details ------------------------------------------------------------



  toggleEditing(show) {
    this.setState({
      editing: show,
      values: [global.userName, global.userEmail, global.userPhone, global.userBday],
      file: null,
      fileDisplay: null,
      reminder: global.clientEmailReminder,
    });
  }


  onChange = event => {
    var tmp = this.state.values
    tmp[parseInt(event.target.name)] = event.target.value
    if(event.target.name === '3') {
      tmp[3] = Moment(event.target.value, 'YYYY-MM-DD').format('DD/MM/YYYY')
    }
    this.setState({values: tmp});
  };


  onChangeHeight = event => {
    this.setState({
      valueHeight: event.target.value
    });
  };


  onChangeEc = event => {
    var tmp = this.state.valuesEc
    tmp[parseInt(event.target.name)] = event.target.value
    this.setState({valuesEc: tmp});
  };


  setEcType(num) {
    var tmp = this.state.valuesEc
    tmp[1] = String(num)
    this.setState({valuesEc: tmp});
  }


  setImage(file) {
    this.setState({
      file: file,
      fileDisplay:  URL.createObjectURL(file),
      newimage: true,
    })
  }


  setAvatar(item) {
    this.setState({
      avatar: item,
      newimage: true,
    })
    this.hideModals()
  }


  displayImage() {
    if(this.state.file === null) {
      if(this.state.imageType === 'avatar') {
        return (
          <div className={'avatar '+this.state.avatar}></div>
        )
      } else if(this.state.imageType === '') {
        return <div className="avatar nophoto"></div>
      } else {
        if(global.userImage === '') {
          return <div className="avatar"><p>✎</p></div>
        } else {
          return (
            <div className="avatar" style={{backgroundImage: 'url('+global.userImage+')'}}></div>
          )
        }
      }
      
    } else {
      return (
        <div className="avatar" style={{backgroundImage: 'url('+this.state.fileDisplay+')'}}> </div>
      )
    }
  }


  hideModals() {
    this.setState({
      showModalAvatar: false,
    })
  }


  checkUpdate() {
    this.setState({error: [false, false, false, false]})
    var tmp = [false, false, false, false]
    if(this.state.values[0] === '') { tmp[0] = true }
    if(this.state.values[1] === '') { tmp[1] = true }
    if(this.state.values[2] === '') { tmp[2] = true }
    if(this.state.values[3] === '') { tmp[3] = true }
    this.setState({error: tmp})
    var passed = true
    for(var item of this.state.values) {
      if(item === '') {
        passed = false
      }
    }
    if(passed) {
      this.updateDetails()
    }
  }


  updateDetails() {
    var user = Firebase.auth().currentUser
    if(this.state.values[0] !== this.state.name) {
      user.updateProfile({
        displayName: this.state.values[0],
      })
    }
    var height = 0
    if(this.state.valueHeight !== '') {
      height = parseInt(this.state.valueHeight)
      if(global.userLbs) {
        var ar = this.state.valueHeight.split(".")
        var h1 = parseInt((ar[0])*12)*2.54
        var h2 = 0
        if(ar.length > 1) {
          h2 = parseInt(ar[1])*2.54
        }
        height = parseInt(h1+h2)
      }
    }

    var avatar = global.userAvatar
    if(this.state.imageType === 'avatar' && this.state.newimage) {
      avatar = this.state.avatar
    }

    var ectype = 99
    if(this.state.valuesEc[1] !== '' && this.state.valuesEc[1] !== undefined) {
      ectype = parseInt(this.state.valuesEc[1])
    }
    for(var item of global.userStaff) {
      Firebase.database().ref('/clients/'+item.id+'/'+item.client).update({
        email: this.state.values[1],
        phone: this.state.values[2],
        name: this.state.values[0],
        height: height,
        ecName: this.state.valuesEc[0],
        ecType: ectype,
        ecPhone: this.state.valuesEc[2],
        emailReminder: this.state.reminder,
        avatar: avatar,
      })
    }
    Firebase.database().ref('/usersClients/'+global.uidUser).update({
      email: this.state.values[1],
      phone: this.state.values[2],
      name: this.state.values[0],
      height: height,
      lbs: this.state.lbs,
      ecName: this.state.valuesEc[0],
      ecType: ectype,
      ecPhone: this.state.valuesEc[2],
      avatar: avatar,
    }).then(() => {
      EventEmitter.dispatch('showMessage', lang.t('message:userdetails'));
      this.setState({
        editing: false,
      });
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
    if(this.state.file !== null && this.state.imageType === 'image' && this.state.newimage) {
      const sr = Firebase.storage().ref()
      const fr = sr.child('images/users/'+global.uidUser+'.jpg')
      user.updateProfile({
        imageDate: Moment().format('DD/MM/YYYY HH:mm'),
        photoURL: 'images/users/'+global.uidUser+'.jpg'
      })
      Resizer.imageFileResizer(
        this.state.file,
        800,
        800,
        "JPEG",
        70,
        0,
        (uri) => {
          var ar = uri.split(',')
          fr.putString(ar[1], "base64", {contentType: 'image/jpg'}).then(() => {
            Firebase.database().ref('/users/'+global.uidUser).update({
              image: 'images/usersClients/'+global.uidUser+'.jpg',
              imageDate: Moment().format('DD/MM/YYYY HH:mm')
            })
            for(var item2 of global.userStaff) {
              Firebase.database().ref('/clients/'+global.uid+'/'+item2.client).update({
                image: 'images/usersClients/'+global.uidUser+'.jpg',
                imageDate: Moment().format('DD/MM/YYYY HH:mm')
              })
            }
          }).catch((error)=>{
            EventEmitter.dispatch('showMessageError', error.message);
          })
        },
        "base64",
        200,
        200
      );
    }
    if(this.state.imageType === '') {
      Firebase.database().ref('/usersClients/'+global.uidUser).update({
        avatar: '',
        image: ''
      })
      for(var item2 of global.userStaff) {
        Firebase.database().ref('/clients/'+item2.id+'/'+item2.client).update({
          avatar: '',
          image: ''
        })
      }
    }
  }


  signOut() {
    global.uid = "";
    global.uidUser = "";
    global.userName = '';
    global.userBusiness = '';
    global.userEmail = '';
    global.userBday = Moment();
    global.userPhone = '';
    global.userStripe = '';
    global.userMessage = 0;
    global.userCard = '';
    global.userSubPlanId = '';
    global.userSubPlan = 'spark';
    global.userSubId = '';
    global.userSubStatus = 'trialing';
    global.userSubBilling = '';
    global.userSubCancel = false;
    global.userSubEnd = 0;
    global.userSubItemId = '';
    global.userStripeConnect = '';
    global.userStripeBank = '';
    global.userStripeAddress = '';
    global.timerStart = 5;
    global.userImageDate = Moment();
    global.userTemplates = [];
    global.userImage = '';
    global.userEmailsSent = [];
    global.userVerified = false;
    global.userOnboarding = ['', ''];
    global.userBadges = [];
    global.userComments = true;
    global.userShowBooked = true;

    global.cards = [];
    global.coupon = [];
    global.userSignup = false;

    global.currentMonth = 1;
    global.currentDay = parseInt(Moment().format('D'));
    global.currentWeek = parseInt(Moment().format('w'));
    global.currentCalendarView = 'week';
    global.currentDate = Moment().format('DD/MM/YYYY');
    global.currentTime = '05';

    global.userStaff = [];
    global.spaces = [];
    global.clientPlans = [];

    global.publicData = [];
    global.publicRequests = [];
    global.showOnboarding = false;
    global.userConnectClient = null;
    global.clientToken = '';
    global.clientTokens = [];
    global.spaceClient = '';

    // Data arrays

    global.clients = [];
    global.clientGroups = [];
    global.clientsImages = [];
    global.clientsInactive = [];
    global.sessions = [];
    global.archive = [];
    global.events = []
    global.clientBest = [];
    global.clientBestGroup = [];
    global.chats = [];
    global.chatsGroup = [];
    global.activity = [];

    global.sessionsTraining = [];
    global.archiveTraining = [];
    global.recurring = [];

    global.programs = [];
    global.programsClient = [];
    global.plans = [];
    global.exercises = [];

    global.payments = [];
    global.invoices = [];
    global.products = [];

    global.userLog = [];
    global.syncDate = 0;
    global.maxClients = 0;
    global.maxPrograms = 0;
    global.maxPlans = 0;
    global.maxTrainers = 0;

    global.userDataLoaded = false;
    Firebase.auth().signOut()
    
  }



  // Display stuff ------------------------------------------------------------



  renderError(num) {
    var texts = [lang.t('account:form.error1'), lang.t('account:form.error2'), lang.t('account:form.error3'), lang.t('account:form.error4')]
    if(this.state.error[num]) {
      return (
        <p className="error">{texts[num]}</p>
      )
    }
  }


  renderEmergency(line) {
    var label = '-'
    if(this.state.emergency[line] !== '') {
      label = this.state.emergency[line]
      var types = [lang.t('auth:form.ec.spouse'), lang.t('auth:form.ec.friend'), lang.t('auth:form.ec.other')]
      if(line === 1) {
        label = ''
      }
      if(line === 1 && this.state.emergency[1] !== '99' && this.state.emergency[1] !== 99) {
        label = ' ('+types[this.state.emergency[line]]+')'
      }
    }
    return label
  }


  renderHeightStatic() {
    var label = (this.state.height === 0 ? '-' : this.state.height+' cm')
    if(global.userLbs && this.state.height !== 0) {
      var h = this.state.height/2.54
      var h1 = parseInt(h/12)
      var h2 = (h-(h1*12)).toFixed(0)
      label = h1+"'"+h2+' ft'
    }
    return label
  }


  renderReminder() {
    if(global.spaceEmailReminder) {
      if(this.state.editing) {
        return (
          <div>
            <label>{lang.t('account:form.confirmation.label')}</label>
            <div className={this.state.reminder ? 'toggle active' : 'toggle'} onClick={() => this.setState({reminder: !this.state.reminder})}>
              <div className="mover"></div>
            </div>
            <p className="toggle-label">{lang.t('account:form.confirmation.text')}</p>
            <div className="clear"></div>
            <div className="sv-20"></div>
          </div>
        )
      } else {
        return (
          <div className="info">
            <label>{lang.t('account:label.reminder')}</label>
            <p>{global.clientEmailReminder ? lang.t('common:label.yes') : lang.t('common:label.no')}</p>
          </div>
        )
      }
    }
  }


  renderImageSelect() {
    if(this.state.imageType === 'avatar') {
      return <button className="btn tertiary small mb-20" onClick={() => this.setState({showModalAvatar: true})}>{lang.t('auth:button.gallery')}</button>
    } else if(this.state.imageType === 'image') {
      return (
        <div className="form-item">
          <input type="file" placeholder="Select an image" onChange={(e: any) => {this.setImage(e.target.files[0]);}}/>
        </div>
      )
    }
  }


  renderDetails() {
    if(this.state.editing) {
      return (
        <div>
          <div className="avatar-container clear">
            {this.displayImage()}
          </div>
          <div className="sv-10"></div>
          <InputRadio label={lang.t('account:label.image')} value={this.state.imageType} values={[{name: lang.t('auth:form.image.gallery'), value: 'avatar'}, {name: lang.t('auth:form.image.photo'), value: 'image'}, {name: lang.t('auth:form.image.noimage'), value: ''}]} clickElement={(event) => this.setState({imageType: event})}/>
          {this.renderImageSelect()}
          <div className="sv-20"></div>
          <div className="info">
            <label>{lang.t('account:label.email')}</label>
            <p>{this.state.email}</p>
          </div>
          <div className="form">
            <label>{lang.t('auth:form.name.label')}*</label>
            <input type="text" placeholder={lang.t('auth:form.fullname.placeholder')} value={this.state.values[0]} name="0" onChange={this.onChange}/>
            {this.renderError(0)}
            <div className="sv-20"></div>
            <label>{lang.t('auth:form.phone.label')}*</label>
            <input type="number" placeholder={lang.t('auth:form.phone.placeholder')} value={this.state.values[2]} name="2" onChange={this.onChange}/>
            {this.renderError(2)}
            <div className="sv-20"></div>
            <label>{lang.t('auth:form.dob.label')}*</label>
            <input type="date" value={Moment(this.state.values[3], 'DD/MM/YYYY').format('YYYY-MM-DD')} name="3" onChange={this.onChange}/>
            {this.renderError(3)}
            <div className="sv-20"></div>
            <label>{lang.t('auth:form.height.label')} {global.userLbs ? 'ft' : 'cm'}</label>
            <input type="number" placeholder={lang.t('auth:form.height.placeholder')+' '+(global.userLbs ? 'ft' : 'cm')} value={this.state.valueHeight} onChange={this.onChangeHeight}/>
            <div className="sv-20"></div>
            <label>{lang.t('account:label.units')}</label>
            <div className="radios">
              <button className={!this.state.lbs ? 'radio col-6 active' : 'radio col-6'} style={{marginTop: 0}} onClick={() => this.setState({lbs: false})}>kg/cm</button>
              <button className={this.state.lbs ? 'radio col-6 active' : 'radio col-6'} style={{marginTop: 0}} onClick={() => this.setState({lbs: true})}>lb/ft</button>
            </div>
            <div className="sv-20"></div>
            {this.renderReminder()}
            <div className="clear sv-20"></div>
            <h4 className="mb-20">{lang.t('account:label.ec')}</h4>
            <label>{lang.t('auth:form.name.label')}</label>
            <input type="text" placeholder={lang.t('auth:form.name.placeholder')} value={this.state.valuesEc[0]} name="0" onChange={this.onChangeEc}/>
            <div className="sv-20"></div>
            <label>{lang.t('auth:form.phone.label')}</label>
            <input type="number" placeholder={lang.t('auth:form.phone.placeholder2')} value={this.state.valuesEc[2]} name="2" onChange={this.onChangeEc}/>
            <div className="sv-20"></div>
            <label>{lang.t('auth:form.ec.label')}</label>
            <div className="radios">
              <button style={{marginTop:0}} className={this.state.valuesEc[1] === '0' ? 'radio col-4 active' : 'radio col-4'} onClick={() => this.setEcType(0)}>{lang.t('auth:form.ec.spouse')}</button>
              <button style={{marginTop:0}} className={this.state.valuesEc[1] === '1' ? 'radio col-4 active' : 'radio col-4'} onClick={() => this.setEcType(1)}>{lang.t('auth:form.ec.friend')}</button>
              <button style={{marginTop:0}} className={this.state.valuesEc[1] === '2' ? 'radio col-4 active' : 'radio col-4'} onClick={() => this.setEcType(2)}>{lang.t('auth:form.ec.other')}</button>
            </div>
            <div className="clear sv-50"></div>
            <button className="btn primary mb-20" onClick={() => this.checkUpdate()}>{lang.t('account:button.update2')}</button>
            <button className="btn tertiary close width-12" onClick={() => this.toggleEditing(false)}>{lang.t('common:button.cancel')}</button>
          </div>
          
        </div>
      )
    } else {
      return (
        <div>
          <div className="avatar-container clear">
            {this.getImage()}
          </div>
          <div className="sv-20"></div>
          <div className="info">
            <label>{lang.t('auth:form.name.label')}</label>
            <p>{this.state.name}</p>
          </div>
          <div className="info">
            <label>{lang.t('auth:form.email.label')}</label>
            <p>{this.state.email}</p>
          </div>
          <div className="info">
            <label>{lang.t('auth:form.phone.label')}</label>
            <p>{this.state.phone}</p>
          </div>
          <div className="info">
            <label>{lang.t('auth:form.dob.label')}</label>
            <p>{Moment(this.state.birth, 'DD/MM/YYYY').locale(lang.language).format('D MMMM YYYY')}</p>
          </div>
          <div className="info">
            <label>{lang.t('account:label.height')}</label>
            <p>{this.renderHeightStatic()}</p>
          </div>
          <div className="info">
            <label>{lang.t('account:label.units')}</label>
            <p>{global.userLbs ? 'lb/ft' : 'kg/cm'}</p>
          </div>
          {this.renderReminder()}
          <div className="info">
            <label>{lang.t('account:label.ec')}</label>
            <p>{this.renderEmergency(0)} {this.renderEmergency(1)}<br/>{this.renderEmergency(2)}</p>
          </div>
          <div className="sv-40"></div>
          <button className="btn primary" onClick={() => this.toggleEditing(true)}>{lang.t('account:button.update')}</button>
          <div className="sv-30"></div>
          <button className="btn tertiary edit width-12" onClick={() => this.showOverlayPass()}>{lang.t('account:button.password')}</button>
        </div>
      )
    }
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content form-sidebar member">
          
          <div className="col-6 mt-20">
            <div className="box">
              {this.renderDetails()}
              <div className="clear sv-20"></div>
            </div>
          </div>

          <div className="col-6 sidebar mt-20">
            <h2 className="mb-30">{lang.t('header:title.account')}</h2>
            <div className="image empty large mb-30">
              <div className="illustration">
                <div className="img1"></div>
                <div className="img2"></div>
                <div className="img3"></div>
                <div className="img4"></div>
                {HelperEmptyImg.renderImage('user')}
              </div>
            </div>
            <p style={{marginBottom: 30}}>{lang.t('account:label.sidebar')}</p>
            <h3 className="mb-20">{lang.t('header:account.links')}</h3>
            <ul className="links">
              <li><a href="http://www.ptmate.net/terms-conditions" target="_blank" rel="noopener noreferrer">{lang.t('account:links.terms')}</a></li>
              <li><a href="http://www.ptmate.net/privacy-policy" target="_blank" rel="noopener noreferrer">{lang.t('account:links.privacy')}</a></li>
              <li><a href="https://intercom.help/pt-mate" target="_blank" rel="noopener noreferrer">{lang.t('nav:account.help')}</a></li>
              <li><a href="https://apps.apple.com/us/app/pt-mate-member/id1549621111" target="_blank" rel="noopener noreferrer">{lang.t('account:links.ios')}</a></li>
              <li><a href="https://play.google.com/store/apps/details?id=net.ptmate.ptmate_client" target="_blank" rel="noopener noreferrer">{lang.t('account:links.android')}</a></li>
            </ul>
          </div>
          <div className="clear sv-20"></div>
          <button className="btn tertiary small close width-12" onClick={() => HelperPage.signOut()}>{lang.t('nav:account.logout')}</button>
          <div className="clear sv-40"></div>
        </div>

        <Navigation active=''/>
        {this.renderOverlayPass()}
        <ModalAvatar show={this.state.showModalAvatar} onHide={() => this.hideModals()} clickElement={(event) => this.setAvatar(event)}/>
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['account','header','common','nav','auth','modal','message'])(withRouter(withAuthorizationMember(condition)(MemberAccountPage)));