import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

import common_en from "./locale/en/common.json";
import admin_en from "./locale/en/admin.json";
import nav_en from "./locale/en/nav.json";
import auth_en from "./locale/en/auth.json";
import calendar_en from "./locale/en/calendar.json";
import overview_en from "./locale/en/overview.json";
import empty_en from "./locale/en/empty.json";
import header_en from "./locale/en/header.json";
import health_en from "./locale/en/health.json";
import message_en from "./locale/en/message.json";
import training_en from "./locale/en/training.json";
import account_en from "./locale/en/account.json";
import modal_en from "./locale/en/modal.json";
import messaging_en from "./locale/en/messaging.json";

import common_es from "./locale/es/common.json";
import admin_es from "./locale/es/admin.json";
import nav_es from "./locale/es/nav.json";
import auth_es from "./locale/es/auth.json";
import calendar_es from "./locale/es/calendar.json";
import overview_es from "./locale/es/overview.json";
import empty_es from "./locale/es/empty.json";
import header_es from "./locale/es/header.json";
import health_es from "./locale/es/health.json";
import message_es from "./locale/es/message.json";
import training_es from "./locale/es/training.json";
import account_es from "./locale/es/account.json";
import modal_es from "./locale/es/modal.json";
import messaging_es from "./locale/es/messaging.json";


i18n
  .use(Backend)
  .use(LanguageDetector)    
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        account: account_en,
        auth: auth_en,
        admin: admin_en,
        calendar: calendar_en,
        common: common_en,
        empty: empty_en,
        header: header_en,
        health: health_en,
        message: message_en,
        messaging: messaging_en,
        modal: modal_en,
        nav: nav_en,
        overview: overview_en,
        training: training_en,
      },
      es: {
        account: account_es,
        auth: auth_es,
        admin: admin_es,
        calendar: calendar_es,
        common: common_es,
        empty: empty_es,
        header: header_es,
        health: health_es,
        message: message_es,
        messaging: messaging_es,
        modal: modal_es,
        nav: nav_es,
        overview: overview_es,
        training: training_es,
      },
    },

    //lng: "en",   //default language
    lng: window.navigator.language,
    //lng: "es-ES",
    fallbackLng: "en", //when specified language translations not present 

//then fallbacklang translations loaded.

    debug: true,
      backend: {
      /* translation file path */
      //loadPath: "/assets/{{ns}}/{{lng}}.json",
      //loadPath: "/locale//{{lng}}/{{ns}}.json",
    },
 

/* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */


    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },

    react: {
      //wait: true,
      useSuspense: false
    },

    

  });


export default i18n;