import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../../components/Firebase';
import * as ROUTES from '../../constants/routes';
import Firebase from 'firebase';
import Connector from '../../data/Connector';
import EventEmitter from '../../helper/Emitter';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class MemberLoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      type: 'login',
      reset: '',
      password: '',
      showOverlayTrainer: false,
      hiddenTrainer: 'hidden',
      margin: 0,
      num: global.num,
      done: false,
      logo: '',
      link: ROUTES.SIGNUP,
      triggered: false,
    };
  }


  componentDidMount() {
    document.title = 'Member Area - PT Mate'
    //Connector.checkUserUnauth()
    
    EventEmitter.subscribe('showPassword', (event) => {
      this.showOverlayInfo()
    })

    global.uid = ''
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-2]
    if(id !== 'ptmate.me' && id !== 'www.ptmate.me' && id !== '') {
      global.uid = id
      Connector.loadPublic()
      this.setState({
        link: '/'+id+'/signup'
      })
    }

    EventEmitter.subscribe('publicLoaded', (event) => {
      this.setAppearance()
    })
    this.setAppearance()
  }


  setType(type) {
    this.setState({type: type})
  }


  setAppearance() {
    var title = 'Member Area'
    setTimeout(() => {
      if(lang.language.indexOf('es') !== -1) {
        title = 'Área de Miembros'
        lang.language = 'es'
      }
      document.title = title+' - PT Mate'
      if(global.userBusiness !== '') {
        document.title = title+' - '+global.userBusiness
      }
    }, 300);
    
    setTimeout(() => {
      EventEmitter.dispatch('showMessage', global.message);
      global.message = ''
    }, 300);
    console.log(global.spaceImage)
    this.setState({
      logo: global.spaceImage
    })
  }



  // Display stuff ------------------------------------------------------------



  renderForm() {
    return (
      <div>
        <SignInFormHome />
      </div>
    )
  }


  getBg() {
    var label = 'hero bg1'
    if(!this.state.done && this.state.num === 99) {
      var num = parseInt(Math.random()*6+1)
      global.num = num
      this.setState({
        num: num
      })
    }
    label = 'hero bg'+this.state.num
    return label
  }


  renderLogo() {
    if(this.state.logo === '') {
      return (
        <img src="/img/logo.svg" alt="PT Mate Member Area"/>
      )
    } else {
      return (
        <div className="logo">
          <img src={this.state.logo} alt="Member Area"/>
        </div>
      )
    }
  }


  renderMainImage() {
    if(global.spaceBackground === '') {
      return (
        <div className={this.getBg()}>
          <div className={'gradient theme-'+global.spaceTheme}></div>
        </div>
      )
    } else {
      return (
        <div className='hero' style={{backgroundImage: 'url('+global.spaceBackground+')', backgroundSize: 'cover'}}></div>
      )
    }
  }


  render() {
    if(Firebase.auth().currentUser != null) {
      if(Firebase.auth().currentUser.displayName === 'client' || Firebase.auth().currentUser.displayName === 'Client') {
        if(Firebase.auth().currentUser.uid !== 'iJam7aX6gSYjd92D3NSbcKacCbv2') {
          Connector.setUser()
          if(global.uid === '') {
            this.props.history.push('/loading')
          } else {
            this.props.history.push('/'+global.uid+'/home')
          }
        } else {
          Firebase.auth().signOut()
        }
        
      } else if(Firebase.auth().currentUser.displayName !== 'client' && Firebase.auth().currentUser.displayName !== null) {
        global.portalChange = true
        Firebase.auth().signOut()
        window.location = 'https://ptmate.app'
      } else {
        if(Firebase.auth().currentUser.displayName === null) {
          if(global.uid === '') {
            this.props.history.push('/complete-signup')
          } else {
            this.props.history.push('/'+global.uid+'/complete-signup')
          }
        } else {
          return (
            <div className={"register theme-"+global.spaceTheme}>
              <div className="content">
                <div className="inner">
                  {this.renderLogo()}
                  <h1>{lang.t('auth:title.login')}</h1>
                  <form>
                    <SignInFormHome />
                  </form>
                </div>
              </div>
              {this.renderMainImage()}
              <Link to={this.state.link} className="link">{lang.t('auth:button.register')}</Link>
            </div>
          )
        }
      }
      return (
        <div/>
      )
    } else {
      return (
        <div className={"register theme-"+global.spaceTheme}>
          <div className="content">
            <div className="inner">
              {this.renderLogo()}
              <h1>{lang.t('auth:title.login')}</h1>
              <form>
                <SignInFormHome />
              </form>
            </div>
          </div>
          {this.renderMainImage()}
          <Link to={this.state.link} className="link">{lang.t('auth:button.register')}</Link>
        </div>
      )
    }
  }
}





class SignInFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error: null,
    };
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onSubmit = event => {
    const { email, password } = this.state;
    event.preventDefault();
    global.logging = true
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        EventEmitter.dispatch('loginLogged', '');
        this.setState({
          email: '',
          password: '',
          error: null,
        });
        if(Firebase.auth().currentUser.displayName !== 'client') {
          EventEmitter.dispatch('showTrainerLogout', '');
          //this.props.history.push(ROUTES.OVERVIEW);
        } else {
          Connector.setUser()
          if(global.productlink !== '') {
            this.props.history.push(global.productlink);
          } else {
            if(global.uid === '') {
              this.props.history.push(ROUTES.LOADING);
            } else {
              this.props.history.push('/'+global.uid+'/home');
            }
          }
          
          
        }
        
      })
      .catch(error => {
        console.log('error')
        console.log(error)
        this.setState({ error });
      });
  };
  

  render() {
    const { email, password, error } = this.state;
    return (
      <div>
        <form>
          <label>{lang.t('auth:form.email.label')}</label>
          <input type="text" placeholder={lang.t('auth:form.email.placeholder')} value={email} onChange={this.onChange} name="email" />
          <label>{lang.t('auth:form.password.label')}</label>
          <input type="password" placeholder={lang.t('auth:form.password.placeholder')} value={password} onChange={this.onChange} name="password" />
          <div className="sv-20"></div>
          <button className="btn primary" onClick={this.onSubmit} type="submit">{lang.t('auth:button.login')}</button>
          {error && <p className="error">{error.message}</p>}
          <Link to={ROUTES.PASSWORD} className="forgot width-12">{lang.t('auth:button.forgot')}</Link>
        </form>
      </div>
    )
  }
}


const SignInFormHome = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);


export default withTranslation(['auth'])(MemberLoginPage);
export { SignInFormHome };