import React, { Component } from 'react';
import Connector from '../../data/Connector';
import 'firebase/functions';
import Moment from 'moment';
import 'moment/locale/es';
import HelperCal from '../../helper/Calendar';
import HelperEmpty from '../../helper/Empty';
import HelperEmptyImg from '../../helper/EmptyLarge';
import Navigation from '../../components/Navigation';
import WidgetChartGraph from '../../components/Widgets/chart-graph';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import { AuthUserContext, withAuthorizationMember } from '../../components/Session';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class MemberTrainingProgressPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      done: false,
      num: global.num,
      sessions: global.sessions,
      archive: global.archive,
      sessionsTraining: global.sessionsTraining,
      archiveTraining: global.archiveTraining,
      best: [],
      showOverlay: false,
      hidden: 'hidden',
      ext: '',
      exd: '',
      exi: '',
      exv: '',
      cbest: null,
      margin: 0,
      // Graph
      gtitle: lang.t('header:training.weights')+' (kg)',
      data: [
        {
          "id": "japan",
          "color": "#1DC5C9",
          "data": []
        },
      ],
      current: '',
    };
  }


  componentDidMount() {
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-3]
    if(id !== 'training' && id !== 'member') {
      global.uid = id
    }
    Connector.setUser()
    global.clientBest = []
    Connector.loadClientBest(global.uidUser)
    document.title = lang.t('header:title.training')+' - '+global.userBusiness
    window.scrollTo(0, 0)

    this.setState({
      done: true
    })

    if(global.message !== '') {
      setTimeout(() => {
        EventEmitter.dispatch('showMessage', global.message);
        global.message = ''
      }, 300);
      
    }
    if(lang.language.indexOf('es') !== -1) {
      lang.language = 'es'
    }
    EventEmitter.subscribe('userLoaded', (event) => this.checkSpace())
    EventEmitter.subscribe('clientLoaded', (event) => this.configureData())
    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('trainingLoaded', (event) => this.configureData())
    EventEmitter.subscribe('bestLoaded', (event) => this.configureData())
    EventEmitter.subscribe('exercisesLoaded', (event) => this.configureData())
    this.configureData()
  }
  

  checkSpace() {
    if(global.uid === '') {
      this.props.history.push(ROUTES.LOADING)
    } else {
      var found = false
      for(var item of global.userStaff) {
        if(item.id === global.uid) {
          found = true
        }
      }
      if(found) {
        document.title = lang.t('header:title.training')+' - '+global.userBusiness
        this.configureData()
      } else {
        this.props.history.push(ROUTES.LOADING)
      }
    }
  }


  configureData() {
    var tmp = []
    var ids = []
    var sess = []
    for(var item of global.clientBest) {
      for(var ex of global.exercises) {
        if(ex.data.subIds.indexOf(item.id) !== -1 && ids.indexOf(item.id) === -1 && global.exToolsWeight.indexOf(item.data.tool) !== -1) {
          tmp.push(item)
          ids.push(item.id)
        }
      }
    }
    for(var item1 of global.sessions) {
      sess.push(item1)
    }
    for(var item2 of global.sessionsTraining) {
      sess.push(item2)
    }
    this.setState({
      best: tmp,
      sessions: sess
    }, () => {
      if(global.clientBest.length > 0) {
        this.setState({
          current: global.clientBest[0].id
        })
        this.createGraphWeights(global.clientBest[0].id)
      }
    })
  }


  getBg() {
    var label = 'image'
    if(!this.state.done && this.state.num === 99) {
      var num = parseInt(Math.random()*6+1)
      global.num = num
      this.setState({
        num: num
      })
    }
    label = 'image bg'+this.state.num
    return label
  }



  // Graph controls ------------------------------------------------------------



  updateGraph(event) {
    var gtitle = lang.t('header:training.weights')+' (kg)'
    for(var item of global.clientBest) {
      if(item.id === event.target.value && (item.data.tool === 6 || item.data.tool === 7)) {
        gtitle = lang.t('header:training.time')
      }
    }
    this.setState({
      current: event.target.value,
      gtitle: gtitle,
      data: [
        {
          "id": "japan",
          "color": "#1DC5C9",
          "data": []
        },
      ],
    })
    this.createGraphWeights(event.target.value)
  }


  createGraphWeights(id) {
    var tmp = []
    var list = this.state.sessions
    list.sort((a,b) => Moment(a.data.date, 'DD/MM/YYYY HH:mm') - Moment(b.data.date, 'DD/MM/YYYY HH:mm'));

    
    for(var item of list) {
      var numbr = 0
      if(item.program[0] !== undefined) {
        if(item.program[0].id !== '') {
          
          for(var block of item.program[0].blocks) {
            for(var i=0; i<block.data.exId.length; i++) {
              if(block.data.exId[i] === id) {
                numbr++
                var label = ''
                if(!item.data.group) {
                  var val = block.data.exResWeight[i]
                  if(val === undefined) {
                    val = 0
                  }
                  
                  if(numbr > 1) {
                    label = ' ('+numbr+')'
                  }
                  if(val !== 0) {
                    tmp.push({
                      "x": Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('DD/MM')+label,
                      "y": val,
                    })
                  }
                } else {
                  var num = 0
                  if(item.data.clients !== undefined && item.data.clients.length > 0) {
                    for(var j=0; j<item.data.clients.length; j++) {
                      if(item.data.clients[j] === this.state.id) {
                        num = j
                      }
                    }
                  }
                  var wgts = []
                  if(block.data.exResWeightGroup[i] !== undefined && block.data.exResWeightGroup[i] !== '') {
                    wgts = String(block.data.exResWeightGroup[i]).split('-')
                  }
                  var val2 = 0
                  if(wgts[num+1] !== '' && wgts[num+1] !== undefined) {
                    val2 = parseFloat(wgts[num+1])
                  }
                  if(numbr > 1) {
                    label = ' ('+numbr+')'
                  }
                  if(val !== 0) {
                    tmp.push({
                      "x": Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('DD/MM')+label,
                      "y": val2,
                    })
                  }
                  
                }
              }
            }
          }
        }
      }
    }
    var tmp2 = [{
      "id": "",
      //"id": id,
      "color": "#1DC5C9",
      data: tmp
    }]

    setTimeout(() => {
      this.setState({data: tmp2});
      EventEmitter.dispatch('graphLoaded', 'loaded');
    }, 100);

    global.dataWeight = tmp

  }



  // Exercise overlay ------------------------------------------------------------



  showOverlayEx(ex) {
    var ext = ''
    var exd = ''
    var exi = ''
    var exv = ''
    var id = ex.id
    if(ex.data.tool === 6 || ex.data.tool === 7) {
      var ar = ex.id.split('|')
      if(ar.length > 1) {
        id = ar[0]
      }
    }
    for(var item of global.exercises) {
      for(var i=0; i<item.data.subTitles.length; i++) {
        if(item.data.subIds[i] === id) {
          ext = item.data.subTitles[i]
          exd = item.data.subDescs[i]
          exi = item.data.subImages[i]
          exv = item.data.subVideos[i]
        }
      }
      
    }

    this.setState({
      showOverlay: true,
      ext: ext,
      exd: exd,
      exi: exi,
      exv: exv,
      cbest: ex,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hidden: '',
        margin: -height/2
      });
    }, 100);
  }


  hideOverlayEx() {
    const height = this.divElement.clientHeight
    this.setState({
      hidden: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showOverlay: false});
    }, 500);
  }


  renderExVideo() {
    /*if(this.state.exv !== '') {
      return (
        <a href="sadf" target="_blank" className="btn tertiary width-12">Watch video</a>
      )
    }*/
  }


  renderExType(type) {
    var label = "kg"
    if(type !== undefined) {
      label = type
    }
    if(type === 'per') {
      label = (global.spaceLbs ? 'lb' : 'kg')
    }
    return label
  }


  renderExHeaviest() {
    if(this.state.cbest.data.actual !== undefined && this.state.cbest.data.percent !== undefined) {
      if( this.state.cbest.data.percent === 0) {
        return (
          <p className="mb-20"><strong>{lang.t('modal:weight.text2')}:</strong><br/>{this.state.cbest.data.actual.toFixed(1)} {this.renderExType(this.state.cbest.data.type)}<br/>{Moment(this.state.cbest.data.date, 'DD/MM/YYYY HH:mm').format('ddd, D MMM YYYY')}</p>
        )
      } else {
        return (
          <p className="mb-20"><strong>{lang.t('modal:weight.text2')}:</strong><br/>{this.state.cbest.data.actual.toFixed(1)} {this.renderExType(this.state.cbest.data.type)} {lang.t('training:prog.at')} {this.state.cbest.data.percent}%<br/>{Moment(this.state.cbest.data.date, 'DD/MM/YYYY HH:mm').format('ddd, D MMM YYYY')}<br/>{lang.t('modal:weight.text3')}: {this.state.cbest.data.value.toFixed(1)} {this.renderExType(this.state.cbest.data.type)}</p>
        )
      }
    } else {
      return (
        <p className="mb-20"><strong>{lang.t('modal:weight.text2')}:</strong><br/>{this.state.cbest.data.value.toFixed(1)} {this.renderExType(this.state.cbest.data.type)}<br/>{Moment(this.state.cbest.data.date, 'DD/MM/YYYY HH:mm').format('ddd, D MMM YYYY')}</p>
      )
    }
  }


  renderOverlay() {
    if(this.state.showOverlay) {
      return (
        <div className={'overlay '+this.state.hidden}   >
            <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">Movement info</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideOverlayEx()}>{lang.t('modal:common.close')}</button>
            <h4 className="mb-20 clear">{this.state.ext}</h4>
            {this.renderExVideo()}
            {this.renderExHeaviest()}
            <p><strong>{lang.t('modal:weight.text1')}:</strong><br/>{this.getLast('value')}<br/>{this.getLast('date')}</p>
          </div>
        </div>
      )
    }
  }


  getLast(type) {
    var label1 = '-'
    var label2 = '-'
    var date = Moment('01/01/2000', 'DD/MM/YYYY').format('X')
    for(var item of this.state.sessions) {
      var sdate = Moment(item.data.date, 'DD/MM/YYYY').format('X')
      var cls = []
      if(item.data.clients !== undefined) { cls = item.data.clients }
      if(item.program.length > 0 && (item.data.client === global.spaceClient || cls.indexOf(global.spaceClient) !== -1)) {
        for(var bl of item.program[0].blocks) {
          if(bl.data.exId.length > 0) {
            for(var i=0; i<bl.data.exId.length; i++) {
              if(bl.data.exId[i] === this.state.cbest.id && parseInt(sdate) >= parseInt(date) && !item.data.group) {
                if(parseInt(sdate) !== parseInt(date) || (parseInt(sdate) === parseInt(date) && bl.data.exResWeight[i] > 0)) {
                  label1 = bl.data.exResWeight[i]+' kg'
                  if(bl.data.exResReps[i] !== 0) {
                    label1 += ' ('+bl.data.exResReps[i]+' '+lang.t('training:prog.reps')+')'
                  }
                  label2 = Moment(item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('ddd, D MMM YYYY')
                  date = sdate
                }
              }
              if(bl.data.exId[i] === this.state.cbest.id && parseInt(sdate) >= parseInt(date) && item.data.group) {
                var val = bl.data.exResWeightGroup[i].split('-')
                val.splice(0,1)
                var val2 = bl.data.exResRepsGroup[i].split('-')
                val2.splice(0,1)
                for(var j=0; j<cls.length; j++) {
                  if(cls[j] === global.spaceClient && val.length > j) {
                    if(parseInt(sdate) !== parseInt(date) || (parseInt(sdate) === parseInt(date) && val[j] > 0)) {
                      label1 = val[j]+' kg'
                      if(val2[j] !== 0 && val2[j] !== undefined) {
                        label1 += ' ('+val2[j]+' '+lang.t('training:prog.reps')+')'
                      }
                      label2 = Moment(item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('ddd, D MMM YYYY')
                      date = sdate
                    }
                  }
                }
                
              }
            }
          }
        }
      }
    }
    for(var item2 of this.state.sessionsTraining) {
      var sdate2 = Moment(item2.data.date, 'DD/MM/YYYY')
      if(item2.program.length > 0 && item2.data.client === global.spaceClient) {
        for(var bl2 of item2.program[0].blocks) {
          if(bl2.data.exId.length > 0) {
            for(var i2=0; i2<bl2.data.exId.length; i2++) {
              if(bl2.data.exId[i2] === this.state.cbest.id && parseInt(sdate) >= parseInt(date)) {
                if(parseInt(sdate) !== parseInt(date) || (parseInt(sdate) === parseInt(date) && bl2.data.exResWeight[i] > 0)) {
                  label1 = bl2.data.exResWeight[i2]+' kg'
                  if(bl2.data.exResReps[i2] !== 0 && bl2.data.exResReps[i2] !== undefined) {
                    label1 += ' ('+bl2.data.exResReps[i2]+' reps)'
                  }
                  label2 = Moment(item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('ddd, D MMM YYYY')
                  date = sdate2
                }
              }
            }
          }
        }
      }
    }
    if(type === 'value') {
      return label1
    } else {
      return label2
    }
    
  }



  // Display stuff ------------------------------------------------------------



  renderGraph() {
    if(this.state.data[0].id === 'japan') {
      return (
        <p className="empty">{lang.t('auth:label.loading2')}</p>
      )
    } else if(this.state.data[0].data.length === 0 && this.state.data[0].id !== 'japan') {
      return (
        <div className="empty small pt-30">
          {HelperEmpty.renderImage('graph')}
          <h4>{lang.t('empty:weights.title')}</h4>
          <p style={{whiteSpace: 'pre-wrap'}}>{lang.t('empty:weights.title')}</p>
        </div>
      )
    } else if(this.state.data[0].data.length > 0 && this.state.data[0].id !== 'japan') {
      return <WidgetChartGraph data={this.state.data} curve='linear'/>
    }
  }


  configureWeightId(item) {
    var label = item.id
    if(item.data.tool === 6 || item.data.tool === 7) {
      var ar = item.id.split('|')
      if(ar.length > 1) {
        label = ar[0]
      }
    }
    return label
  }


  renderWeights() {
    if(this.state.best.length === 0) {
      return (
        <div className="col-12 mt-20">
          <h3 className="lft mt-5">{lang.t('header:training.weights')}</h3>
          <div className="chart clear">
            <div className="empty small pt-30">
              {HelperEmpty.renderImage('graph')}
              <h4>{lang.t('empty:weights.title')}</h4>
              <p style={{whiteSpace: 'pre-wrap'}}>{lang.t('empty:weights.title')}</p>
            </div>
          </div>
        </div>
      )
    } else {
      var list = this.state.best
      list.sort((a, b) => a.data.name.localeCompare(b.data.name))
      return (
        <div className="col-12 mt-20">
          <h3 className="lft mt-5">{this.state.gtitle}</h3>
          <select className="light rgt" style={{width: 250}} value={this.state.current} onChange={event => this.updateGraph(event)}>
            {list.map(item => (
              <option key={item.id} value={this.configureWeightId(item)}>{this.configureBestName(item)}</option>
            ))}
          </select>
          <div className="chart clear">
            {this.renderGraph()}
          </div>
        </div>
      )
    }
  }


  configureBestName(item) {
    var label = item.data.name
    if(item.data.tool === 6 || item.data.tool === 7) {
      var ar = item.id.split('|')
      if(ar.length > 1) {
        label = item.data.name+' '+ar[1]+' m'
      }
    }
    return label
  }


  configureBestValue(item) {
    var label = item.data.value.toFixed(1)+' kg'
    if(item.data.actual !== undefined && item.data.percent !== undefined) {
      label = item.data.actual.toFixed(1)+' kg'
      if(item.data.percent > 0) {
        label = item.data.actual.toFixed(1)+' kg at '+item.data.percent+'%'
      }
    }
    if(item.data.tool === 6 || item.data.tool === 7) {
      label = HelperCal.getDurationMin(parseInt(item.data.value))
    }
    return label
  }


  renderBest() {
    if(this.state.best.length === 0) {
      return (
        <div className="empty large pt-60 clear">
          <div className="illustration mb-30">
            <div className="img1"></div>
            <div className="img2"></div>
            <div className="img3"></div>
            <div className="img4"></div>
            {HelperEmptyImg.renderImage('programs')}
          </div>
          <div className="data">
            <h3 className="mb-20">{lang.t('empty:weight.title')}</h3>
            <p style={{whiteSpace: 'pre-wrap'}}>{lang.t('empty:weight.text')}</p>
          </div>
        </div>
      )
    } else {
      var list = this.state.best
      list.sort((a, b) => a.data.name.localeCompare(b.data.name))
      return (
        <div className="col-12 clear">
          {list.map(item => (
            <div className="box list simple highlight mb-10" key={item.id} onClick={() => this.showOverlayEx(item)}>
              <div className="col-7">
                <p><strong>{this.configureBestName(item)}</strong></p>
              </div>
              <div className="col-3"><p>{this.configureBestValue(item)}</p></div>
              <div className="col-2"><button className="btn tertiary small rgt">{lang.t('common:button.viewdetails')}</button></div>
            </div>
          ))}
        </div>
      )
    }
  }


  renderContent() {
    return (
      <div>
        {this.renderWeights()}
        <div className="clear"></div>
        <div className="listheader">
          <h3>{lang.t('header:training.heaviest')}</h3>
          <div className="clear"></div>
        </div>
        {this.renderBest()}
        <div className="clear sv-40"></div>
      </div>
    )
  }


  renderMainImage() {
    if(global.spaceBackground === '') {
      return (
        <div className={this.getBg()}>
          <div className={'gradient theme-'+global.spaceTheme}></div>
        </div>
      )
    } else {
      return (
        <div className='image bg' style={{backgroundImage: 'url('+global.spaceBackground+')', backgroundSize: 'cover'}}></div>
      )
    }
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content member">
          <div className="member-header mb-10">
            {this.renderMainImage()}
            <div className="member-main">
              <div className="business">
                <div className="avatar" style={{backgroundImage: 'url('+global.spaceImage+')'}}> </div>
                <h3>{global.userBusiness}<br/><span>{lang.t('header:title.memberarea')}</span></h3>
              </div>
              <h2 className="mb-30">{lang.t('header:title.training')}</h2>
              <Link to={'/'+global.uid+'/training/sessions'} className="tab">
                <p>{lang.t('nav:training.sessions')}</p>
                <div className="bg"></div>
              </Link>
              <Link to={'/'+global.uid+'/training/programs'} className="tab">
                <p>{lang.t('nav:training.programs')}</p>
                <div className="bg"></div>
              </Link>
              <Link to={'/'+global.uid+'/training/plans'} className="tab">
                <p>{lang.t('nav:training.plans')}</p>
                <div className="bg"></div>
              </Link>
              <Link to={'/'+global.uid+'/training/progress'} className="tab active">
                <p>{lang.t('nav:training.progress')}</p>
                <div className="bg"></div>
              </Link>
            </div>
            <div className="clear"></div>
          </div>
          {this.renderContent()}
        </div>
        <Navigation active='training' />
        {this.renderOverlay()}
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['nav','header','common','empty','auth','modal','training'])(withRouter(withAuthorizationMember(condition)(MemberTrainingProgressPage)));