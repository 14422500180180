import React, { Component } from 'react';
import Connector from '../../data/Connector';
import Firebase from 'firebase';
import Moment from 'moment';
import { withRouter } from 'react-router-dom';
import HelperEmptyImg from '../../helper/EmptyLarge';
import EventEmitter from '../../helper/Emitter';
import { AuthUserContext, withAuthorizationMember } from '../../components/Session';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class MemberConfirmPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spaces: global.userStaff,
      selection: false,
      search: '',
      allspaces: [],
      list: [],
      showOverlay: false,
      hidden: 'hidden',
      margin: 0,
      selected: null,
      img: null,
      error: false
    };
  }


  componentDidMount() {
    Connector.setUser()
    document.title = lang.t('header:title.welcomepage')+' - PT Mate'
    window.scrollTo(0, 0)
    global.programLocked = true

    Moment.updateLocale("en", { week: {
      dow: 1, // First day of week is Monday
    }});

    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-2]
    /*if(id !== 'confirm' && id !== 'member' && id.indexOf('localhost') === -1 && id.indexOf('ptmate.me') === -1 && global.uid === '') {
      global.uid = id
    }*/
    if(id !== 'confirm' && global.uid === '' && id !== 'ptmate.me' && id !== 'www.ptmate.me' && id !== '') {
      global.uid = id
    }

    this.setState({
      done: true
    })

    if(Firebase.auth().currentUser.uid !== 'iJam7aX6gSYjd92D3NSbcKacCbv2') {
      if(global.spaceClient === '') {
        Connector.loadAllSpaces((result) => {})
      } else {
        Connector.getSpace()
        Connector.loadClientGroups((result) => {})
        Connector.loadPayments((result) => {})
        Connector.loadInvoices((result) => {})
        Connector.loadLog((result) => {})
        Connector.loadChat((result) => {})
        Connector.loadChatsGroup((result) => {})
        Connector.loadClients((result) => {})
        Connector.loadProducts((result) => {})
        Connector.loadPrograms((result) => {})
        Connector.loadCommunity((result) => {})
        Connector.loadRecurring((result) => {})
        Connector.loadHabits((result) => {})
        Connector.loadDocuments((result) => {})
        Connector.loadTraining((result) => {})
        Connector.loadExercises((result) => {})
        Connector.loadLocations((result) => {})
        Connector.loadSchedule((result) => {})
      }

      EventEmitter.subscribe('userLoaded', (event) => this.configureData())
      EventEmitter.subscribe('allSpacesLoaded', (event) => this.setTrainers())
      EventEmitter.subscribe('spaceInfoLoaded', (event) => this.setTrainers())
      this.configureData()
    } else {
      Connector.loadForms((result) => {})
    }
  }
  

  configureData() {
    if(Firebase.auth().currentUser.uid !== 'iJam7aX6gSYjd92D3NSbcKacCbv2') {
      var found = false
      for(var item of global.userStaff) {
        if(item.id === global.uid && global.spaceClient !== '') {
          found = true
        }
      }
      if(global.userStaff.length > 0 && global.uid === '' && !found && this.state.selected === null) {
        if(global.userStaff[0].client !== undefined && global.userStaff[0].client !== '' && global.spaceClient === '') {
          global.uid = global.userStaff[0].id
          global.spaceClient = global.userStaff[0].client
        }
        this.props.history.push('/'+global.userStaff[0].id+'/home')
      }
    }
  }


  onChange = event => {
    this.setState({
      search: event.target.value
    });
  };


  filterList() {
    var tmp = []
    if(this.state.search !== '') {
      var val = this.state.search.toLowerCase()
      for(var item of this.state.allspaces) {
        if(item.data.name !== undefined && item.data.owner !== undefined && item.data.name !== '' && item.data.owner !== '') {
          var l1 = String(item.data.name).toLowerCase()
          var l2 = String(item.data.owner).toLowerCase()
          var num = parseInt(Math.random()*6+1)
          item.num = num
          if(l1.indexOf(val) !== -1 || l2.indexOf(val) !== -1) {
            tmp.push(item)
          }
          if(item.data.image !== '' && item.img === '') {
            this.getImageSpace(item)
          }
        }
        
      }
    }
    this.setState({
      list: tmp
    })
  }


  getImageSpace(item) {
    Firebase.storage().ref().child(item.data.image).getDownloadURL().then((url) => {
      var tmp = this.state.list
      for(var sp of tmp) {
        if(sp.id === item.id) {
          sp.img = url
        }
      }
      this.setState({
        list: tmp
      })
    }).catch((error) => {
      // Handle any errors
    })
  }


  setTrainers() {
    this.setState({
      spaces: global.userStaff,
      allspaces: global.spaces
    })
  }


  clickContinue() {
    global.userVerified = true
    if(global.uid === '') {
      if(Firebase.auth().currentUser.uid !== 'iJam7aX6gSYjd92D3NSbcKacCbv2') {
        // logout failsafe
        Firebase.auth().signOut()
      }
    } else {
      if(Firebase.auth().currentUser.uid === 'iJam7aX6gSYjd92D3NSbcKacCbv2') {
        if(global.forms.length === 0) {
          this.props.history.push('/'+global.uid+'/thankyou')
        } else {
          this.props.history.push('/'+global.uid+'/pre-exercise')
        }
      } else {
        Firebase.database().ref('activity/'+global.uid).push({
          type: 'clientconnected',
          data: global.uidUser+' '+global.spaceClient,
          date: Moment().format('DD/MM/YYYY HH:mm')
        })
        Connector.loadSessions((result) => {})
        Connector.loadEvents((result) => {})
        Connector.loadClientGroups((result) => {})
        Connector.loadPayments((result) => {})
        Connector.loadLocations((result) => {})
        Connector.loadSchedule((result) => {})
        Connector.loadInvoices((result) => {})
        Connector.loadLog((result) => {})
        Connector.loadChat((result) => {})
        Connector.loadClient((result) => {
          if(global.userParent !== '' && global.userParent !== undefined) {
            Connector.loadFamilyBilling(global.userParent)
          }
        })
        Connector.loadChatsGroup((result) => {})
        Connector.loadPrograms((result) => {})
        Connector.loadCommunity((result) => {})
        Connector.loadRecurring((result) => {})
        Connector.loadHabits((result) => {})
        Connector.loadDocuments((result) => {})
        Connector.loadTraining((result) => {})
        Connector.loadExercises((result) => {})
        Connector.loadClients((result) => {
          Connector.loadForms((result) => {
            if(global.spaceWelcome !== '') {
              this.sendWelcome()
            }
            if(global.forms.length === 0) {
              this.props.history.push('/'+global.uid+'/home')
            } else {
              this.props.history.push('/'+global.uid+'/pre-exercise')
            }
          })
        })
      }
      
      
    }
  }


  selectSpace(item) {
    global.userVerified = true
    global.uid = item.id
    global.userBusiness = item.name
    global.spaceOwner = item.owner
    global.spaceImage = item.image
    global.spaceClient = item.client
    global.userCommunity = item.community
    global.userCommunityPost = item.communityPost
    global.userLimitBooking = item.limitBooking
    global.userAllowBooking = item.allowBooking
    global.spaceAllowRecurring = item.allowRecurring
    global.num = item.num
    global.userStripeConnect = item.stripe
    global.clientToken = item.token
    Connector.loadSessions((result) => {})
    Connector.loadEvents((result) => {})
    Connector.loadForms((result) => {
      if(global.forms.length === 0) {
        this.props.history.push('/'+global.uid+'/home')
      } else {
        this.props.history.push('/'+global.uid+'/pre-exercise')
      }
    })
    Connector.loadClientGroups((result) => {})
    Connector.loadPayments((result) => {})
    Connector.loadLocations((result) => {})
    Connector.loadSchedule((result) => {})
    Connector.loadInvoices((result) => {})
    Connector.loadLog((result) => {})
    Connector.loadChat((result) => {})
    Connector.loadChatsGroup((result) => {})
    Connector.loadClients((result) => {})
    Connector.loadClient((result) => {
      if(global.userParent !== '' && global.userParent !== undefined) {
        Connector.loadFamilyBilling(global.userParent)
      }
    })
    Connector.loadPrograms((result) => {})
    Connector.loadCommunity((result) => {})
    Connector.loadRecurring((result) => {})
    Connector.loadHabits((result) => {})
    Connector.loadDocuments((result) => {})
    Connector.loadTraining((result) => {})
    Connector.loadExercises((result) => {})
    if(item.theme !== undefined) {
      global.spaceTheme = item.theme
    } else {
      global.spaceTheme = 'blue'
    }
    if(item.image !== '') {
      Firebase.storage().ref().child(item.image).getDownloadURL().then((url) => {
        global.spaceImage = url
        EventEmitter.dispatch('userLoaded', 'loaded');
      }).catch((error) => {
        // Handle any errors
      })
    }
  }



  // Overlay stuff ------------------------------------------------------------



  showOverlay(item) {
    this.setState({
      showOverlay: true,
      selected: item,
      img: null,
      value: ''
    })
    if(global.connect.length === 0) {
      Connector.loadConnect((result) => {})
    }
    setTimeout(() => {
      const height = this.divElement.clientHeight
      if(this.state.selected.data.image !== '') {
        this.getImage()
      }
      this.setState({
        hidden: '',
        margin: -height/2,
      });
    }, 100);
  }


  hideOverlay() {
    const height = this.divElement.clientHeight
    this.setState({
      hidden: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showOverlay: false});
    }, 500);
  }


  getImage() {
    if(this.state.selected.data.image !== '') {
      Firebase.storage().ref().child(this.state.selected.data.image).getDownloadURL().then((url) => {
        this.setState({
          img: url
        })
      }).catch((error) => {
        // Handle any errors
      })
    }
  }


  renderImage() {
    if(this.state.img !== null) {
      return (
        <div className="avatar" style={{backgroundImage: 'url('+this.state.img+')'}}></div>
      )
    } else {
      var inits = ''
      let arr = this.state.selected.data.name.split(' ')
      if(arr.length > 1) {
        inits = arr[0].charAt(0)+arr[1].charAt(0)
      } else {
        inits = arr[0].charAt(0)
      }
      return (
        <div className="avatar">
          <p>{inits}</p>
        </div>
      )
    }
  }


  renderError() {
    if(this.state.error) {
      return (
        <p className="error">{lang.t('modal:connect.error')}</p>
      )
    }
  }


  renderOverlay() {
    if(this.state.showOverlay) {
      return (
        <div className={'overlay '+this.state.hidden}>
            <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="lft mb-20">{lang.t('modal:connect.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideOverlay()}>{lang.t('modal:common.close')}</button>
            <div className="clear"></div>
            <div className="client static">
              {this.renderImage()}
              <div className="text">
                <h4>{this.state.selected.data.name}</h4>
                <p>{this.state.selected.data.owner}<br/>{this.state.selected.data.email}</p>
              </div>
            </div>
            <div className="clear sv-20"></div>
            <p className="clear mb-20">{lang.t('modal:connect.text')}</p>
            <div className="form">
            <label>{lang.t('modal:connect.label')}</label>
              <input type="number" placeholder={lang.t('modal:connect.label')} value={this.state.value} onChange={this.onChangePIN}/>
              {this.renderError()}
            </div>
            <div className="sv-40"></div>
            <button className="btn primary" onClick={() => this.checkConnect()}>{lang.t('modal:connect.button')}</button>
          </div>
        </div>
      )
    }
  }


  onChangePIN = event => {
    this.setState({
      value: event.target.value
    });
  };


  checkConnect() {
    if(this.state.value === this.state.selected.data.pin) {
      this.connectSpace()
    } else {
      this.setState({
        error: true
      })
    }
  }


  connectSpace() {
    this.hideOverlay()
    var client = ''
    var cid = ''
    var activity = 'newclient'
    global.uid = this.state.selected.id
    for(var item of global.connect) {
      if(item.data.email === Firebase.auth().currentUser.email && item.data.space === global.uid) {
        client = item.data.client
        cid = item.id
      }
    }
    if(global.uid !== '') {
      var obj =  Firebase.database().ref('/clients/'+global.uid).push()
      if(client === '') {
        client = obj.key
      }
      global.userStaff = [{
        id: this.state.selected.id,
        client: client,
        name:  this.state.selected.data.name,
        owner: this.state.selected.data.owner,
        image: this.state.selected.data.image,
        banner: '',
        theme: this.state.selected.data.theme,
        num: parseInt(Math.random()*4+1),
        stripe: this.state.selected.data.stripe,
        token: ''
      }]
      Connector.getSpace()
      var image = global.userImage
      var height = 0
      if(global.coupon !== '' && global.coupon !== undefined) {
        height = global.coupon
      }
      if(height === 'NaN') {
        height = 0
      }
      global.spaceClient = client
      Firebase.database().ref('/usersClients/'+Firebase.auth().currentUser.uid+'/trainers/'+global.uid).update({
        client: client,
        trainer: global.uid
      })
      global.userVerified = true
      if(client !== '') {
        Firebase.database().ref('activity/'+global.uid).push({
          type: activity,
          data: global.uidUser+' '+client,
          date: Moment().format('DD/MM/YYYY HH:mm')
        })
        Firebase.database().ref('/clients/'+global.uid+'/'+client).update({
          uid: Firebase.auth().currentUser.uid,
          ecName: global.emergency[0],
          ecType: parseInt(global.emergency[1]),
          ecPhone: global.emergency[2],
          name: global.userName,
          phone: global.userPhone,
          birth: Moment(global.userBday, 'DD/MM/YYYY').format('DD/MM/YYYY'),
          height: height,
          image: image,
          avatar: global.userAvatar,
          country: 'au',
          email: global.userEmail,
          imageDate: Moment().format('DD/MM/YYYY HH:mm')
        }).then(() => {
          global.activityLocked = true
          Connector.loadSessions((result) => {})
          Connector.loadEvents((result) => {})
          Connector.loadForms((result) => {
            if(global.spaceWelcome !== '') {
              this.sendWelcome()
            }
            if(global.forms.length === 0) {
              this.props.history.push('/'+global.uid+'/home')
            } else {
              this.props.history.push('/'+global.uid+'/pre-exercise')
            }
          })
          Connector.loadClientGroups((result) => {})
          Connector.loadPayments((result) => {})
          Connector.loadLocations((result) => {})
          Connector.loadSchedule((result) => {})
          Connector.loadInvoices((result) => {})
          Connector.loadLog((result) => {})
          Connector.loadChat((result) => {})
          Connector.loadChatsGroup((result) => {})
          Connector.loadClients((result) => {})
          Connector.loadClient((result) => {
            if(global.userParent !== '' && global.userParent !== undefined) {
              Connector.loadFamilyBilling(global.userParent)
            }
          })
          Connector.loadCommunity((result) => {})
          Connector.loadRecurring((result) => {})
          Connector.loadHabits((result) => {})
          Connector.loadDocuments((result) => {})
        }).catch((error)=>{
          EventEmitter.dispatch('showMessageError', error.message);
        })
        if(cid !== '') {
          Firebase.database().ref('/connect/'+cid).remove()
        }
      } else {
        Firebase.auth().signOut()
      }
    }
  }


  logoutUser() {
    global.uid = "";
    global.uidUser = "";
    global.userName = '';
    global.userBusiness = '';
    global.userEmail = '';
    global.userPhone = '';
    global.userStripe = '';
    global.userMessage = 0;
    global.userCard = '';
    global.userSubPlanId = '';
    global.userSubPlan = 'spark';
    global.userSubId = '';
    global.userSubStatus = 'trialing';
    global.userSubBilling = '';
    global.userSubCancel = false;
    global.userSubEnd = 0;
    global.userSubItemId = '';
    global.userStripeConnect = '';
    global.userStripeBank = '';
    global.userStripeAddress = '';
    global.timerStart = 5;
    global.userTemplates = [];
    global.userImage = '';
    global.userEmailsSent = [];
    global.userVerified = false;
    global.userOnboarding = ['', ''];
    global.userBadges = [];
    global.userComments = true;
    global.userShowBooked = true;
    global.userSubdomain = '';
    global.userLimitBooking = false;

    global.cards = [];
    global.coupon = [];
    global.userSignup = false;

    global.userRole = '';
    global.userStaff = [];
    global.spaces = [];
    global.clientPlans = [];

    global.publicData = [];
    global.publicRequests = [];
    global.showOnboarding = false;
    global.userConnectClient = null;
    global.clientToken = '';
    global.clientTokens = [];
    global.spaceClient = '';
    global.spaceTheme = 'blue';

    // Data arrays

    global.clients = [];
    global.clientGroups = [];
    global.clientsImages = [];
    global.clientsInactive = [];
    global.sessions = [];
    global.archive = [];
    global.events = []
    global.clientBest = [];
    global.clientBestGroup = [];
    global.chats = [];
    global.chatsGroup = [];
    global.activity = [];

    global.sessionsTraining = [];
    global.archiveTraining = [];
    global.recurring = [];

    global.programs = [];
    global.programsClient = [];
    global.plans = [];
    global.exercises = [];

    global.payments = [];
    global.invoices = [];
    global.products = [];

    global.userLog = [];
    global.syncDate = 0;
    global.maxClients = 0;
    global.maxPrograms = 0;
    global.maxPlans = 0;
    global.maxTrainers = 0;

    global.userDataLoaded = false;
    Firebase.auth().signOut()
  }


  sendWelcome() {
    const itemsRef = Firebase.database().ref('/emails/'+global.uid);
    itemsRef.on('value', (snap) => {
      global.emails = [];
      snap.forEach((child) => {
        if(child.key === global.spaceWelcome) {
          var subject = child.val().subject
          var content = child.val().content
          var scheduled = parseInt(Moment().add(global.spaceWelcomeTime, 'hours').format('X'))
          var callFunction = Firebase.functions().httpsCallable('sendWelcomeV2');
          callFunction({sender: global.userBusiness, email: global.userEmail, subject: subject, content: content, scheduled: scheduled}).then(function(result) {});
        }
      });
    });
  }



  // Display stuff ------------------------------------------------------------



  renderText() {
    var label = lang.t('auth:label.confirm1')
    if(global.spaceClient !== '') {
      label = lang.t('auth:label.confirm2')
    }
    if(Firebase.auth().currentUser.uid === 'iJam7aX6gSYjd92D3NSbcKacCbv2') {
      label = lang.t('auth:label.confirm3')
    }
    return label
  }


  renderListImg(item) {
    if(item.img === '') {
      return (
        <div className={'member-image bg'+item.num}>
          <div className={'gradient theme-'+item.data.theme}></div>
        </div>
      )
    } else {
      return (
        <div className="member-image" style={{backgroundImage: 'url('+item.img+')'}}></div>
      )
    }
  }


  renderList() { 
    if(this.state.allspaces.length > 0 && this.state.search !== '') {
      var list = this.state.list
      list.sort((a, b) => a.data.name.localeCompare(b.data.name))
      return (
        <div>
          {list.map(item => (
              <div key={item.id} onClick={() => this.showOverlay(item)}>
                <div className="box list simple highlight mb-10">
                  {this.renderListImg(item)}
                  <div className="text lft">
                    <h4>{item.data.name}</h4>
                    <p>{item.data.owner}<br/>{lang.t('auth:label.clicktoconnect')}</p>
                  </div>
                  <div className="clear"></div>
                </div>
                <div className="clear sv-20"></div>
              </div>
            ))}
        </div>
      )
    }
  }


  renderBottom() {
    if(global.spaceClient !== '') {
      return (
        <div>
          <div className="sv-40"></div>
          <button className="btn primary" onClick={() => this.clickContinue()}>{lang.t('common:button.continue')}</button>
        </div>
      )
    } else {
      return (
        <div>
          <div className="sv-50 clear"></div>
          <div className="col-8">
            <input type="text" placeholder={lang.t('auth:form.search.placeholder')} value={this.state.search} onChange={this.onChange}/>
          </div>
          <div className="col-4">
            <button className="btn primary" onClick={() => this.filterList()}>{lang.t('common:button.search')}</button>
          </div>
          <div className="clear sv-40"></div>
          {this.renderList()}
        </div>
      )
    }
  }


  renderLogout() {
    if(Firebase.auth().currentUser.uid !== 'iJam7aX6gSYjd92D3NSbcKacCbv2') {
      return (
        <div style={{position: 'fixed', top: 15, right: 15}}>
          <button className="btn tertiary small close" onClick={() => this.logoutUser()}>{lang.t('nav:account.logout')}</button>
        </div>
      )
    }
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content form-container">
          <div className="empty large pt-30 clear">
            <div className="illustration mb-30">
              <div className="img1"></div>
              <div className="img2"></div>
              <div className="img3"></div>
              <div className="img4"></div>
              {HelperEmptyImg.renderImage('clients')}
            </div>
          </div>
          <div className="center">
            <h2 className="mb-20" style={{textAlign: 'center'}}>{Firebase.auth().currentUser.uid === 'iJam7aX6gSYjd92D3NSbcKacCbv2' ? lang.t('auth:title.thankyou') : lang.t('auth:title.allset')}</h2>
            <p style={{textAlign: 'center'}}>{this.renderText()}</p>
            {this.renderLogout()}
          </div>
          {this.renderBottom()}
        </div>
        {this.renderOverlay()}
      </div>
    )
    
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['auth','nav','common','modal','header'])(withRouter(withAuthorizationMember(condition)(MemberConfirmPage)));