import React, { Component } from 'react';
import Connector from '../../data/Connector';
import 'firebase/functions';
import Moment from 'moment';
import 'moment/locale/es';
import Navigation from '../../components/Navigation';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import HelperEmptyImg from '../../helper/EmptyLarge';
import { AuthUserContext, withAuthorizationMember } from '../../components/Session';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class MemberTrainingSessionsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      done: false,
      num: global.num,
      sessions: [],
    };
  }


  componentDidMount() {
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-3]
    if(id !== 'training' && id !== 'member') {
      global.uid = id
    }
    Connector.setUser()
    document.title = lang.t('header:title.training')+' - '+global.userBusiness
    window.scrollTo(0, 0)

    this.setState({
      done: true
    })

    if(global.message !== '') {
      setTimeout(() => {
        EventEmitter.dispatch('showMessage', global.message);
        global.message = ''
      }, 300);
      
    }
    EventEmitter.subscribe('userLoaded', (event) => this.checkSpace())
    EventEmitter.subscribe('clientLoaded', (event) => this.configureData())
    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('trainingLoaded', (event) => this.configureData())
    this.configureData()

  }
  

  checkSpace() {
    if(global.uid === '') {
      this.props.history.push(ROUTES.LOADING)
    } else {
      var found = false
      for(var item of global.userStaff) {
        if(item.id === global.uid) {
          found = true
        }
      }
      if(found) {
        document.title = lang.t('header:title.training')+' - '+global.userBusiness
        this.configureData()
      } else {
        this.props.history.push(ROUTES.LOADING)
      }
    }
  }


  configureData() {
    var tmp = []
    for(var item1 of global.sessions) {
      if(item1.data.attendance === 3 && Moment(item1.data.date, 'DD/MM/YYYY HH:mm').isBefore(Moment())) {
        if(global.loc === '' || global.loc.indexOf(item1.data.location) !== -1) {
          if(!item1.data.group && item1.data.client === global.spaceClient) {
            tmp.push(item1)
          } else if(item1.data.group) {
            if(item1.data.clients !== undefined) {
              if(item1.data.clients.indexOf(global.spaceClient) !== -1) {
                tmp.push(item1)
              }
            }
          }
        }
      }
    }
    for(var item2 of global.sessionsTraining) {
      if(item2.data.attendance === 3 && Moment(item2.data.date, 'DD/MM/YYYY HH:mm').isBefore(Moment()) && item2.program.length > 0) {
        tmp.push(item2)
      }
    }
    this.setState({
      sessions: tmp,
    })
  }


  getBg() {
    var label = 'image'
    if(!this.state.done && this.state.num === 99) {
      var num = parseInt(Math.random()*6+1)
      global.num = num
      this.setState({
        num: num
      })
    }
    label = 'image bg'+this.state.num
    return label
  }



  // Display stuff ------------------------------------------------------------



  renderSessionType(item) {
    if(item.data.group && item.data.availability === undefined) {
      return (
        <div className="icon primary">
          <div className="inner group"></div>
        </div>
      )
    } else {
      if(item.data.uid === global.uidUser) {
        return (
          <div className="icon tertiary">
          <div className="inner training"></div>
        </div>
        )
      } else {
        return (
          <div className="icon secondary">
          <div className="inner session"></div>
        </div>
        )
      }
    }
  }


  renderSessionName(item) {
    var label = lang.t('calendar:label.pt')
    if(item.data.name !== undefined && item.data.name !== '') {
      label = item.data.name
    }
    if(item.data.group) {
      if(item.data.availability === undefined) {
        if(item.data.client === '') {
          label = lang.t('calendar:label.group')
        } else {
          label = item.data.client
        }
      } else {
        label = lang.t('calendar:label.available')
        if(item.data.name !== undefined && item.data.name !== '') {
          label = item.data.name
        }
      }
    }
    if(item.data.uid === global.uidUser) {
      label = lang.t('calendar:label.training')
    }
    if(item.data.link !== '') {
      label += ' ('+lang.t('calendar:label.virtual')+')'
    }
    return label
  }


  renderSessionDate(date) {
    var label = Moment(date, 'X').format('dddd, D MMM YYYY')
    if(Moment(date, 'X').isSame(Moment(), 'day')) {
      label = lang.t('calendar:label.today2')
    }
    if(Moment(date, 'X').add(1, 'day').isSame(Moment(), 'day')) {
      label = lang.t('calendar:label.tomorrow2')
    }
    return label
  }


  renderSessions() {
    if(this.state.sessions.length === 0) {
      return (
        <div className="empty large pt-60 clear">
          <div className="illustration mb-30">
            <div className="img1"></div>
            <div className="img2"></div>
            <div className="img3"></div>
            <div className="img4"></div>
            {HelperEmptyImg.renderImage('calendar')}
          </div>
          <div className="data">
            <h3 className="mb-20">{lang.t('empty:sessions.title')}</h3>
            <p style={{whiteSpace: "pre-wrap"}}>{lang.t('empty:sessions.text')}</p>
          </div>
        </div>
      )
    } else {
      var list = this.state.sessions.sort((a,b) => Moment(b.data.date, 'DD/MM/YYYY HH:mm') - Moment(a.data.date, 'DD/MM/YYYY HH:mm'))
      return (
        <div>
          {list.map(item => (
            <div className="box list simple highlight mb-10" key={item.id}>
              <Link to={'/'+global.uid+'/past-session/'+item.id}>
                <div className="col-5">
                  {this.renderSessionType(item)}
                  <p><strong>{this.renderSessionName(item)}</strong></p>
                </div>
                <div className="col-5">
                  <p>{Moment(item.data.timestamp, 'X').locale(lang.language).format('llll')}</p>
                </div>
                <div className="col-2">
                  <button className="btn tertiary small rgt">{lang.t('common:button.viewdetails')}</button>
                </div>
                <div className="clear"></div>
              </Link>
            </div>
          ))}
          <div className="sv-40"></div>
        </div>
      )
    }
  }


  renderContent() {
    return (
      <div>
        <div className="listheader">
          <h3>{lang.t('header:training.pastsessions')}</h3>
          <div className="clear"></div>
        </div>
        {this.renderSessions()}
      </div>
    )
  }


  renderMainImage() {
    if(global.spaceBackground === '') {
      return (
        <div className={this.getBg()}>
          <div className={'gradient theme-'+global.spaceTheme}></div>
        </div>
      )
    } else {
      return (
        <div className='image bg' style={{backgroundImage: 'url('+global.spaceBackground+')', backgroundSize: 'cover'}}></div>
      )
    }
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content member">
          <div className="member-header mb-10">
            {this.renderMainImage()}
            <div className="member-main">
              <div className="business">
                <div className="avatar" style={{backgroundImage: 'url('+global.spaceImage+')'}}> </div>
                <h3>{global.userBusiness}<br/><span>{lang.t('header:title.memberarea')}</span></h3>
              </div>
              <h2 className="mb-30">{lang.t('header:title.training')}</h2>
              <Link to={'/'+global.uid+'/training/sessions'} className="tab active">
                <p>{lang.t('nav:training.sessions')}</p>
                <div className="bg"></div>
              </Link>
              <Link to={'/'+global.uid+'/training/programs'} className="tab">
                <p>{lang.t('nav:training.programs')}</p>
                <div className="bg"></div>
              </Link>
              <Link to={'/'+global.uid+'/training/plans'} className="tab">
                <p>{lang.t('nav:training.plans')}</p>
                <div className="bg"></div>
              </Link>
              <Link to={'/'+global.uid+'/training/progress'} className="tab">
                <p>{lang.t('nav:training.progress')}</p>
                <div className="bg"></div>
              </Link>
            </div>
            <div className="clear"></div>
          </div>
          {this.renderContent()}
        </div>
        <Navigation active='training' />
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['nav','header','calendar','common','empty'])(withRouter(withAuthorizationMember(condition)(MemberTrainingSessionsPage)));