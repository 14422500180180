import React, { Component } from 'react';
import Connector from '../../data/Connector';
import 'firebase/functions';
import Navigation from '../../components/Navigation';
import HelperEmptyImg from '../../helper/EmptyLarge';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import Moment from 'moment';
import 'moment/locale/es';
import { AuthUserContext, withAuthorizationMember } from '../../components/Session';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class MemberHabitsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      done: false,
      num: global.num,
      habits: []
    };
  }


  componentDidMount() {
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-3]
    if(id !== 'training' && id !== 'member') {
      global.uid = id
    }
    Connector.setUser()
    document.title = lang.t('header:title.health')+' - '+global.userBusiness
    window.scrollTo(0, 0)

    this.setState({
      done: true
    })

    if(global.message !== '') {
      setTimeout(() => {
        EventEmitter.dispatch('showMessage', global.message);
        global.message = ''
      }, 300);
      
    }
    EventEmitter.subscribe('userLoaded', (event) => this.checkSpace())
    EventEmitter.subscribe('clientLoaded', (event) => this.configureData())
    EventEmitter.subscribe('habitsLoaded', (event) => this.configureData())
    this.configureData()

  }
  

  checkSpace() {
    if(global.uid === '') {
      this.props.history.push(ROUTES.LOADING)
    } else {
      var found = false
      for(var item of global.userStaff) {
        if(item.id === global.uid) {
          found = true
        }
      }
      if(found) {
        document.title = lang.t('header:title.health')+' - '+global.userBusiness
        this.configureData()
      } else {
        this.props.history.push(ROUTES.LOADING)
      }
    }
  }


  configureData() {
    var tmp = []
    for(var item of global.habits) {
      if(Moment(item.data.start, 'X').startOf('day') < Moment() && Moment(item.data.end, 'X') > Moment()) {
        tmp.push(item)
      }
    }
    this.setState({
      habits: tmp
    })
  }


  getBg() {
    var label = 'image'
    if(!this.state.done && this.state.num === 99) {
      var num = parseInt(Math.random()*6+1)
      global.num = num
      this.setState({
        num: num
      })
    }
    label = 'image bg'+this.state.num
    return label
  }


  getCompliance(item, type) {
    var label = 'grey'
    var num = 0
    var tmp1 = []
    var start = Moment(item.data.start, 'X').startOf('day')
    var end = Moment().add(1, 'days')
    if( Moment(item.data.end, 'X') < Moment()) {
      end = Moment(item.data.end, 'X')
    }
    var d = Moment.duration(end.diff(start))
    var dys = d.asDays()
    for(var i=0; i<dys.toFixed(0); i++) {
      if(item.data.days !== undefined) {
        var dt = Moment(item.data.start, 'X').add(i, 'days').format('DD/MM/YYYY')
        for(var day of item.data.days) {
          if(day.indexOf(dt) !== -1 && day.indexOf('||1||') !== -1) {
            tmp1++
          }
        }
      }
    }
    var per = tmp1/dys.toFixed(0)*100
    num = per.toFixed(0)
    if(per > 0 && per < 30) {
      label = 'red'
    }
    if(per > 29 && per < 80) {
      label = 'yellow'
    }
    if(per > 79) {
      label = 'green'
    }
    if(isNaN(per)) {
      num = 0
    }

    if(type === 'class') {
      return label
    } else {
      return num
    }
  }



  // Display stuff ------------------------------------------------------------



  renderHabits() {
    if(this.state.habits.length === 0 || !global.spaceAllowHabits) {
      return (
        <div className="empty large pt-60 clear">
          <div className="illustration mb-30">
            <div className="img1"></div>
            <div className="img2"></div>
            <div className="img3"></div>
            <div className="img4"></div>
            {HelperEmptyImg.renderImage('habits')}
          </div>
          <div className="data">
            <h3 className="mb-20">{lang.t('empty:habits.title')}</h3>
            <p>{lang.t('empty:habits.text')}</p>
          </div>
        </div>
      )
    } else {
      var list = this.state.habits.sort((a,b) => a.data.name.localeCompare(b.data.name))
      return (
        <div>
          {list.map(item => (
            <div className="box list simple highlight mb-10" key={item.id}>
              <Link to={'/'+global.uid+'/health/habit/'+item.id}>
                <div className="col-4">
                  <div className={'icon '+this.getCompliance(item, 'class')}>
                    <p>{this.getCompliance(item, 'label')}%</p>
                  </div>
                  <p><strong>{item.data.name}</strong></p>
                </div>
                <div className="col-3">
                  <p>{item.data.amount} {item.data.unit} {item.data.interval === 1 ? lang.t('health:label.perday') : lang.t('health:label.perweek')}</p>
                </div>
                <div className="col-3">
                  <p>{lang.t('health:label.started')} {Moment(item.data.start, 'X').locale(lang.language).format('dddd, D MMM YYYY')}</p>
                </div>
                <div className="col-2">
                  <button className="btn tertiary small rgt">{lang.t('common:button.viewdetails')}</button>
                </div>
                <div className="clear"></div>
              </Link>
            </div>
          ))}
          <div className="space-40"></div>
        </div>
      )
    }
  }


  renderContent() {
    return (
      <div>
        <div className="listheader">
          <h3>{lang.t('header:health.habits')}</h3>
          <div className="clear"></div>
        </div>
        {this.renderHabits()}
      </div>
    )
  }


  renderMainImage() {
    if(global.spaceBackground === '') {
      return (
        <div className={this.getBg()}>
          <div className={'gradient theme-'+global.spaceTheme}></div>
        </div>
      )
    } else {
      return (
        <div className='image bg' style={{backgroundImage: 'url('+global.spaceBackground+')', backgroundSize: 'cover'}}></div>
      )
    }
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content member">
          <div className="member-header mb-10">
            {this.renderMainImage()}
            <div className="member-main">
              <div className="business">
                <div className="avatar" style={{backgroundImage: 'url('+global.spaceImage+')'}}> </div>
                <h3>{global.userBusiness}<br/><span>{lang.t('header:title.memberarea')}</span></h3>
              </div>
              <h2 className="mb-30">{lang.t('header:title.health')}</h2>
              <Link to={'/'+global.uid+'/health/nutrition'} className="tab">
                <p>{lang.t('nav:health.meal')}</p>
                <div className="bg"></div>
              </Link>
              <Link to={'/'+global.uid+'/health/log'} className="tab">
                <p>{lang.t('nav:health.log')}</p>
                <div className="bg"></div>
              </Link>
              <Link to={'/'+global.uid+'/health/habits'} className="tab active">
                <p>{lang.t('nav:health.habits')}</p>
                <div className="bg"></div>
              </Link>
            </div>
            <div className="clear"></div>
          </div>
          {this.renderContent()}
        </div>
        <Navigation active='health' />
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['nav','header','empty','health','common'])(withRouter(withAuthorizationMember(condition)(MemberHabitsPage)));