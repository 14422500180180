import React, { Component } from 'react';
import Firebase from 'firebase';
import Moment from 'moment';
import ListClient from '../List/client';
import EventEmitter from '../../helper/Emitter';
import Connector from '../../data/Connector';
import Cal from '../../helper/Calendar';
import { withTranslation } from "react-i18next";
import lang from 'i18next';



class ModalLeaderboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      session: props.session,
      block: props.block,
      show: props.show,
      hidden: 'hidden',
      clients: [],
    };
    if(props.show) {
      this.show()
      this.configureClients()
    }
  }


  componentWillReceiveProps(props) {
    this.setState({
      session: props.session,
      block: props.block,
      show: props.show,
      data: props.data ?? '',
    }, () => {
      if(this.state.show) {
        this.show()
        this.configureClients()
      }
    })
  }


  configureClients() {
    var tmp = []
    var num = 0
    if(this.state.session.data.group) {
      if(this.state.session.data.clients !== undefined) {
        for(var item of global.clients) {
          if(this.state.session.data.clients.indexOf(item.id) !== -1) {
            tmp.push(item)
            tmp[tmp.length-1].session = this.state.session
            var result = this.getResult(item, num)
            tmp[tmp.length-1].reps = result[0]
            tmp[tmp.length-1].weight = result[1]
            tmp[tmp.length-1].time = result[2]
            tmp[tmp.length-1].score = result[3]
            num++
          }
        }
      }
    } else {
      for(var item2 of global.clients) {
        if(this.state.session.data.client === item2.id) {
          tmp.push(item2)
        }
      }
    }
    // Other sessions same day
    for(var sess of global.sessions) {
      var num2 = 0
      if(sess.program.length > 0 && Moment(sess.data.timestamp, 'X').format('DD/MM/YYYY') === Moment(this.state.session.data.timestamp, 'X').format('DD/MM/YYYY')) {
        if(sess.program[0].id !== '' && sess.program[0].id !== undefined && sess.id !== this.state.session.id) {
          for(var bl of sess.program[0].blocks) {
            if(bl.id === this.state.block.id) {
              if(sess.data.group && sess.data.clients !== undefined) {
                for(var cl of global.clients) {
                  if(sess.data.clients.indexOf(cl.id) !== -1) {
                    tmp.push(cl)
                    tmp[tmp.length-1].session = sess
                    var result = this.getResult(cl, num)
                    tmp[tmp.length-1].reps = result[0]
                    tmp[tmp.length-1].weight = result[1]
                    tmp[tmp.length-1].time = result[2]
                    tmp[tmp.length-1].score = result[3]
                    num2++
                  }
                }
              } else {
                for(var cl2 of global.clients) {
                  if(sess.data.client === cl2.id) {
                    tmp.push(cl2)
                  }
                }
              }
            }
          }
        }
      }
    }
    this.setState({
      clients: tmp,
    })
  }


  show() {
    this.setState({
      show: true,
    })
    setTimeout(() => {
      this.setState({
        hidden: '',
      });
    }, 100);
  }


  hide() {
    this.setState({
      hidden: 'hidden',
    })
    setTimeout(() => {
      this.setState({show: false});
      this.props.onHide(false);
    }, 500);
  }



  // Data -----------------------------------------------------


  
  getResult(item, num) {
    var reps = 0
    var weight = ""
    var time = 0
    var total = 0
    if(this.state.block.type === 0) {
      reps = this.getAmrap(item.session, num)[0]
      weight = this.getAmrap(item.session, num)[1]
      total = this.getAmrap(item.session, num)[2]
    } else if(this.state.block.type === 5) {
      reps = this.getForTime(item.session, num)[0]
      weight = this.getForTime(item.session, num)[1]
      time = this.getForTime(item.session, num)[2]
      total = this.getForTime(item.session, num)[3]
    } else {
      reps = this.getScore(item.session, num)[0]
      weight = this.getScore(item.session, num)[1]
      total = this.getScore(item.session, num)[2]
    }
    return [reps, weight, time, total]
  }


  getScore(session, pos) {
    var tblock = this.state.block
    var value = 0
    var weight = 0
    var lweight = ""
    var total = 0
    var type = "group"
    if(!session.data.group) {
      type = "pt"
    }
    // other session here
    if(session.id !== this.state.session.id) {
      for(var bl of session.program[0].blocks) {
        if(bl.id === this.state.block.id) {
          tblock = this.createBlock(bl, session)
        }
      }
    }
    
    if(!tblock.simple) {
      for(var item of tblock.exercises) {
        // Classes
        if(type == "group") {
          var ar = item.resRepsGroup.split("-")
          var ar2 = item.resWeightGroup.split("-")
          if(ar.length > pos) {
            value += parseInt(ar[pos])
          }
          if(ar2.length > pos) {
            if(ar2[pos] == "" || ar2[pos] == "0" || ar2[pos] == "0.0") {
              weight += 1
            } else {
              weight += parseFloat(ar2[pos])
              lweight += String(ar2[pos])+(item.weightType == "per" ? (global.userLbs ? "lb" : "kg") : item.weightType)+"-"
            }
          }
        // 1:1 & Training
        } else {
          value += item.resReps
          if(item.resWeight > 0) {
            weight += item.weight
            lweight += String(item.weight)+(item.weightType == "per" ? (global.userLbs ? "lb" : "kg") : item.weightType)+"-"
          } else {
            weight += 1
          }
        }
      }
      if(lweight != "") {
        var tmp = lweight.substring(0, lweight.length-1)
        lweight = " - "+tmp
      }
      total = weight*value
    } else {
      weight = 0
      lweight = "-"
      total = 0
      if(tblock.valueSimple2 !== undefined) {
        if(tblock.valueSimple2.length > pos) {
          weight = 2
          value = tblock.valueSimple2[pos]
          
          if(tblock.valueSimple2[pos] != 0) {
            lweight = String(tblock.valueSimple2[pos])
            if(tblock.unitSimple != "weight") {
              lweight = String(tblock.valueSimple2[pos])
            }
            if(tblock.unitSimple == "reps") { lweight += " reps" }
            if(tblock.unitSimple == "weight") { lweight += global.userLbs ? " lb" : " kg" }
            if(tblock.unitSimple == "distance") { lweight += " m" }
            if(tblock.scaledSimple2.length > pos) {
              if(tblock.scaledSimple2[pos]) {
                lweight += " (Scaled)"
                weight = 1
              }
            }
            total = weight*value
          }
        }
      }
    }
    return [value, lweight, total]
  }


  getAmrap(session, pos) {
    var tblock = this.state.block
    var value = 0
    var weight = 0
    var lweight = ""
    var total = 0
    var type = "group"
    if(!session.data.group) {
      type = "pt"
    }
    // other session here
    if(session.id !== this.state.session.id) {
      for(var bl of session.program[0].blocks) {
        if(bl.id === this.state.block.id) {
          tblock = this.createBlock(bl, session)
        }
      }
    }

    if(!tblock.simple) {
      for(var item of tblock.exercises) {
        // Classes
        if(type == "group") {
          var ar = item.resRepsGroup.split("-")
          var ar2 = item.resWeightGroup.split("-")
          if(ar.length > pos) {
            value += parseInt(ar[pos])
          }
          if(ar2.length > pos) {
            if(ar2[pos] == "" || ar2[pos] == "0" || ar2[pos] == "0.0") {
              weight += 1
            } else {
              weight += parseFloat(ar2[pos])
              lweight += String(ar2[pos])+(item.weightType == "per" ? (global.userLbs ? "lb" : "kg") : item.weightType)+"-"
            }
          }
        // 1:1 & Training
        } else {
          value += item.resReps
          if(item.resWeight > 0) {
            weight += item.weight
            lweight += String(item.weight)+(item.weightType == "per" ? (global.userLbs ? "lb" : "kg") : item.weightType)+"-"
          } else {
            weight += 1
          }
        }
      }
      if(lweight != "") {
        var tmp = lweight.substring(0, lweight.length-1)
        lweight = " - "+tmp
      }
      total = weight*value
    } else {
      lweight = "-"
      weight = 2
      if(tblock.amrapSimple !== undefined) {
        if(tblock.amrapSimple.length > pos) {
          lweight = tblock.amrapSimple[pos]+" rounds"
          if(tblock.scaledSimple.length > pos) {
              if(tblock.scaledSimple[pos]) {
                lweight += " (Scaled)"
                weight = 1
              }
          }
          var ar = tblock.amrapSimple[pos].split("+")
          if(ar.length > 1) {
            total = parseFloat(ar[0])*1000+parseFloat(ar[1])*weight
          }
          if(tblock.amrapSimple[pos] == "0+0") {
            lweight = "-"
          }
        }
      }
    }
    if(lweight == "0 rounds") { lweight = "-" }
    return [value, lweight, total]
  }


  getForTime(session, pos) {
    var tblock = this.state.block
    var value = 0
    var time = 0
    var weight = 0
    var lweight = ""
    var total = 0
    var type = "group"
    if(!session.data.group) {
      type = "pt"
    }
    // other session here
    if(session.id !== this.state.session.id) {
      for(var bl of session.program[0].blocks) {
        if(bl.id === this.state.block.id) {
          tblock = this.createBlock(bl, session)
        }
      }
    }

    if(tblock.resMinGroup.length > pos) {
      time = tblock.resMinGroup[pos]*60
    }
    if(tblock.resSecGroup.length > pos) {
      time += tblock.resSecGroup[pos]
    }
    if(!tblock.simple) {
      for(var item of tblock.exercises) {
        // Class
        if(type == "group") {
          var ar = item.resRepsGroup.split("-")
          var ar2 = item.resWeightGroup.split("-")
          if(ar.length > pos) {
            value += parseInt(ar[pos])
          }
          if(ar2.length > pos) {
            if(ar2[pos] == "" || ar2[pos] == "0" || ar2[pos] == "0.0") {
              weight += 1
            } else {
              weight += parseFloat(ar2[pos])
              lweight += String(ar2[pos])+(item.weightType == "per" ? (global.userLbs ? "lb" : "kg") : item.weightType)+"-"
            }
          }
        // 11 & Training
        } else {
          value += item.resReps
          if(item.resWeight > 0) {
            weight += item.weight
            lweight += String(item.weight)+(item.weightType == "per" ? (global.userLbs ? "lb" : "kg") : item.weightType)+"-"
          } else {
            weight += 1
          }
        }
      }
      if(lweight != "") {
        var tmp = lweight.substring(0, lweight.length-1)
        lweight = " - "+tmp
      }
    } else {
      lweight = ""
      if(time != 0) {
        value = 1
        weight = 2
        if(tblock.scaledSimple2 !== undefined) {
          if(tblock.scaledSimple2.count > pos) {
            if(tblock.scaledSimple[pos]) {
              lweight += " (Scaled)"
              weight = 1
            }
          }
        }
      }
    }
    if(time == 0 && value != 0) {
      time = tblock.rounds-1
    }
    if(time < tblock.rounds && !tblock.simple && time > 0) {
      value = 0
      for(var ex of tblock.exercises) {
        value += ex.reps*tblock.cycles
      }
      if(value == 0) {
        value = 1
      }
    }
    total = weight*value*(tblock.rounds-time)
    if(time == 0 && value == 0) {
      total = 0
    }
    return [value, lweight, time, total]
  }


  createBlock(block, item) {
    var cycles = 0
    var min = parseInt(block.data.rounds/60)
    var rmin = parseInt(block.data.rounds/60)
    var rsec = parseInt(block.data.rounds)-(rmin*60)
    var rming = []
    var rsecg = []
    var arrt = []
    var simple = false
    var usimple = "reps"
    if(block.data.cycles !== undefined) {
      cycles = block.data.cycles
    }

    if(item.data.group) {
      if(block.data.timeResGroup !== undefined && block.data.timeResGroup !== null) {
        arrt = block.data.timeResGroup
      }
      if(arrt.length === 0) {
        var att1 = 0
        while(att1 < item.data.clients.length) {
          rming.push(0)
          rsecg.push(0)
          att1++
        }
      } else {
        for(var tme of arrt) {
          rming.push(parseInt(tme/60))
          rsecg.push(tme-(parseInt(tme/60)*60))
        }
      }
    }

    // Simple results
    if(block.data.simple) {
      if(block.data.unitSimple !== undefined && block.data.unitSimple !== '') {
        usimple = block.data.unitSimple
      }
      simple = true
    }

    var ex = []
    var tmpb = 0
    var tmp0 = 0

    var exWeightType = []
    var ei = 0
    while(ei < block.data.exName.length) {
      exWeightType.push('per')
      ei++
    }

    for(var i=0; i<block.data.exName.length; i++) {
      var min3 = parseInt(block.data.exResWeight[i]/60)
      var m = []
      var s = []

      var unit = ''
      if(block.data.exUnits !== undefined) {
        if(block.data.exUnits.length > i) {
          unit = block.data.exUnits[i]
        }
      }

      tmpb += parseInt(block.data.exReps[i])
      if(!item.data.group) {
        tmp0 += parseInt(block.data.exResReps[i])
      }

      // Reps
      var reps = block.data.exReps[i]
      if(item.data.clients.length === 0) {
        // 1:1
        if(block.data.exResReps !== undefined) {
          if(block.data.exResReps.length > i) {
            tmp0 += parseInt(block.data.exResReps[i])
            if(block.data.exResReps[i] !== 0 && block.data.exResReps[i] !== undefined) {
              reps = block.data.exResReps[i]
            }
          }
        }
      } else {
        // Group
        if(block.data.exResRepsGroup !== undefined) {
          if(block.data.exResRepsGroup.length > i) {
            var areps = block.data.exResRepsGroup[i].split('-')
            if(areps.length > this.state.current) {
              if(areps[this.state.current] !== '') {
                reps = areps[this.state.current]
                tmp0 += parseInt(areps[this.state.current])
              }
            }
          }
          for(var im of block.data.exResRepsGroup) {
            var mins = parseInt(im/60)
            m.push(parseInt(im/60))
            s.push(parseInt(im)-(mins*60))
          }
          if(m.length < item.data.clients.length) {
            for(var ia=m.length; ia<item.data.clients.length; ia++) {
              m.push(0)
              s.push(0)
            }
          }
        }
      }

      // Weight
      var weight = block.data.exWeight[i]
      if(item.data.clients.length === 0) {
        // 1:1
        if(block.data.exResWeight !== undefined) {
          if(block.data.exResWeight.length > i) {
            if(block.data.exResWeight[i] !== 0 && block.data.exResWeight[i] !== undefined) {
              weight = block.data.exResWeight[i]
            }
          }
        }
      } else {
        // Group
        if(block.data.exResWeightGroup !== undefined) {
          if(block.data.exResWeightGroup.length > i) {
            var aweight = block.data.exResWeightGroup[i].split('-')
            if(aweight.length > this.state.current) {
              if(aweight[this.state.current] !== '') {
                weight = aweight[this.state.current]
              }
            }
          }
        }
      }

      ex.push({
        id: block.data.exId[i],
        name: block.data.exName[i],
        tool: block.data.exTool[i],
        image: block.data.exImage[i],
        cat: block.data.exCat[i],
        type: block.data.exType[i],
        reps: block.data.exReps[i],
        resRepsGroup: block.data.exResRepsGroup[i],
        // Updating reps data
        resReps: reps,
        weight: block.data.exWeight[i],
        resWeightGroup: block.data.exResWeightGroup[i],
        // Updating weight data
        resWeight: weight,
        weightType: exWeightType[i],
        work: block.data.exWork[i],
        rest: block.data.exRest[i],
        resMin: min3,
        resSec: block.data.exResWeight[i]-(min3*60),
        resMinGroup: m[i],
        resSecGroup: s[i],
        unit: unit,
      })
    }

    var item = {
      id: block.id,
      type: block.data.type,
      cat: block.data.cat,
      rounds: block.data.rounds,
      cycles: cycles,
      emom: block.data.emom,
      exercises: ex,
      min: min,
      sec: block.data.rounds-(min*60),
      resMin: rmin,
      resSec: rsec,
      resMinGroup: rming,
      resSecGroup: rsecg,
      simple: simple,
      unitSimple: usimple,
      scaledSimple2: block.data.scaledSimple,
      valueSimple2: block.data.valueSimple
    }
    return item
  }


  clickElement(item) {
    if(item.id !== global.spaceClient) {
      var list = []
      if(item.session.data.highfives !== undefined) {
        list = item.session.data.highfives
      }
      list.push(item.id+'||'+global.spaceClient+'||'+String(parseInt(Moment().format('X'))))
      Firebase.database().ref('/sessions/'+global.uid+'/'+item.session.id).update({
        highfives: list
      })
      var session = ((item.session.data.name === undefined || item.session.data.name === '') ? 'PT session' : item.session.data.name)
      if(item.session.data.group) {
        session = (item.session.data.client === '' ? 'Class' : item.session.data.client)
      }
      for(var cl of global.clients) {
        if(cl.id === item.id && cl.data.pushToken !== '' && cl.data.pushToken !== undefined) {
          Connector.sendPushNotification(cl.data.pushToken, "High five!", "You received a high five from "+global.userName+" for "+session+" "+Cal.getSpecialDate(item.session, true)+".", 'session', item.session.id)
        }
      }
      EventEmitter.dispatch('showMessage', "High five!");
    }
  }



  // Display stuff -----------------------------------------------------



  getLine1(item) {
    var label = '-'
    if(this.state.block.type === 0) {
      var round = 0
      for(var ex of this.state.block.exercises) {
        round += ex.reps
      }
      if(this.state.block.simple) {
        label = item.weight
      } else {
        let rounds = Math.floor(item.reps/round)
        let reps = item.reps-rounds*round
        label = String(rounds)+"+"+String(reps)+" rounds"+item.weight
        if(item.reps == 0) {
          label = "-"
        }
      }
    } else if(this.state.block.type === 5) {
      var mins = Math.floor(item.time/60)
      var secs = item.time-mins*60
      var lsecs = String(secs)
      if(secs < 10) {
          lsecs = "0"+String(secs)
      }
      label = String(mins)+":"+lsecs+" min"+item.weight
      if(item.time == this.state.block.rounds-1 && item.reps != 0) {
        label = String(item.reps)+" reps"+item.weight
      }
      if(item.total === 0 || (mins === 0 && secs === 0)) {
        label = "-"
      }
    } else {
      label = String(item.reps)+" total reps"+item.weight
      if(item.reps == 0) {
        label = "-"
      }
      if(this.state.block.simple) {
        label = item.weight
      }
    }
    if(label.indexOf('NaN') !== -1) {
      label = '-'
    }
    if(label === '-') {
      label = '- No result -'
    }
    return label
  }


  getLine2(item) {
    var label = ' '
    var num = 0
    if(item.session.data.highfives !== undefined) {
      for(var hf of item.session.data.highfives) {
        var ar = hf.split('||')
        if(ar[0] === item.id) {
          num++
        }
      }
    }
    if(num === 1) {
      label = '1 high five'
    }
    if(num > 1) {
      label = num+' high fives'
    }
    return label
  }


  renderContent() {
    if(this.state.clients.length === 0) {
      return (
        <div className="scroll">
          <div className="empty small pt-50">
            <h4>{lang.t('modals:clients.empty')}</h4>
          </div>
        </div>
      )
    } else {
      var list = this.state.clients
      list.sort((a,b) => b.score - a.score)
      return (
        <div className="scroll">
          {list.map(item => (
            <ListClient key={item.id} client={item} type='' line1={this.getLine1(item)} line2={this.getLine2(item)} clickElement={() => this.clickElement(item)}/>
          ))}
        </div>
      )
    }
  }


  render() {
    if(this.state.show) {
      return (
        <div className={'overlay '+this.state.hidden}>
          <div className="box clients">
            <h2 className="mb-20 lft">Leaderboard</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hide()}>Close</button>
            <div className="clear sv-20"></div>
            {this.renderContent()}
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}


export default withTranslation(['modals','common'])(ModalLeaderboard);