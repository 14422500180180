import React, { Component } from 'react';
import HelperClients from '../../helper/Clients';



class ListClient extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      client: props.client,
      type: props.type ?? '',
      title: props.title ?? '',
      line1: props.line1 ?? '',
      line2: props.line2 ?? '',
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      client: props.client,
      type: props.type ?? '',
      title: props.title ?? '',
      line1: props.line1 ?? '',
      line2: props.line2 ?? '',
    }
  }


  clickElement() {
    if(!this.state.type !== 'static') {
      this.props.clickElement()
    } 
  }


  getName() {
    var label = this.state.client.data.name
    if(this.state.client.id === global.spaceClient) {
      label = 'You'
    }
    return label
  }


  render() {
    if(this.state.type === 'placeholder') {
      return (
        <div className="client placeholder" onClick={() => this.clickElement()}>
          <div className="avatar">
            <div className="inner"></div>
          </div>
          <div className="text">
            <h4>{this.state.title}</h4>
            <p>{this.state.line1}<br/>{this.state.line2}</p>
          </div>
        </div>
      )
    } else if(this.state.type.indexOf('static') !== -1 && this.state.client !== undefined) {
      return (
        <div className={'client '+this.state.type}>
          {HelperClients.getImage(this.state.client)}
          <div className="text">
            <h4>{this.state.client.id === global.spaceClient ? 'You' : this.state.client.data.name}</h4>
            <p>{this.state.line1 === '' ? this.state.client.data.email : this.state.line1}<br/>{this.state.line2 === '' ? HelperClients.getPhone(this.state.client.data.phone) : this.state.line2}</p>
          </div>
        </div>
      )
    } else if(this.state.type.indexOf('static') === -1 && this.state.client !== undefined) {
      return (
        <div className={'client '+this.state.type} onClick={() => this.clickElement()}>
          {HelperClients.getImage(this.state.client)}
          <div className="text">
            <h4>{this.state.title === '' ? this.getName() : this.state.title}</h4>
            <p>{this.state.line1 === '' ? this.state.client.data.email : this.state.line1}<br/>{this.state.line2 === '' ? HelperClients.getPhone(this.state.client.data.phone) : this.state.line2}</p>
          </div>
        </div>
      )
    } else {
      return <div></div>
    }
  }
}


export default ListClient;