import React, { Component } from 'react';
import Firebase from 'firebase';
import 'firebase/functions';
import HelperEmptyImg from '../../helper/EmptyLarge';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class TimeoutPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: global.uid
    };
  }


  componentDidMount() {
    document.title = 'Member Area'
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-1]
    if(id !== 'timeout' && id !== 'member') {
      this.setState({
        id: id
      })
    }
    this.logoutUser()
  }


  logoutUser() {
    global.uid = "";
    global.uidUser = "";
    global.userName = '';
    global.userBusiness = '';
    global.userEmail = '';
    global.userPhone = '';
    global.userStripe = '';
    global.userMessage = 0;
    global.userCard = '';
    global.userSubPlanId = '';
    global.userSubPlan = 'spark';
    global.userSubId = '';
    global.userSubStatus = 'trialing';
    global.userSubBilling = '';
    global.userSubCancel = false;
    global.userSubEnd = 0;
    global.userSubItemId = '';
    global.userStripeConnect = '';
    global.userStripeBank = '';
    global.userStripeAddress = '';
    global.timerStart = 5;
    global.userTemplates = [];
    global.userImage = '';
    global.userEmailsSent = [];
    global.userVerified = false;
    global.userOnboarding = ['', ''];
    global.userBadges = [];
    global.userComments = true;
    global.userShowBooked = true;
    global.userSubdomain = '';
    global.userLimitBooking = false;

    global.cards = [];
    global.coupon = [];
    global.userSignup = false;

    global.userRole = '';
    global.userStaff = [];
    global.spaces = [];
    global.clientPlans = [];

    global.publicData = [];
    global.publicRequests = [];
    global.showOnboarding = false;
    global.userConnectClient = null;
    global.clientToken = '';
    global.clientTokens = [];
    global.spaceClient = '';
    global.spaceTheme = 'blue';

    // Data arrays

    global.clients = [];
    global.clientGroups = [];
    global.clientsImages = [];
    global.clientsInactive = [];
    global.sessions = [];
    global.archive = [];
    global.events = []
    global.clientBest = [];
    global.clientBestGroup = [];
    global.chats = [];
    global.chatsGroup = [];
    global.activity = [];

    global.sessionsTraining = [];
    global.archiveTraining = [];
    global.recurring = [];

    global.programs = [];
    global.programsClient = [];
    global.plans = [];
    global.exercises = [];

    global.payments = [];
    global.invoices = [];
    global.products = [];

    global.userLog = [];
    global.syncDate = 0;
    global.maxClients = 0;
    global.maxPrograms = 0;
    global.maxPlans = 0;
    global.maxTrainers = 0;

    global.userDataLoaded = false;
    Firebase.auth().signOut()
  }


  tapLogin() {
    if(this.state.id === '') {
      window.location = 'https://ptmate.me/'
    } else {
      window.location = 'https://ptmate.me/'+this.state.id+'/login'
    }
  }


  render() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content">
          <div className="empty large pt-30 clear">
            <div className="illustration mb-30">
              <div className="img1"></div>
              <div className="img2"></div>
              <div className="img3"></div>
              <div className="img4"></div>
              {HelperEmptyImg.renderImage('clients')}
            </div>
          </div>
          <div className="center">
            <h2 className="mb-20" style={{textAlign: 'center'}}>{lang.t('auth:timeout.title')}</h2>
            <p className="mb-20" style={{textAlign: 'center', whiteSpace: 'pre-wrap'}}>{lang.t('auth:timeout.text')}</p>
            <button className="btn tertiary width-12" onClick={() => this.tapLogin()}>{lang.t('auth:timeout.button')}</button>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation(['auth'])(TimeoutPage);