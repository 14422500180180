import Firebase from 'firebase';
import EventEmitter from '../helper/Emitter';
import Moment from 'moment';
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import lang from 'i18next';


class Connector {

  checkUserUnauth() {
    this.listener = Firebase.auth().onAuthStateChanged(
      authUser => {
        if(authUser !== null) {
          var name = Firebase.auth().currentUser.displayName
          if(name === 'client') {
            if(!global.logging) {
              window.location = '/loading'
            }
          } else {
            //window.location = '/overview'
          }
        }
      },
    );
  }

  checkUserSession() {
    Firebase.auth().currentUser.getIdTokenResult().then((idTokenResult) => {
      const authTime = idTokenResult.claims.auth_time;
      const tlogin = Moment(authTime, 'X').format('DD/MM/YYYY')
      if(tlogin !== Moment().format('DD/MM/YYYY')) {
        if(global.uid === '') {
          window.location = '/timeout'
        } else {
          window.location = '/timeout/'+global.uid
        }
      }
    });
  }

  setUser() {
    if(global.uidUser !== '') {
      this.checkUserSession()
    }

    if(global.uidUser === '' || global.uidUser === undefined) {
      global.uidUser = Firebase.auth().currentUser.uid
      
      if(window.location.host === 'localhost:3000') {
        //global.uidUser = 'MOi1ND30qUMX1FdCVKeqh2GHcJ42'
        //global.uidUser = 'cjpzXrY4ZNdAMVJqsCvPMZvHtEK2'
      }

      if(global.uidUser !== '') {
        this.checkUserSession()
      }

      global.userCurrency = "aud"
      global.curSym = "A$"

      if(lang.language.indexOf('en') !== -1) {
        lang.language = 'en'
      } else if(lang.language.indexOf('es') !== -1) {
        lang.language = 'es'
      }

      //var callFunction = Firebase.functions().httpsCallable('testCreate');
      //callFunction({email: 'ptmate.app@gmail.com', password: 'ptmate123', uid: 'bsuJMlTNd7eufBoo3qiOHT7N7v92', displayName: "client"}).then(function(result) {});

      global.userName = Firebase.auth().currentUser.displayName
      if(global.userName !== 'client' && global.userName !== 'Client') {
        Firebase.auth().signOut()
        window.location = 'https://ptmate.app'
        
      } else if(global.userName === 'client' && global.uidUser === 'iJam7aX6gSYjd92D3NSbcKacCbv2' && global.spaceClient === '') {
        Firebase.auth().signOut()
      } else {
        if(Firebase.auth().currentUser.uid !== 'iJam7aX6gSYjd92D3NSbcKacCbv2') {
          global.currentDay = 0
          this.loadUser((result) => {
            if(global.uid !== '' && global.uidUser !== 'iJam7aX6gSYjd92D3NSbcKacCbv2') {
              this.getSpace()
              this.loadClient((result) => {
                this.loadForms((result) => {})
                if(global.userParent !== '' && global.userParent !== undefined) {
                  this.loadFamilyBilling(global.userParent)
                }
              })
              this.loadSessions((result) => {})
              this.loadEvents((result) => {})
              this.loadClientGroups((result) => {})
              this.loadPayments((result) => {})
              this.loadInvoices((result) => {})
              this.loadLog((result) => {})
              this.loadChat((result) => {})
              this.loadChatsGroup((result) => {})
              this.loadClients((result) => {})
              this.loadProducts((result) => {})
              this.loadPrograms((result) => {})
              this.loadCommunity((result) => {})
              this.loadRecurring((result) => {})
              this.loadHabits((result) => {})
              this.loadDocuments((result) => {})
              this.loadExercises((result) => {})
              this.loadTraining((result) => {})
              this.loadClientBest(global.uidUser)
              this.loadPlans((result) => {})
              this.loadLocations((result) => {})
              this.loadSchedule((result) => {})
            } else {
              if(global.userStaff.length === 0) {
                // search page
              }
            }
          })
        }
      }
    }

    Moment.updateLocale("en", { week: {
      dow: 1, // First day of week is Monday
    }});
    
  }


  // User Data

  loadUser(callback) {
		let itemsRef = Firebase.database().ref('/usersClients/'+global.uidUser);
		itemsRef.on("value", function(snapshot) {
			if (snapshot.exists()) {
				var data = snapshot.val();
				global.userBday = Moment(data.birth, 'DD/MM/YYYY')
		  	global.userPhone = data.phone
        global.userEmail = data.email
        global.userName = data.name
		  	global.userImage = ''
		  	global.userImageDate = Moment(data.imageDate, 'DD/MM/YYYY HH:mm')
				global.userTemplates = data.templates
				global.userImage = data.image
        global.coupon = String(data.height)
        global.userCountry = data.userCountry

        global.userLbs = false
        if(data.lbs !== undefined) {
          global.userLbs = data.lbs
        }

        global.userAvatar = ''
        if(data.avatar !== undefined) {
          global.userAvatar = data.avatar
        }

        global.emergency = ['', '', '']
        if(data.ecName !== undefined) {
          global.emergency[0] = data.ecName
        }
        if(data.ecType !== undefined) {
          global.emergency[1] = String(data.ecType)
        }
        if(data.ecPhone !== undefined) {
          global.emergency[2] = data.ecPhone
        }
        global.userToken = ''
        if(data.pushToken !== undefined) {
          global.userToken = data.pushToken
        }
        
        let sRef = Firebase.database().ref('/usersClients/'+global.uidUser+'/trainers/');
      	global.userStaff= []
      	sRef.once('value', (ssnap) => {
      		ssnap.forEach((schild) => {
      			global.userStaff.push({
      				id: schild.key,
              client: schild.val().client,
              name: 'Training Space',
              owner: 'Trainer',
              image: '',
              banner: '',
              theme: 'blue',
              num: parseInt(Math.random()*4+1),
              stripe: '',
              token: ''
      			});
      		});
      	});

        if(global.userStaff.length === 1) {
          global.uid = global.userStaff[0].id
        }

        EventEmitter.dispatch('userDataLoaded', 'loaded');
        //global.userDataLoaded = true;
        callback({success: true});
			} else {
				global.userPhone = ''
			}
      if(global.userPhone === '') {
        window.location.pathname = '/complete-signup'
      } else {
        if(global.userStaff.length === 0 && !global.programLocked) {
          window.location.pathname = '/confirm'
        }
        if(data.image !== '') {
          Firebase.storage().ref().child(data.image).getDownloadURL().then((url) => {
            global.userImage = url
            EventEmitter.dispatch('userLoaded', 'loaded');
          }).catch((error) => {
            // Handle any errors
          })
        }
        EventEmitter.dispatch('userLoaded', 'loaded');
        callback({success: true}); 
      }
    
		});
	}


  // Current user space

  getSpace() {
    this.getSpaceToken()
    for(var item of global.userStaff) {
      if(item.id === global.uid) {
        global.spaceClient = item.client
      }
    }
    let itemsRef = Firebase.database().ref('/spaces/'+global.uid);
		itemsRef.on("value", function(snapshot) {
			if (snapshot.exists()) {
				var data = snapshot.val();
        global.userBusiness = data.name
        global.spaceOwner = data.owner
        global.spaceImage = ''
        global.spaceTheme = data.theme
        global.spaceBackground = ''
        global.userStripeConnect = data.stripeConnect
        global.userCommunity = data.community
        global.userCommunityPost = data.communityPost
        global.spaceAddress = data.address
        global.spaceEmailReminder = data.emailReminder ?? false
        
        global.userShowBooked = true
        if(data.showBooked !== undefined) {
          global.userShowBooked = data.showBooked
        }

        if(data.allowBooking !== undefined) {
          global.userAllowBooking = data.allowBooking
        }

        global.userComments = true
        if(data.comments !== undefined) {
          global.userComments = data.comments
        }

        global.spaceWelcome = ''
        global.spaceWelcomeTime = 0
        if(data.welcome !== undefined) {
          global.spaceWelcome = data.welcome
          global.spaceWelcomeTime = data.welcomeTime
        }

        global.spaceAllowRecurring = false
        if(data.allowRecurring !== undefined) {
          global.spaceAllowRecurring = data.allowRecurring
        }

        global.userLimitBooking = false
        if(data.limitBooking !== undefined && global.userStripeConnect !== '' && global.userStripeConnect !== undefined) {
          global.userLimitBooking = data.limitBooking
        }

        global.spaceAllowHabits = false
        global.userSubPlanId = data.subPlanId
		  	global.userSubStatus = data.subStatus
        //if(global.userSubPlanId === 'price_1OOCAEAd6uNQtfqaqhBopmgh' || global.userSubPlanId === 'price_1OOCCzAd6uNQtfqalqT5jeD6' || global.userSubStatus === 'trialing') {
        if(global.subsBus.indexOf(global.userSubPlanId) !== -1) {
          global.spaceAllowHabits = true
        } else {
          global.spaceWelcome = ''
          global.spaceBackground = ''
        }

        global.userSessionReminder = 99999
        if(data.reminder !== undefined) {
          global.userSessionReminder = data.reminder
        }

        global.spaceInvoice1 = ""
        global.spaceInvoice2 = ""
        if(data.invoice1 !== undefined) {
          global.spaceInvoice1 = data.invoice1
        }
        if(data.invoice2 !== undefined) {
          global.spaceInvoice2 = data.invoice2
        }

        if(data.image !== '') {
          Firebase.storage().ref().child(data.image).getDownloadURL().then((url) => {
            global.spaceImage = url
            EventEmitter.dispatch('userLoaded', 'loaded');
          }).catch((error) => {
            // Handle any errors
          })
        }
        if(data.background !== '') {
          Firebase.storage().ref().child(data.background).getDownloadURL().then((url) => {
            global.spaceBackground = url
            EventEmitter.dispatch('userLoaded', 'loaded');
          }).catch((error) => {
            // Handle any errors
          })
        }
      }
      EventEmitter.dispatch('spaceLoaded', 'loaded');
    });
  }


  getSpaceToken() {
    let itemsRef = Firebase.database().ref('/users/'+global.uid);
		itemsRef.on("value", function(snapshot) {
			if (snapshot.exists()) {
				var data = snapshot.val();
        global.clientToken = data.pushToken
      }

      global.userCurrency = "aud"
      global.curSym = "A$"
      if(data.country === 'us') {
        global.userCurrency = "usd"
        global.curSym = "$"
      }
      
      EventEmitter.dispatch('tokenLoaded', 'loaded');
    });
  }


  // Spaces for selection

  getSpaceInfo(item) {
    let itemsRef = Firebase.database().ref('/spaces/'+item.id);
		itemsRef.once("value", function(snapshot) {
			if (snapshot.exists()) {
				var data = snapshot.val();
        item.name = data.name
        item.owner = data.owner
        item.theme = data.theme
        item.stripe = data.stripeConnect
        item.token = data.pushToken
        item.community = data.community
        item.communityPost = data.communityPost
        item.limitBooking = false
        item.emailReminder = data.emailReminder ?? false
        if(data.limitBooking !== undefined && data.stripeConnect !== '' && data.stripeConnect !== undefined) {
          item.limitBooking = data.limitBooking
        }

        if(data.allowBooking !== undefined) {
          item.allowBooking = data.allowBooking
        }

       item.allowRecurring = false
        if(data.allowRecurring !== undefined) {
          item.allowRecurring = data.allowRecurring
        }

        if(data.image !== '' && item.image === '') {
          Firebase.storage().ref().child(data.image).getDownloadURL().then((url) => {
            item.image = url
            EventEmitter.dispatch('spaceInfoLoaded', 'loaded');
          }).catch((error) => {
            // Handle any errors
          })
        }
      }
      EventEmitter.dispatch('spaceInfoLoaded', 'loaded');
    });
  }


  // Check if space exists

  checkSpaceExists(id) {
    let itemsRef = Firebase.database().ref('/spaces/'+id);
		itemsRef.once("value", function(snapshot) {
      if (snapshot.exists()) {
        EventEmitter.dispatch('spaceChecked', 'yes');
      } else {
        EventEmitter.dispatch('spaceChecked', 'no');
      }
    });
  }


  // Current client info

  loadClient(callback) {
    let itemsRef = Firebase.database().ref('/clients/'+global.uid+'/'+global.spaceClient);
		itemsRef.on("value", function(snapshot) {
			if (snapshot.exists()) {
				var data = snapshot.val();

        var del = false
        var uid = true
        global.userStripe = ''
        global.userVerified = true
        if(data.active !== undefined) {
          global.userVerified = data.active
        }
        if(data.deleted !== undefined) {
          del = data.deleted
        }
        if(data.uid === undefined || data.uid === '') {
          uid = false
        }
        if(data.customer !== undefined) {
          global.userStripe = data.customer
        }

        if(data.parent !== undefined) {
          global.userParent = data.parent
        }
        if(data.restricted !== undefined) {
          global.userRestricted = data.restricted
        }

        global.userCard = ''
        global.cards = []
        if(data.cardId !== undefined && data.cardId !== '') {
          global.userCard = data.cardId
          global.cards.push({
            id: data.cardId,
            brand: data.cardBrand,
            last4: data.cardLast4
          })
        }
        global.clientEmailReminder = data.emailReminder ?? true

        let aRef = Firebase.database().ref('/clients/'+global.uid+'/'+global.spaceClient+'/assessments/');
      	global.healthlog = []
      	aRef.once('value', (asnap) => {
      		asnap.forEach((achild) => {
      			global.healthlog.push({
      				id: achild.key,
          			data: achild.val(),
      			});
      		});
      	});

        let cRef = Firebase.database().ref('/clients/'+global.uid+'/'+global.spaceClient+'/credits/');
      	global.credits = []
      	cRef.once('value', (csnap) => {
      		csnap.forEach((cchild) => {
      			global.credits.push({
      				id: cchild.key,
          			data: cchild.val(),
      			});
      		});
      	});

      	let sRef = Firebase.database().ref('/clients/'+global.uid+'/'+global.spaceClient+'/subscriptions/');
      	global.subscriptions = []
      	sRef.once('value', (ssnap) => {
      		ssnap.forEach((schild) => {
      			global.subscriptions.push({
      				id: schild.key,
          			data: schild.val(),
      			});
      		});
      	});

        let fRef = Firebase.database().ref('/clients/'+global.uid+'/'+global.spaceClient+'/forms/');
        if(!global.activityLocked) {
          global.forms = []
        }
      	fRef.once('value', (fsnap) => {
      		fsnap.forEach((fchild) => {
            let fsRef = Firebase.database().ref('/clients/'+global.uid+'/'+global.spaceClient+'/forms/'+fchild.key+'/sections/');
            var sections = []
            fsRef.once('value', (fssnap) => {
              fssnap.forEach((fschild) => {
                sections.push({
                  id: fschild.key,
                  data: fschild.val(),
                });
              });
            });
            let fhRef = Firebase.database().ref('/clients/'+global.uid+'/'+global.spaceClient+'/forms/'+fchild.key+'/notes/');
            var history = []
            fhRef.once('value', (fhsnap) => {
              fhsnap.forEach((fhchild) => {
                history.push({
                  id: fhchild.key,
                  data: fhchild.val(),
                });
              });
            });
            global.forms.push({
      				id: fchild.key,
          		data: fchild.val(),
              sections: sections,
              history: history,
      			});
      		});
      	});

        global.userNutritionId = data.nutritionId
        global.userNutritionStart = data.nutritionStart
        global.userNutritionEnd = data.nutritionEnd
        global.userNutritionTrial = data.nutritionStatus

        if(del || !uid) {
          global.uid = ''
        }
      }

      EventEmitter.dispatch('userLoaded', 'loaded');
      EventEmitter.dispatch('subsLoaded', 'loaded');
      callback({success: true});
    });
  }


  // Family Billing

  loadFamilyBilling(client) {
    let itemsRef = Firebase.database().ref('/clients/'+global.uid+'/'+client);
		itemsRef.on("value", function(snapshot) {
			if (snapshot.exists()) {

        let cRef = Firebase.database().ref('/clients/'+global.uid+'/'+client+'/credits/');
      	global.credits = []
      	cRef.once('value', (csnap) => {
      		csnap.forEach((cchild) => {
      			global.credits.push({
      				id: cchild.key,
          			data: cchild.val(),
      			});
      		});
      	});

      	let sRef = Firebase.database().ref('/clients/'+global.uid+'/'+global.client+'/subscriptions/');
      	global.subscriptions = []
      	sRef.once('value', (ssnap) => {
      		ssnap.forEach((schild) => {
      			global.subscriptions.push({
      				id: schild.key,
          			data: schild.val(),
      			});
      		});
      	});
      }
      EventEmitter.dispatch('userLoaded', 'loaded');
    });
  }


  // Sessions

  loadSessions(callback) {
    const itemsRef = Firebase.database().ref('/sessions/'+global.uid);
    itemsRef.on('value', (snap) => {
      global.sessions = [];
      snap.forEach((child) => {
        let wRef = Firebase.database().ref('/sessions/'+global.uid+'/'+child.key+'/workout/');
        var program = []
        var comments = []
        wRef.once('value', (wsnap) => {
          wsnap.forEach((wchild) => {

            let bRef = Firebase.database().ref('/sessions/'+global.uid+'/'+child.key+'/workout/'+wchild.key+'/blocks/');
            var blocks = []
            bRef.once('value', (bsnap) => {
              bsnap.forEach((bchild) => {
                blocks.push({
                  id: bchild.key,
                    data: bchild.val(),
                });
              });
            });

            program.push({
              id: wchild.key,
                data: wchild.val(),
                blocks: blocks
            });
          });
        });

        let cRef = Firebase.database().ref('/sessions/'+global.uid+'/'+child.key+'/comments/');
          cRef.once('value', (csnap) => {
            csnap.forEach((cchild) => {
              comments.push({
                id: cchild.key,
                  data: cchild.val(),
              });
            });
          });

          global.sessions.push({
            id: child.key,
            data: child.val(),
            program: program,
            comments: comments
            //blocks: blocks
          });
          if(child.val().timestamp !== undefined) {
            global.sessions[global.sessions.length-1].data.date = Moment(child.val().timestamp, 'X').format('DD/MM/YYYY HH:mm')
          }
        //}
        
      });
      EventEmitter.dispatch('sessionsLoaded', 'loaded');
      callback({success: true});
    });
	}


  // Training sessions

  loadTraining(callback) {
    const itemsRef = Firebase.database().ref('/sessions/'+global.uidUser);
    itemsRef.on('value', (snap) => {
      global.sessionsTraining = [];
      snap.forEach((child) => {
        let wRef = Firebase.database().ref('/sessions/'+global.uidUser+'/'+child.key+'/workout/');
        var program = []
        var comments = []
        wRef.once('value', (wsnap) => {
          wsnap.forEach((wchild) => {

            let bRef = Firebase.database().ref('/sessions/'+global.uidUser+'/'+child.key+'/workout/'+wchild.key+'/blocks/');
            var blocks = []
            bRef.once('value', (bsnap) => {
              bsnap.forEach((bchild) => {
                blocks.push({
                  id: bchild.key,
                    data: bchild.val(),
                });
              });
            });

            program.push({
              id: wchild.key,
                data: wchild.val(),
                blocks: blocks
            });
          });
        });

        let cRef = Firebase.database().ref('/sessions/'+global.uidUser+'/'+child.key+'/comments/');
          cRef.once('value', (csnap) => {
            csnap.forEach((cchild) => {
              comments.push({
                id: cchild.key,
                  data: cchild.val(),
              });
            });
          });

          global.sessionsTraining.push({
            id: child.key,
            data: child.val(),
            program: program,
            comments: comments
          });
          if(child.val().timestamp !== undefined) {
            global.sessionsTraining[global.sessionsTraining.length-1].data.date = Moment(child.val().timestamp, 'X').format('DD/MM/YYYY HH:mm')
          }
        //}
        
      });
      EventEmitter.dispatch('trainingLoaded', 'loaded');
      callback({success: true});
    });
	}


  // Events

  loadEvents(callback) {
		const itemsRef = Firebase.database().ref('/events/'+global.uid);
    itemsRef.on('value', (snap) => {
      global.events = [];
      snap.forEach((child) => {
        var add = false
        if(child.val().bookable !== undefined) {
          if(child.val().bookable) {
            add = true
          }
        }
        if(add) {
          global.events.push({
            id: child.key,
            data: child.val(),
          });
        }
      });
      EventEmitter.dispatch('eventsLoaded', 'loaded');
      callback({success: true});
    });
	}


  // Client groups

  loadClientGroups = async(callback) => {
		const itemsRef = await Firebase.database().ref('/groups/'+global.uid);
    itemsRef.on('value', (snap) => {
      global.clientGroups = [];
      snap.forEach((child) => {
        global.clientGroups.push({
          id: child.key,
          data: child.val(),
        });
      });
      EventEmitter.dispatch('groupsLoaded', 'loaded');
      callback({success: true});
    });
	}


  // Payments

  loadPayments(callback) {
		const itemsRef = Firebase.database().ref('/payments/'+global.uid);
    itemsRef.on('value', (snap) => {
      global.payments = [];
      snap.forEach((child) => {
		  global.payments.push({
          id: child.key,
          data: child.val(),
        });
      });
      EventEmitter.dispatch('paymentsLoaded', 'loaded');
      callback({success: true});
    });
	}


  // Programs

  loadPrograms(callback) {
		const itemsRef = Firebase.database().ref('/workouts/'+global.uidUser);
    itemsRef.on('value', (snap) => {
      if(!global.programLocked) {
        global.programs = [];
        snap.forEach((child) => {

          let bRef = Firebase.database().ref('/workouts/'+global.uidUser+'/'+child.key+'/blocks/');
          var blocks = []
          bRef.once('value', (ssnap) => {
            ssnap.forEach((schild) => {
              blocks.push({
                id: schild.key,
                  data: schild.val(),
              });
            });
          });

          if(child.val().name === undefined) {
            Firebase.database().ref('/workouts/'+global.uidUser+'/'+child.key).remove()
          } else {
            global.programs.push({
              id: child.key,
              data: child.val(),
              blocks: blocks
            });
          }
        });
        EventEmitter.dispatch('programsLoaded', 'loaded');
      }
      callback({success: true}); 
    });
	}


  // Training plans

  loadPlans(callback) {
    const itemsRef = Firebase.database().ref('/plans/'+global.uidUser);
    itemsRef.on('value', (snap) => {
      global.plans = [];
      snap.forEach((child) => {

        let wRef = Firebase.database().ref('/plans/'+global.uidUser+'/'+child.key+'/weeks/');
        var weeks = []
        wRef.once('value', (ssnap) => {
          ssnap.forEach((wchild) => {
            weeks.push({
              id: wchild.key,
              data: wchild.val(),
            });
          });
        });

        let pRef = Firebase.database().ref('/plans/'+global.uidUser+'/'+child.key+'/workouts/');
        var programs = []
        pRef.once('value', (psnap) => {
          psnap.forEach((pchild) => {

            let bRef = Firebase.database().ref('/plans/'+global.uidUser+'/'+child.key+'/workouts/'+pchild.key+'/blocks/');
            var blocks = []
            bRef.once('value', (bsnap) => {
              bsnap.forEach((bchild) => {
                blocks.push({
                  id: bchild.key,
                  data: bchild.val(),
                });
              });
            });

            programs.push({
              id: pchild.key,
              data: pchild.val(),
              blocks: blocks
            });
          });
        });

        if(child.val().name === undefined) {
          Firebase.database().ref('/plans/'+global.uidUser+'/'+child.key).remove()
        } else {
          global.plans.push({
            id: child.key,
            data: child.val(),
            weeks: weeks,
            programs: programs
          });
        }
      });
      EventEmitter.dispatch('plansLoaded', 'loaded');
      callback({success: true});
    });
  }


  // Log

  loadLog(callback) {
		const itemsRef = Firebase.database().ref('/log/'+global.uid);
    itemsRef.on('value', (snap) => {
      global.userLog = [];
      snap.forEach((child) => {
      	if(child.val().seen === false) {
      		global.userLog.push({
	          id: child.key,
	          data: child.val(),
	        });
      	}
      });
      EventEmitter.dispatch('logLoaded', 'loaded');
      callback({success: true});
    });
  }


  // Locations

  loadLocations(callback) {
		const itemsRef = Firebase.database().ref('/locations/'+global.uid);
    itemsRef.on('value', (snap) => {
      global.locations = [];
      global.spaceLocations = [];
      snap.forEach((child) => {
        global.spaceLocations.push({
          id: child.key,
          data: child.val(),
        });
      });

      for(var loc of global.spaceLocations) {
        if(loc.data.clients !== undefined) {
          if(loc.data.clients.indexOf(global.spaceClient) !== -1) {
            global.locations.push(loc)
          }
        }
      }
      if(global.spaceLocations.length > 0 && global.spaceLocations.length !== global.locations.length) {
        global.loc = global.spaceLocations[0].id
      }
      if(global.spaceLocations.length > 1 && global.spaceLocations.length !== global.locations.length) {
        var str = ""
        for(var item of global.locations) {
          str += item.id+","
        }
        global.loc = str
      }
      if(global.spaceLocations.length > 0 && global.locations.length === 0) {
        global.loc = 'notset'
      }

      EventEmitter.dispatch('locationsLoaded', 'loaded');
      callback({success: true});
    });
	}


  // Scheduled notifications

  loadSchedule(callback) {
		const itemsRef = Firebase.database().ref('/schedule/');
    itemsRef.on('value', (snap) => {
      global.schedule = [];
      snap.forEach((child) => {
        global.schedule.push({
          id: child.key,
          data: child.val(),
        });
      });
      EventEmitter.dispatch('scheduleLoaded', 'loaded');
      callback({success: true});
    });
	}


  // Chats

  loadChat(callback) {
    let itemsRef = Firebase.database().ref('/messaging/'+global.uid+global.uidUser);
		itemsRef.on("value", function(snapshot) {
      global.chats = [];
			if (snapshot.exists()) {
        let cRef = Firebase.database().ref('/messaging/'+global.uid+global.uidUser+'/messages/');
      	var messages = []
      	cRef.once('value', (csnap) => {
      		csnap.forEach((cchild) => {
      			messages.push({
      				id: cchild.key,
          		data: cchild.val(),
      			});
      		});
      	});
        global.chats.push({
          id: global.uid+global.uidUser,
          data: snapshot.val(),
          messages: messages
        });
      } else {
        global.chats.push({
          id: global.uid+global.uidUser,
          data: {
            client: global.uidUser,
            trainer: global.uid,
            nameClient: global.userName,
            nameTrainer: global.spaceOwner,
            dateClient: '01/01/1900 00:00:00',
            dateTrainer: '01/01/1900 00:00:00',
            timeClient: Moment().format('X'),
            timeTrainer: Moment().format('X'),
            id: global.uid+global.uidUser
          },
          messages: []
        });
      }
      EventEmitter.dispatch('chatLoaded', 'loaded');
      callback({success: true});
    });
	}


  loadChatsGroup(callback) {
    const itemsRef = Firebase.database().ref('/messagingGroup/'+global.uid);
    itemsRef.on('value', (snap) => {
      global.chatsGroup = [];
      snap.forEach((child) => {

        let cRef = Firebase.database().ref('/messagingGroup/'+global.uid+'/'+child.key+'/clients/');
        var clients = []
        cRef.once('value', (csnap) => {
          csnap.forEach((cchild) => {
            clients.push({
              id: cchild.key,
              data: cchild.val(),
            });
          });
        });

        let mRef = Firebase.database().ref('/messagingGroup/'+global.uid+'/'+child.key+'/messages/');
        var messages = []
        mRef.once('value', (msnap) => {
          msnap.forEach((mchild) => {
            messages.push({
              id: mchild.key,
              data: mchild.val(),
            });
          });
        });

        var gdata = child.val()
        if(child.val().name === '' || child.val().name === null || child.val().name === undefined) {
          gdata.name = 'Group chat'
        }
        var add = false
        for(var cl of clients) {
          if(cl.id === global.uidUser) {
            add = true
          }
        }
        if(add) {
          global.chatsGroup.push({
            id: child.key,
            data: gdata,
            clients: clients,
            messages: messages
          });
        }

      });
      EventEmitter.dispatch('chatsGroupLoaded', 'loaded');
      callback({success: true});
    });
  }


  // Space clients

  loadClients(callback) {
		const itemsRef = Firebase.database().ref('/clients/'+global.uid);
    itemsRef.on('value', (snap) => {
      // get children as an array
      global.clients = [];
      global.clientsImages = [];
      global.userLinked = [];

      snap.forEach((child) => {

        var del = false
        if(child.val().deleted !== undefined) {
          del = child.val().deleted
        }
        
        if(child.val().name === undefined) {
          //Firebase.database().ref('/clients/'+global.uid+'/'+child.key).remove()
          del = true
        }

        if(!del) {
          global.clients.push({
            id: child.key,
            data: child.val(),
          });

          global.clientsImages.push({
            id: child.key,
            image: ''
          })
        }
        
        if(child.val().image !== '') {
          if(child.val().uid === '') {
            if(child.val().image !== undefined) {
              this.loadClientImage(child.key, child.val().image)
            }
          } else {
            this.loadClientImage(child.key, '/images/users/'+child.val().uid+'.jpg')
          }
        }

        if(child.val().parent === global.spaceClient) {
          var add = true
          for(var lnk of global.userLinked) {
            if(lnk === child.key) {
              add = false
            }
          }
          if(add && child.val().deleted === undefined) {
            global.userLinked.push(child.key)
          }
        }

      });
      EventEmitter.dispatch('clientsLoaded', 'loaded');
      global.clientsLoaded = true
      callback({success: true});
    
    });
	}


  // Community

  loadCommunity(callback) {
		const itemsRef = Firebase.database().ref('/community/'+global.uid);
    itemsRef.on("value", function(snap) {
      global.community = []
      snap.forEach((child) => {

        let cRef = Firebase.database().ref('/community/'+global.uid+'/'+child.key+'/comments/');
        var comms = []
        cRef.on('value', (csnap) => {
          csnap.forEach((cchild) => {
            comms.push({
              id: cchild.key,
              data: cchild.val(),
            });
          });
        });

        global.community.push({
          id: child.key,
          data: child.val(),
          comments: comms
        });
        
      });
      EventEmitter.dispatch('communityLoaded', 'loaded');
      callback({success: true});
    });
	}


  // Recurring templates

  loadRecurring(callback) {
    const itemsRef = Firebase.database().ref('/recurring/'+global.uid);
    itemsRef.on('value', (snap) => {
      global.recurring = [];
      snap.forEach((child) => {
        global.recurring.push({
          id: child.key,
          data: child.val(),
        });
        if(child.val().timestamp !== undefined) {
          global.recurring[global.recurring.length-1].data.date = Moment(child.val().timestamp, 'X').format('DD/MM/YYYY HH:mm')
        }
      });
      
      EventEmitter.dispatch('recurringLoaded', 'loaded');
      callback({success: true});
    });
  }


  // Login stuff

  loadPublic() {
		let itemsRef = Firebase.database().ref('/public/'+global.uid);
    console.log('loading public')
		itemsRef.on("value", function(snapshot) {
			if (snapshot.exists()) {
				var data = snapshot.val();
				global.spaceTheme = data.color
        global.userBusiness = data.name
        if(data.color === undefined) {
          global.spaceTheme = 'blue'
        }
        if(data.name === undefined) {
          global.userBusiness = ''
        }
		  	if(data.logo !== '' && data.logo !== undefined) {
          Firebase.storage().ref().child(data.logo).getDownloadURL().then((url) => {
            global.spaceImage = url
            EventEmitter.dispatch('publicLoaded', 'loaded');
          }).catch((error) => {
            // Handle any errors
          })
        }
        if(data.background !== '' && data.background !== undefined) {
          Firebase.storage().ref().child(data.background).getDownloadURL().then((url) => {
            global.spaceBackground = url
            EventEmitter.dispatch('publicLoaded', 'loaded');
          }).catch((error) => {
            // Handle any errors
          })
        }

        EventEmitter.dispatch('publicLoaded', 'loaded');
			}
    
		});
	}


  // Habits

  loadHabits(callback) {
    let itemsRef = Firebase.database().ref('/habits/'+global.uid);
    itemsRef.on('value', (snap) => {
      global.habits = [];
      snap.forEach((child) => {
        if(child.val().client === global.spaceClient) {
          global.habits.push({
            id: child.key,
            data: child.val()
          });
        }
      });
      EventEmitter.dispatch('habitsLoaded', 'loaded');
      callback({success: true});
    });
  }


  // Connection stuff

  loadConnect(callback) {
		const itemsRef = Firebase.database().ref('/connect/');
    itemsRef.once("value", function(snap) {
      global.connect = []
      snap.forEach((child) => {

        global.connect.push({
          id: child.key,
          data: child.val(),
        });
        
      });
      EventEmitter.dispatch('connectLoaded', 'loaded');
      callback({success: true});
    });
	}


  // Pre exercise form

  loadForms(callback) {
    let itemsRef = Firebase.database().ref('/forms/'+global.uid);
    itemsRef.on('value', (snap) => {
      snap.forEach((child) => {
        let sRef = Firebase.database().ref('/forms/'+global.uid+'/'+child.key+'/sections/');
      	var sections = []
      	sRef.once('value', (ssnap) => {
      		ssnap.forEach((schild) => {
      			sections.push({
      				id: schild.key,
          		data: schild.val(),
      			});
      		});
      	});

        var pre = false
        for(var item of global.forms) {
          if(item.data.pre) {
            pre = true
          }
        }
        if(!pre && child.val().pre) {
          global.forms.push({
            id: child.key,
            data: child.val(),
            sections: sections,
            history: []
          });
        }
        
      });
      EventEmitter.dispatch('formsLoaded', 'loaded');
      callback({success: true});
    });
  }


  // Admin email list

  loadAdminList(callback) {
		const itemsRef = Firebase.database().ref('/admin/accounts');
    itemsRef.once('value', (snap) => {
      global.adminList = [];
      snap.forEach((child) => {
      	global.adminList.push({
          id: child.key,
          data: child.val(),
        });
      });
      callback({success: true});
    });
  }


  // All spaces

  loadAllSpaces(callback) {
		let itemsRef = Firebase.database().ref('/spaces/');
		itemsRef.on('value', (snap) => {
      global.spaces = []
			snap.forEach((child) => {
        if(child.val().image !== undefined) {
          global.spaces.push({
            id: child.key,
            data: child.val(),
            img: '',
          });
        }
        
			});
      EventEmitter.dispatch('allSpacesLoaded', 'loaded');
      callback({success: true});
		});
	}


  // Products

  loadProducts(callback) {
		const itemsRef = Firebase.database().ref('/packs/'+global.uid);
    itemsRef.on('value', (snap) => {
      global.products = [];
      snap.forEach((child) => {
        var add = false
        if(child.val().public !== undefined) {
          add = child.val().public
        }
        if(add) {
          global.products.push({
            id: child.key,
            data: child.val(),
          });
        }
      });
      for(var item of global.products) {
        if(item.data.type === 'sessions') {
          if(item.data.expires === undefined || item.data.expires === 99999) {
            item.data.expires = 12
            item.data.expType = "months"
          }
        }
      }
      EventEmitter.dispatch('productsLoaded', 'loaded');
      callback({success: true});
    });
	}


  // Community image

  loadCommunityImage(id, image) {
    Firebase.storage().ref().child(image).getDownloadURL().then((url) => {
      global.communityImages.push({
        id: id,
        image: url
      })
      EventEmitter.dispatch('communityImagesLoaded', 'loaded');
    }).catch((error) => {
      // Handle any errors
    })
  }


  // Invoices

  loadInvoices(callback) {
    let itemsRef = Firebase.database().ref('/invoices/'+global.uid);
    itemsRef.on('value', (snap) => {
      global.invoices = [];
      snap.forEach((child) => {
        global.invoices.push({
          id: child.key,
          data: child.val()
        });
      });
      EventEmitter.dispatch('invoicesLoaded', 'loaded');
      callback({success: true});
    });
  }


  // Documents

  loadDocuments(callback) {
    let itemsRef = Firebase.database().ref('/documents/'+global.uid);
    itemsRef.on('value', (snap) => {
      global.documents = [];
      snap.forEach((child) => {
        global.documents.push({
          id: child.key,
          data: child.val()
        });
      });
      EventEmitter.dispatch('documentsLoaded', 'loaded');
      callback({success: true});
    });
  }


  // Movements

  loadExercises(callback) {
    //var lockitsch = false
		const itemsRef = Firebase.database().ref('/exercises/');
    //if(!lockitsch) {
		itemsRef.on('value', (snap) => {
			global.exercises = [];
			snap.forEach((child) => {
        if(child.val().user === 'admin' || child.val().user === global.uid) {
  				global.exercises.push({
  					id: child.key,
  					data: child.val(),
					});
				}
        
			});
			EventEmitter.dispatch('exercisesLoaded', 'loaded');
			callback({success: true});
		});
	}


  // Client best

  loadClientBest(id) {
		global.clientBest = []
		let bRef = Firebase.database().ref('/records/'+id);
  	bRef.on('value', (bsnap) => {
  		bsnap.forEach((bchild) => {
        //if(bchild.val().value !== 0) {
          global.clientBest.push({
            id: bchild.key,
            data: bchild.val(),
          });
        //}
  		});
  		EventEmitter.dispatch('bestLoaded', 'loaded');
  	});
    
	}


  // Nutrition Stuff

  createApolloClient = () => {
    return new ApolloClient({
      link: new HttpLink({
        uri: 'https://ptmate-client.hasura.app/v1/graphql',
        headers: {
          'x-hasura-admin-secret': 'Ib2gLkOdCmhuemuKrYTkoPsCZbZIwn0FKfUmwV1OE0TZtdZt1qvMx440gMqM4aQ7',
        }
      }),
      cache: new InMemoryCache(),
    });
  };


  // Client token

  getClientToken(id) {
    global.clientToken = ''
    let itemsRef = Firebase.database().ref('/usersClients/'+id);
    itemsRef.on("value", function(snapshot) {
      if (snapshot.exists()) {
        var data = snapshot.val();
        global.clientToken = data.pushToken
      }
      EventEmitter.dispatch('tokenLoaded', 'loaded');
    });
  }


  getClientTokenAll(id, client) {
    let itemsRef = Firebase.database().ref('/usersClients/'+id);
    itemsRef.on("value", function(snapshot) {
      if (snapshot.exists()) {
        var data = snapshot.val();
        var add = true
        for(var tk of global.clientTokens) {
          if(tk.id === id) {
            add = false
          }
        }
        if(add) {
          global.clientTokens.push({
            id: id,
            client: client,
            token: data.pushToken
          })
        }
        
      }
      EventEmitter.dispatch('tokenLoaded', 'loaded');
    });
  }


  // Push notification

  sendPushNotification(token, title, body, type, iid) {
    var callFunction = Firebase.functions().httpsCallable('sendPushV2');
    callFunction({token: token, title: title, body: body, type: type, space: global.uid, id: iid}).then(function(result) {});
  }


  loadClientImage(id, image) {
    if(image.indexOf('undefined') === -1) {
      Firebase.storage().ref().child(image).getDownloadURL().then((url) => {
        for(var item of global.clientsImages) {
          if(item.id === id) {
            item.image = url
            if(item.id === global.currentClient) {
              EventEmitter.dispatch('imageLoaded', 'loaded');
            }
          }
        }
      }).catch((error) => {
        for(var item of global.clientsImages) {
          if(item.id === id) {
            item.image = ''
            if(item.id === global.currentClient) {
              EventEmitter.dispatch('imageLoaded', 'loaded');
            }
          }
        }
        for(var item2 of global.clients) {
          if(item2.id === id) {
            item2.data.image = ''
          }
        }
        // Handle any errors
      })
    }
  }


  // Admin client users

  loadAdminClients() {
    let itemsRef = Firebase.database().ref('/usersClients/');
    itemsRef.on('value', (snap) => {
      // get children as an array
      global.adminClients = [];
      snap.forEach((child) => {

        let tRef = Firebase.database().ref('/usersClients/'+child.key+'/trainers/');
        var trainers = []
        tRef.once('value', (tsnap) => {
          tsnap.forEach((tchild) => {
            trainers.push({
              id: tchild.key,
              data: tchild.val(),
            });
          });
        });

        global.adminClients.push({
          id: child.key,
          data: child.val(),
          trainers: trainers
        });
      });
      EventEmitter.dispatch('adminClientsLoaded', 'loaded');
    });
  }

}


const b = new Connector();
export default b;