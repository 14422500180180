import React, { Component } from 'react';
import Connector from '../../data/Connector';
import Firebase from 'firebase';
import 'firebase/functions';
import Moment from 'moment';
import 'moment/locale/es';
import { gql } from "@apollo/client";
import Navigation from '../../components/Navigation';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import HelperEmptyImg from '../../helper/EmptyLarge';
import { AuthUserContext, withAuthorizationMember } from '../../components/Session';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class MemberNutritionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      done: false,
      num: global.num,
      loading: true,
      nutrition: null,
      nutritionWeek: null,
      nutritionMeal: null,
      shopping: [],
      weeks: [],
      currentWeek: global.nutritionWeek,
      meals: [],
      weekday: global.currentDay,
      showOverlayShopping: false,
      hiddenShopping: 'hidden',
      list: null,
      modified: false
    };
  }


  componentDidMount() {
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-3]
    if(id !== 'health' && id !== 'member') {
      global.uid = id
    }
    Connector.setUser()
    document.title = lang.t('header:title.health')+' - '+global.userBusiness
    window.scrollTo(0, 0)

    this.setState({
      done: true
    })

    if(global.message !== '') {
      setTimeout(() => {
        EventEmitter.dispatch('showMessage', global.message);
        global.message = ''
      }, 300);
      
    }
    EventEmitter.subscribe('userLoaded', (event) => this.checkSpace())
    EventEmitter.subscribe('clientLoaded', (event) => this.configureData())
    this.configureData()

  }
  

  checkSpace() {
    if(global.uid === '') {
      this.props.history.push(ROUTES.LOADING)
    } else {
      var found = false
      for(var item of global.userStaff) {
        if(item.id === global.uid) {
          found = true
        }
      }
      if(found) {
        document.title = lang.t('header:title.health')+' - '+global.userBusiness
        this.configureData()
      } else {
        this.props.history.push(ROUTES.LOADING)
      }
    }
  }


  configureData() {
    this.setState({
      name: global.userName,
    })
    if(global.userNutritionId !== undefined) {
      if(global.nutritionData !== null) {
        this.processNutritionData(global.nutritionData)
      } else {
        this.getNutritionData()
      }
    }
  }


  getBg() {
    var label = 'image'
    if(!this.state.done && this.state.num === 99) {
      var num = parseInt(Math.random()*6+1)
      global.num = num
      this.setState({
        num: num
      })
    }
    label = 'image bg'+this.state.num
    return label
  }


  getNutritionData() {
    const client = Connector.createApolloClient()
    const query = gql`
    query getUserData($id: Int!) {
      users(where: { id: { _eq: $id} }) {
        id
        sex
        bmr
        weight
        weight_unit
        u_weight
        u_goal_weight
        u_current_weight
        goal
        goal_weight
        current_weight
        activity_level
        form_status
        current_period_start
        current_period_end
        v_type
        v_meals
        
        dinner_serves
        leftover_lunches
        cheat_meal
        dessert
        snack_morning
        snack_afternoon
        snack_evening
        cold_breakfast
        cold_lunch
        rotate_breakfast
        
        gluten
        wheat
        lactose
        milk
        egg
        nuts
        shellfish
        fish
        red_meat
        pork
        chicken
        turkey
        fruit
        vegetable
        grain
        herb_spice
        legume
        berry
        lamb
        preference_length
        preference_string
        
        alt_vol_in_recipes
        last_protein
        
        plan_type
        snack_morning_protein
        snack_afternoon_protein
        snack_evening_protein

        weights {
          date_recorded
          weight
        }

        plans(limit: 3, order_by: {created_at: desc}) {
          id
          start_date
          stop_date
          daily_cals
          starting_weight
          bmr
          target_fat
          target_carbs
          target_protein
          meals {
            id
            recipe_id
            next_meal_id
          }
          shopping_lists {
            id
            start_date
            stop_date
            list_items {
              id
              shopping_list_id
              name
              checked
              amount
              base_amount
              shopping_cat
              kind
              measurement
              gluten
            }
          }
        }
        recipe_preferences {
          id
          user_id
          recipe_id
          rating
        }
      }
    }`;

    client.query({
      variables: { id: parseInt(global.userNutritionId) },
      query
    }).then((result) => {
      global.nutritionData = result.data
      this.processNutritionData(result.data)
    }).catch((error) => {
      console.log(error);
    });
  }


  processNutritionData(data) {
    var weeks = []
    var weights = []
    var shopping = []
    for(var week of data.users[0].plans) {
      weeks.push({id: week.id, date: Moment(week.start_date, 'YYYY-MM-DD'), daily: week.daily_cals})
      shopping.push(week.shopping_lists)
    }
    
    weeks.sort((a,b) => Moment(b.date) - Moment(a.date));
    if(data.users[0].weights !== null) {
      for(var wgt of data.users[0].weights) {
        weights.push({"y": wgt.weight, "x": Moment(wgt.date_recorded, 'YYYY-MM-DD').format('DD/MM')})
      }
    }

    var tmp = [{
      "id": "",
      "color": "#81DB24",
      data: weights
    }]
    this.setState({
      userPassed: true,
      nutrition: data,
      weeks: weeks,
      shopping: shopping,
      graph: tmp
    }, () => {
      if(this.state.nutrition.users[0].form_status !== global.userNutritionTrial && this.state.nutrition.users[0].form_status !== null) {
        Firebase.database().ref('/clients/'+global.uid+'/'+global.spaceClient).update({
          nutritionStatus: this.state.nutrition.users[0].form_status
        })
      }
      var cps = Moment(this.state.nutrition.users[0].current_period_start, 'YYYY-MM-DDTHH:mm:ss.SSS')
      var cpe = Moment(this.state.nutrition.users[0].current_period_end, 'YYYY-MM-DDTHH:mm:ss.SSS')
      if(cps !== Moment(global.userNutritionStart, 'X') && this.state.nutrition.users[0].current_period_start !== null && this.state.nutrition.users[0].current_period_end !== null) {
        Firebase.database().ref('/clients/'+global.uid+'/'+global.spaceClient).update({
          nutritionStart: parseInt(cps.format('X')),
          nutritionEnd: parseInt(cpe.format('X'))
        })
      }
    })
    if(weeks.length === 0) {
      this.setState({
        loading: false
      })
    } else {
      if(this.state.currentWeek === 0) {
        var wk = weeks[0]
        if(Moment(weeks[0].date).startOf('week').format('X') !== Moment().startOf('week').format('X')) {
          for(var week1 of weeks){
            if(Moment(week1.date).startOf('week').format('X') === Moment().startOf('week').format('X')) {
              wk = week1
              global.currentDay = Moment().isoWeekday()-1
              this.setState({
                weekday: Moment().isoWeekday()-1
              })
            }
          }
        }
        global.nutritionWeek = wk.id
        global.nutritionCals = wk.daily
        this.setState({
          currentWeek: wk.id
        }, () => {
          if(global.nutritionWeeks !== null) {
            this.processWeekData(global.nutritionWeeks)
          } else {
            this.getNutritionWeek()
          }
        })
      } else {
        this.setState({
          currentWeek: global.nutritionWeek
        })
        if(global.nutritionWeeks !== null) {
          this.processWeekData(global.nutritionWeeks)
        } else {
          this.getNutritionWeek()
        }
      }
    }
    
  }


  getNutritionWeek() {
    const client = Connector.createApolloClient()
    const query = gql`
    query getWeek($id: Int!) {
      days(where: { plan_id: { _eq: $id} }) {
        meals {
          id
          recipe_id
          breakfast
          snack_morning
          lunch
          snack_afternoon
          dinner
          snack_evening
          dessert
          checked
          size
          serve_now_servings
          next_meal_id
          calories
        }
        id
        scheduled_date
      }
    }`;

    client.query({
      variables: { id: parseInt(this.state.currentWeek) },
      query
    }).then((result) => {
      global.nutritionWeeks = result.data
      this.processWeekData(result.data)
      for(var week of this.state.weeks) {
        if(week.id === this.state.currentWeek) {
          global.nutritionCals = week.daily
        }
      }
    }).catch((error) => {
      console.log(error);
    });
  }


  processWeekData(data) {
    var meals = []
    var days = []

    for(var pl of global.nutritionData.users[0].plans) {
      for(var ml of pl.meals) {
        meals.push(ml.recipe_id)
      }
    }
    
    for(var dy of data.days) {
      var day = []
      for(var item of dy.meals) { 
        //meals.push(item.recipe_id)
        var seq = 0
        if(item.snack_morning) { seq = 1 }
        if(item.lunch) { seq = 2 }
        if(item.snack_afternoon) { seq = 3 }
        if(item.dinner) { seq = 4 }
        if(item.snack_evening) { seq = 5 }
        if(item.dessert) { seq = 6 }
        day.push({data: item, seq: seq})
      }
      day.sort((a, b) => a.seq - b.seq)
      days.push({data: dy, meals: day})
    }
    days.sort((a,b) => Moment(a.data.scheduled_date, 'YYYY-MM-DD') - Moment(b.data.scheduled_date, 'YYYY-MM-DD'));
    this.setState({
      nutritionWeek: days,
      meals: meals
    }, () => {
      if(global.nutritionRecipes !== null) {
        this.setState({
          loading: false,
          nutritionMeal: global.nutritionRecipes,
        })
      } else {
        this.getNutritionRecipes()
      }
      
    })
  }


  getNutritionRecipes() {
    const client = Connector.createApolloClient()
    const query = gql`
    query getWeek($id: [Int!]) {
      recipes(where: { id: { _in: $id} })  {
        name
        id
        photo_file_name
        cooking_time
        protein
        sugars
        total_fat
        total_carbs
        method
        amounts {
            size
            ingredient {
                id
                name
                base_amount
                measurement
                increments
                kind
            }
        }
      }
    }`;
    client.query({
      variables: { id: this.state.meals },
      query
    }).then((result) => {
      this.setState({
        loading: false,
        nutritionMeal: result.data,
      })

      global.nutritionRecipes = result.data
    }).catch((error) => {
      console.log(error);
    });
  }


  setDay(num) {
    global.currentDay = num
    this.setState({
      weekday: num
    })
  }



  // Overlay ------------------------------------------------------------



  showOverlayShopping() {
    var list = []
    for(var sh of this.state.shopping) {
      if(sh[0].start_date === this.state.nutritionWeek[0].data.scheduled_date) {
        for(var item of sh[0].list_items) {
          list.push({
            id: item.id,
            name: item.name,
            checked: item.checked,
            amount: item.amount,
            measurement: item.measurement,
            shopping_cat: item.shopping_cat
          })
        }
      }
    }
    this.setState({
      showOverlayShopping: true,
      list: list
    })
    setTimeout(() => {
      this.setState({
        hiddenShopping: '',
      });
    }, 100);
  }


  hideOverlayShopping() {
    this.setState({
      hiddenShopping: 'hidden',
    })
    if(this.state.modified) {
      this.getNutritionData()
    }
    setTimeout(() => {
      this.setState({
        showOverlayShopping: false,
        modified: false
      });
    }, 500);
  }


  renderAmount(item) {
    var amount = item.amount
    /*if(amount % 1 !== 0) {
      amount = parseInt(amount)
    }*/
    return amount
  }


  renderOverlayShopping() {
    if(this.state.showOverlayShopping) {
      var list = this.state.list
      list.sort((a,b) => a.shopping_cat.localeCompare(b.shopping_cat))
      return (
        <div className={'overlay '+this.state.hiddenShopping}>
          <div className="box clients">
            <h3 style={{margin: 0}}>{lang.t('modal:shopping.title')}</h3>
            <button className="btn tertiary small close" onClick={() => this.hideOverlayShopping()}>{lang.t('modal:common.close')}</button>
            <h4 className="clear">{this.getWeekShopping()}</h4>
            <div className="scroll">
              {list.map(item => (
                <div className="client-list nutrition" key={item.id} onClick={() => this.checkItem(item.id, item.checked)}>
                  <div className="avatar">
                    <img src={'/img/nutrition-'+item.shopping_cat+'.svg'} alt={item.name}/>
                  </div>
                  <div className="text">
                    <h4>{item.name}</h4>
                    <p>{this.renderAmount(item)} {item.measurement}</p>
                  </div>
                  <div className={item.checked ? 'tick active' : 'tick'}></div>
                  <div className="clear"></div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )
    }
  }


  checkItem(id, checked) {
    var chk = !checked
    const client = Connector.createApolloClient()
    const mutation = gql`
      mutation updateList($id: Int!, $checked: Boolean) {
        update_list_items_by_pk(pk_columns: {id: $id}, _set: {checked: $checked}) {
          id
          checked
        }
      }
    `;

    client.mutate({
      variables: { id: parseInt(id), checked: chk },
      mutation
    }).then((result) => {
      var list = this.state.list
      for(var item of list) {
        if(item.id === id) {
          item.checked = chk
        }
      }
      this.setState({
        list: list,
        modified: true
      })
    }).catch((error) => {
      console.log(error);
    });
  }



  // Interactive ------------------------------------------------------------



  selectWeek(event) {
    global.nutritionWeek = event.target.value
    this.setState({
      currentWeek: event.target.value,
      loading: true
    }, () => {
      global.nutritionRecipes = null
      this.getNutritionWeek()
    })
  }


  getMeal(id) {
    var label = lang.t('common:label.loading')+'...'
    if(this.state.nutritionMeal !== null) {
      for(var item of this.state.nutritionMeal.recipes) {
        if(item.id === id) {
          label = item.name
        }
      }
    }
    return label
  }


  getMealPhoto(id) {
    var label = ''
    if(this.state.nutritionMeal !== null) {
      for(var item of this.state.nutritionMeal.recipes) {
        if(item.id === id) {
          label = item.photo_file_name
        }
      }
    }
    return label
  }


  getMealLink(id) {
    var start = '000000000'+String(id)
    var total = start.substr(String(id).length, start.length)
    var label = total.substr(0, 3)+'/'+total.substr(3, 3)+'/'+total.substr(6, 3)
    return label
  }


  getMealType(meal) {
    var label = '-'
    if(meal.breakfast) {
      label = lang.t('health:meal.breakfast')
    }
    if(meal.lunch) {
      label = lang.t('health:meal.lunch')
    }
    if(meal.dinner) {
      label = lang.t('health:meal.dinner')
    }
    if(meal.snack_morning) {
      label = lang.t('health:meal.morning')
    }
    if(meal.snack_afternoon) {
      label = lang.t('health:meal.afternoon')
    }
    if(meal.snack_evening) {
      label = lang.t('health:meal.evening')
    }
    if(meal.dessert) {
      label = lang.t('health:meal.dessert')
    }
    return label
  }


  getMealChecked(date, meal) {
    if(meal.checked) {
      return (
        <span className="pill green">{lang.t('health:label.checked')}</span>
      )
    } else if(!meal.checked && Moment(date, 'YYYY-MM-DD') < Moment()) {
      return (
        <span class="pill red">{lang.t('health:label.notchecked')}</span>
      )
    } else {
      return (
        <span class="pill grey">- {lang.t('health:label.pending')} -</span>
      )
    }
  }


  getWeekName(date) {
    var label = lang.t('health:label.weekstarting')+' '+Moment(date).format('D MMM YYYY')
    if(Moment(date).startOf('week').format('DD/MM/YYYY') === Moment().startOf('week').format('DD/MM/YYYY')) {
      label = lang.t('health:label.thisweek')
    }
    if(Moment(date).startOf('week').format('DD/MM/YYYY') === Moment().add(7, 'days').startOf('week').format('DD/MM/YYYY')) {
      label = lang.t('health:label.nextweek')
    }
    if(Moment(date).startOf('week').format('DD/MM/YYYY') === Moment().add(-7, 'days').startOf('week').format('DD/MM/YYYY')) {
      label = lang.t('health:label.lastweek')
    }
    return label
  }


  getWeekShopping() {
    var label = lang.t('health:label.week')
    for(var wk of this.state.weeks) {
      if(wk.id === parseInt(this.state.currentWeek)) {
        label = this.getWeekName(wk.date)
      }
    }
    return label
  }


  selectMeal(meal, date) {
    global.nutritionMeal = meal
    global.nutritionDate = date
    this.props.history.push('/'+global.uid+'/health/meal/'+meal.data.id)
  }



  // Display stuff ------------------------------------------------------------



  renderButton() {
    if(global.userNutritionId !== undefined && global.userNutritionId !== '') {
      return (
        <Link to={'/'+global.uid+'/health/preferences'} className="btn tertiary rgt edit">{lang.t('health:button.preferences')}</Link>
      )
    }
  }


  renderMeals() {
    if(this.state.nutritionWeek !== null && this.state.nutritionWeek !== undefined && this.state.nutritionWeek.length > 0) {
      if(Moment(global.userNutritionEnd, 'X').add(1, 'days') < Moment()) {
        return (
          <div>
            <div className="listheader">
              <h3 className="lft">{lang.t('header:health.meals')}</h3>
              {this.renderButton()}
              <div className="clear"></div>
            </div>
            <div className="empty large pt-60 clear">
              <div className="illustration mb-30">
                <div className="img1"></div>
                <div className="img2"></div>
                <div className="img3"></div>
                <div className="img4"></div>
                {HelperEmptyImg.renderImage('nutrition')}
              </div>
              <div className="data">
                <h3 className="mb-20">{lang.t('empty:nonutrition.title')}</h3>
                <p>{lang.t('empty:nonutrition.text1')}</p>
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <div>
            <div className="listheader">
              <h3 className="lft mt-5">{lang.t('header:health.meals')}</h3>
              <select className="light rgt" onChange={event => this.selectWeek(event)} value={this.state.currentWeek}>
              {this.state.weeks.map(item => (
                <option value={item.id} key={item.id}>{this.getWeekName(item.date)}</option>
              ))}
              </select>
              <div className="clear sv-30"></div>
              <div className="radios">
                <div className={'radio weekday pt-10 lft '+(this.state.weekday === 0 ? 'active' : '')} onClick={() => this.setDay(0)}>{lang.t('health:label.monday')}</div>
                <div className={'radio weekday pt-10 lft '+(this.state.weekday === 1 ? 'active' : '')} onClick={() => this.setDay(1)}>{lang.t('health:label.tuesday')}</div>
                <div className={'radio weekday pt-10 lft '+(this.state.weekday === 2 ? 'active' : '')} onClick={() => this.setDay(2)}>{lang.t('health:label.wednesday')}</div>
                <div className={'radio weekday pt-10 lft '+(this.state.weekday === 3 ? 'active' : '')} onClick={() => this.setDay(3)}>{lang.t('health:label.thursday')}</div>
                <div className={'radio weekday pt-10 lft '+(this.state.weekday === 4 ? 'active' : '')} onClick={() => this.setDay(4)}>{lang.t('health:label.friday')}</div>
                <div className={'radio weekday pt-10 lft '+(this.state.weekday === 5 ? 'active' : '')} onClick={() => this.setDay(5)}>{lang.t('health:label.saturday')}</div>
                <div className={'radio weekday pt-10 lft '+(this.state.weekday === 6 ? 'active' : '')} onClick={() => this.setDay(6)}>{lang.t('health:label.sunday')}</div>
              </div>
            </div>
            {this.state.nutritionWeek.map((item, index) => (
              <div key={item.data.id} className={this.state.weekday === index ? '' : 'none'}>
                <div className="filters">
                  <h4 className="lft mb-20">{Moment(item.data.scheduled_date, 'YYYY-MM-DD').locale(lang.language).format('dddd, D MMM YYYY')}</h4>
                  <button className="btn tertiary rgt" onClick={() => this.showOverlayShopping()}>{lang.t('health:button.shopping')}</button>
                  <div className="clear"></div>
                </div>
                {item.meals.map(meal => (
                  <div className="col-3" key={meal.data.id}>
                    <div className="box meal highlight mb-20" onClick={() => this.selectMeal(meal, item.data.scheduled_date)}>
                      <div className="image mb-20" style={{backgroundImage: 'url(https://backtomybody-dev.s3.amazonaws.com/recipes/photos/'+this.getMealLink(meal.data.recipe_id)+'/medium/'+this.getMealPhoto(meal.data.recipe_id)+')'}}></div>
                      <div className="meal-info">
                        {this.getMealChecked(item.data.scheduled_date, meal.data)}
                        <div className="info mt-20">
                          <label>{this.getMealType(meal.data)}</label>
                          {this.getMeal(meal.data.recipe_id)}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
            <div className="clear sv-40"></div>
            <Link to={'/'+global.uid+'/health/preferences'} className="btn tertiary width-12 edit">{lang.t('health:button.preferences')}</Link>
            <div className="clear sv-40"></div>
          </div>
        )
      }
    }
  }


  renderContent() {
    // No content or meals
    if(global.userNutritionId === undefined || global.userNutritionId === '' || global.userNutritionTrial !== 'active') {
      var title = lang.t('empty:nutrition1.title')
      var text = lang.t('empty:nutrition1.text')
      if(Moment(global.userNutritionStart, 'X') > Moment() && global.userNutritionTrial === 'registered') {
        title = lang.t('empty:nutrition2.title')
        text = lang.t('empty:nutrition2.text1')+" "+Moment(global.userNutritionStart, 'X').locale(lang.language).format('dddd, D MMM')+" "+lang.t('empty:nutrition2.text2')
      }
      if(global.userNutritionId !== '' && global.userNutritionId === undefined && Moment(global.userNutritionStart, 'X') <= Moment() && Moment(global.userNutritionEnd, 'X') > Moment()) {
        title = lang.t('empty:nutrition3.title')
        text = lang.t('empty:nutrition3.text')
      }
      if(global.userNutritionTrial === 'created') {
        title = lang.t('empty:nutrition4.title')
        text = lang.t('empty:nutrition4.text')
      }
      return (
        <div>
          <div className="listheader">
            <h3 className="lft">{lang.t('header:health.meals')}</h3>
            {this.renderButton()}
            <div className="clear"></div>
          </div>
          <div className="empty large pt-60 clear">
            <div className="illustration mb-30">
              <div className="img1"></div>
              <div className="img2"></div>
              <div className="img3"></div>
              <div className="img4"></div>
              {HelperEmptyImg.renderImage('nutrition')}
            </div>
            <div className="data">
              <h3 className="mb-20">{title}</h3>
              <p>{text}</p>
            </div>
          </div>
        </div>
      )
    // Nutrition data
    } else {
      if(Moment(global.userNutritionEnd, 'X') < Moment()) {
        return (
          <div>
            <div className="listheader">
              <h3 className="lft">{lang.t('header:health.meals')}</h3>
              {this.renderButton()}
              <div className="clear"></div>
            </div>
            <div className="empty large pt-60 clear">
              <div className="illustration mb-30">
                <div className="img1"></div>
                <div className="img2"></div>
                <div className="img3"></div>
                <div className="img4"></div>
                {HelperEmptyImg.renderImage('nutrition')}
              </div>
              <div className="data">
                <h3 className="mb-20">{lang.t('empty:nonutrition.title')}</h3>
                <p style={{whiteSpace: 'pre-wrap'}}>{lang.t('empty:nonutrition.text2')}</p>
              </div>
            </div>
          </div>
        )
      } else if(Moment(global.userNutritionStart, 'X') > Moment()) {
        return (
          <div>
            <div className="listheader">
              <h3 className="lft">{lang.t('header:health.meals')}</h3>
              {this.renderButton()}
              <div className="clear"></div>
            </div>
            <div className="illustration mb-30">
              <div className="img1"></div>
              <div className="img2"></div>
              <div className="img3"></div>
              <div className="img4"></div>
              {HelperEmptyImg.renderImage('nutrition')}
            </div>
            <div className="data">
              <h3 className="mb-20">{lang.t('empty:newnutrition.title')}</h3>
              <p>{lang.t('empty:newnutrition.text1')}<br/>{Moment(global.userNutritionStart, 'X').locale(lang.language).format('dddd, D MMM')}. {lang.t('empty:newnutrition.text2')}</p>
            </div>
          </div>
        )
      } else {
        if(this.state.loading) {
          return (
            <div>
              <div className="listheader">
                <h3>{lang.t('header:health.meals')}</h3>
                {this.renderButton()}
                <div className="clear"></div>
              </div>
              <div className="empty large pt-60">
                <h3 style={{opacity: '.3'}}>{lang.t('common:label.loadingdata')}</h3>
              </div>
            </div>
          )
        } else if(this.state.nutritionWeek !== null && this.state.nutritionWeek !== undefined && this.state.nutritionWeek.length > 0) {
          return this.renderMeals()
        } else {
          return (
            <div className="empty large pt-60">
              <h3>{lang.t('common:label.nodata')}</h3>
            </div>
          )
        }
      }
    }
  }


  renderHabits() {
    if(global.spaceAllowHabits) {
      return (
        <Link to={'/'+global.uid+'/health/habits'} className="tab">
          <p>{lang.t('nav:health.habits')}</p>
          <div className="bg"></div>
        </Link>
      )
    }
  }


  renderMainImage() {
    if(global.spaceBackground === '') {
      return (
        <div className={this.getBg()}>
          <div className={'gradient theme-'+global.spaceTheme}></div>
        </div>
      )
    } else {
      return (
        <div className='image bg' style={{backgroundImage: 'url('+global.spaceBackground+')', backgroundSize: 'cover'}}></div>
      )
    }
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content member">
          <div className="member-header mb-10">
            {this.renderMainImage()}
            <div className="member-main">
              <div className="business">
                <div className="avatar" style={{backgroundImage: 'url('+global.spaceImage+')'}}> </div>
                <h3>{global.userBusiness}<br/><span>{lang.t('header:title.memberarea')}</span></h3>
              </div>
              <h2 className="mb-30">{lang.t('header:title.health')}</h2>
              <Link to={'/'+global.uid+'/health/nutrition'} className="tab active">
                <p>{lang.t('nav:health.meal')}</p>
                <div className="bg"></div>
              </Link>
              <Link to={'/'+global.uid+'/health/log'} className="tab">
                <p>{lang.t('nav:health.log')}</p>
                <div className="bg"></div>
              </Link>
              {this.renderHabits()}
            </div>
            <div className="clear"></div>
          </div>
          {this.renderContent()}
        </div>
        <Navigation active='health' />
        {this.renderOverlayShopping()}
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['nav','header','empty','health','modal'])(withRouter(withAuthorizationMember(condition)(MemberNutritionPage)));